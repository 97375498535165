

















import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';
import ImportResourceFileUpload from '@/components/ImportResource/ImportResourceFileUpload.vue';

@Component({
  components: {
    ImportResourceFileUpload,
  },
})
export default class FileUploadDialog extends Vue {
  @Prop() readonly resourceType: string;

  @Prop() readonly headers: any[];

  @PropSync('fileUploadDialog') showUploadDialog: boolean;

  resetForm(): void {
    (this.$refs.importResource as ImportResourceFileUpload).onClickCancel();
  }
}
