


















































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import ModelData from './ThreeLoaderTypes';
import main from './webgl-starter';

let worker: Worker;

export default Vue.extend({
  name: 'ModelViewer',
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    try {
      if (this.model.model === null || this.model.model === '') {
        this.$emit('isModelSupported', false);
        return;
      }
      if (this.model && document.getElementsByClassName('box').length !== 0) {
        const canvasDiv = (document.getElementById('modelCanvas') as HTMLElement);
        const w = parseInt(window.getComputedStyle(canvasDiv, null).getPropertyValue('width'), 10);
        const h = parseInt(window.getComputedStyle(canvasDiv, null).getPropertyValue('height'), 10);
        worker = main((document.getElementById('c') as HTMLCanvasElement));
        if (worker === null) {
          this.$emit('isModelSupported', false);
          return;
        }
        worker.postMessage({
          type: 'main', width: w, height: h,
        });

        this.load(
          this.model.model,
          this.model.pcd,
          this.model.modelFlip,
          this.model.scaleX,
          this.model.scaleY,
          this.model.scaleZ,
        );
        this.canvasResizeEvent();
        window.addEventListener('keydown', this.onKeyDown);
      }
    } catch {
      this.$emit('isModelSupported', false);
    }
  },
  data: () => ({
    pcd: false,
    onlyEnablePCD: false,
    tools: false,
    culling: false,
    hemisphericalCircle: false,
    snapOpen: false,
    snap: false,
    percentage: 0,
    planerToggle: { x: false, y: false, z: false },
    timeUntilMouseMoveUpdate: 0,
    activeTool: '',
    mouseDownClientX: 0,
    mouseDownClientY: 0,
  }),
  watch: {
    model: {
      immediate: false,
      handler(value: ModelData) {
        if (value.pcd !== '' && document.getElementsByClassName('box').length !== 0) {
          this.load(value.model, value.pcd, value.modelFlip,
            value.scaleX, value.scaleY, value.scaleZ);
        }
      },
    },
    activeTool: {
      immediate: false,
      handler(value: string) {
        (worker as any).postMessage({
          type: 'activeToolChange',
          value,
        });
      },
    },
  },
  methods: {
    onMouseClick(event: MouseEvent) {
      if (this.mouseDownClientX !== event.clientX || this.mouseDownClientY !== event.clientY) {
        return;
      }
      if (this.activeTool === 'measurement') {
        const canvas = (document.getElementById('c') as HTMLCanvasElement);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (worker as any).postMessage({
          type: 'mouseClick',
          x: ((event.offsetX / canvas.clientWidth) * 2) - 1,
          y: (-(event.offsetY / canvas.clientHeight) * 2) + 1,
        });
      }

      if (event.button === 2) {
        this.resetMeasurement();
      }
    },
    onMouseDown(event: MouseEvent) {
      this.mouseDownClientX = event.clientX;
      this.mouseDownClientY = event.clientY;
    },
    onKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape' && this.activeTool === 'measurement') this.resetMeasurement();
    },
    load(model: string, pFile: string, rotateModel: boolean,
      X: number, Y: number, Z: number) {
      try {
        const canvas = (document.getElementById('c') as HTMLCanvasElement);
        const image = (document.getElementById('modelCanvasImage') as HTMLImageElement);
        image.crossOrigin = 'Anonymous';

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (worker as any).onmessage = this.onMessage;
        canvas.addEventListener('mousemove', this.onMouseMove, false);
        canvas.addEventListener('mousedown', this.onMouseDown, false);
        canvas.addEventListener('mouseup', this.onMouseClick, false);
        canvas.addEventListener('contextmenu', this.onMouseClick, false);
        canvas.addEventListener('wheel', this.onMouseWheel, false);
        image.onload = function imgLoad() {
          const offscreen = new OffscreenCanvas(image.width, image.height);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const context = (offscreen.getContext('2d') as any);
          context.drawImage(image, 0, 0);

          const imgData = context.getImageData(0, 0, image.width, image.height);

          // Events
          (document.getElementById('resetmeasure') as HTMLButtonElement).addEventListener('click', () => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (worker as any).postMessage({ type: 'resetMeasure' });
          });

          (worker as any).postMessage({
            type: 'load',
            imageData: imgData,
            model3d: model,
            pcd: pFile,
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
            width: image.width,
            height: image.height,
            rotate: rotateModel,
            scaleX: X,
            scaleY: Y,
            scaleZ: Z,
          });
        };
      } catch (e) {
        console.log(e);
      }
    },
    onMessage(e: any) {
      const split = e.data.split(',');
      switch (split[0]) {
        case 'load':
          this.updateLoadBar(parseFloat(split[1]));
          break;
        case 'updateAngle':
          this.updateAngle(parseFloat(split[1]));
          break;
        case 'measure':
          if (document.getElementById('measure') != null) {
            (document.getElementById('measure') as HTMLElement).innerHTML = `${String(split[1])}`;
          }
          break;
        case 'enableOnlyPointCloud':
          this.pcd = true;
          this.onlyEnablePCD = true;
          break;
        default:
          break;
      }
    },
    toggleSnap() {
      this.snap = !this.snap;
      (worker as any).postMessage({ type: 'toggleSnap', value: this.snap });
    },
    toggleModels() {
      if (!this.onlyEnablePCD) {
        this.pcd = !this.pcd;
        (worker as any).postMessage({ type: 'toggleM' });
      }
    },
    toggleTools() {
      this.tools = !this.tools;
      (worker as any).postMessage({ type: 'toggleTools' });
    },
    toggleCulling() {
      this.culling = !this.culling;
      (worker as any).postMessage({ type: 'toggleC' });
    },
    toggleHemisphericalCircle() {
      this.hemisphericalCircle = !this.hemisphericalCircle;
      (worker as any).postMessage({ type: 'toggleHemisphericalCircle' });
    },
    togglePlanerSnapX() {
      this.planerToggle.x = !this.planerToggle.x;
      (worker as any).postMessage({
        type: 'togglePlaner', x: this.planerToggle.x, y: this.planerToggle.y, z: this.planerToggle.z,
      });
    },
    togglePlanerSnapY() {
      this.planerToggle.y = !this.planerToggle.y;
      (worker as any).postMessage({
        type: 'togglePlaner', x: this.planerToggle.x, y: this.planerToggle.y, z: this.planerToggle.z,
      });
    },
    togglePlanerSnapZ() {
      this.planerToggle.z = !this.planerToggle.z;
      (worker as any).postMessage({
        type: 'togglePlaner', x: this.planerToggle.x, y: this.planerToggle.y, z: this.planerToggle.z,
      });
    },
    onMouseMove(event:MouseEvent) {
      const canvas = (document.getElementById('c') as HTMLCanvasElement);
      const d = new Date();
      if (d.getTime() > this.timeUntilMouseMoveUpdate) {
        this.timeUntilMouseMoveUpdate = d.getTime() + (1000 / 10);
        (worker as any).postMessage({
          type: 'mouseMove',
          x: ((event.offsetX / canvas.clientWidth) * 2) - 1,
          y: (-(event.offsetY / canvas.clientHeight) * 2) + 1,
        });
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onMouseWheel(event: WheelEvent) {
      (worker as any).postMessage({
        type: 'wheelEvent',
        direction: Math.sign(event.deltaY),
      });
    },
    frontView(e: MouseEvent) {
      if (e.shiftKey) {
        (worker as any).postMessage({ type: 'frontView', shift: 'y' });
      } else {
        (worker as any).postMessage({ type: 'frontView', shift: 'n' });
      }
    },
    sideView(e: MouseEvent) {
      if (e.shiftKey) {
        (worker as any).postMessage({ type: 'sideView', shift: 'y' });
      } else {
        (worker as any).postMessage({ type: 'sideView', shift: 'n' });
      }
    },
    topView(e: MouseEvent) {
      if (e.shiftKey) {
        (worker as any).postMessage({ type: 'topView', shift: 'y' });
      } else {
        (worker as any).postMessage({ type: 'topView', shift: 'n' });
      }
    },
    isoView() {
      (worker as any).postMessage({ type: 'isoView', shift: 'n' });
    },
    canvasResizeEvent() {
      setTimeout(() => {
        const canvasDiv = (document.getElementById('modelCanvas') as HTMLElement);
        const width = parseInt(window.getComputedStyle(canvasDiv, null).getPropertyValue('width'), 10);
        const height = parseInt(window.getComputedStyle(canvasDiv, null).getPropertyValue('height'), 10);
        this.$emit('updateHeight', height);
        if (worker) {
          (worker as any).postMessage({ type: 'canvasResize', width: String(width), height: String(height) });
        }
      }, 200);
    },
    updateLoadBar(percent: number) {
      this.$emit('on-progress-changed', percent);
      this.percentage = percent;
    },
    updateAngle(angle: number) {
      this.$emit('on-angle-changed', angle);
    },
    resetMeasurement(): void {
      (worker as any).postMessage({ type: 'resetMeasure' });
    },
    toggleMeasurement(): void{
      if (this.activeTool === 'measurement') {
        this.activeTool = '';
        this.resetMeasurement();
      } else {
        this.activeTool = 'measurement';
      }
    },
  },
});
