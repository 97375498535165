import User from '@/auth/User';
import { DetailedUserData } from '@/store/users/types';
import { ClientInfo, TeamMember } from '../types';

export default class MPSSectionContactsDTO {
    userNameList = [];

    newClientFirstName = '';

    newClientLastName = '';

    newClientTitle = '';

    newClientPhone = '';

    newClientEmail = '';

    newClientRole = '';

    newClientCompany = '';

    newClientAddress = '';

    addClientDialog = false;

    userNameModel = { value: -1, text: '' };

    validName = false;

    validUser = false;

    newMemberName = '';

    newMemberTitle = '';

    newMemberPhone = '';

    newMemberEmail = '';

    newMemberDept = '';

    addMemberDialog = false;

    validClient = false as boolean;

    isEditing = false;

    editIndex = -1;

    newPhoneCode = '+1';

    newPhoneRegion = {
      name: 'United States',
      placeholder: '(123) 456-7890',
      rules: [(v: string): string | boolean | number => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) || 'Phone number not valid'],
    };

    formatRegionCode(): void {
      if (this.newPhoneRegion.name === 'United States') {
        this.newPhoneCode = '+1';
      } else if (!this.newPhoneCode.includes('+')) {
        this.newPhoneCode = `+${this.newPhoneCode}`;
      }
    }

    formatPhone(phone: string): string {
      if (this.newPhoneRegion.name === 'United States') {
        const x = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        return !x[2]
          ? x[1]
          : `(${x[1]}) ${x[2]}${x[3]
            ? `-${x[3]}`
            : ''}`;
      }

      if (this.newPhoneRegion.name === 'International') {
        const x = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,4})/);
        // eslint-disable-next-line no-nested-ternary
        return !x[2]
          ? x[1]
          : x[4]
            ? `${x[1]} ${x[2]} ${x[3]} ${x[4]}`
            : `${x[1]} ${x[2]} ${x[3]}`;
      }

      return '';
    }

    removeMember(item: TeamMember, TeamMembers: TeamMember[]): boolean {
      try {
        const deleteIndex = TeamMembers.findIndex(
          (value) => value.email === item.email,
        );
        if (deleteIndex === -1) {
          // If we can not find the item in syncedTeamMembers
          return false;
        }
        TeamMembers.splice(deleteIndex, 1);
      } catch (e) {
        return false;
      }
      return true;
    }

    removeClient(item: ClientInfo, allClientInfo: ClientInfo[]): boolean {
      try {
        const deleteIndex = allClientInfo.findIndex(
          (value) => value.email === item.email,
        );
        if (deleteIndex === -1) {
          // If we can not find the item in syncedClientInfo
          return false;
        }
        allClientInfo.splice(deleteIndex, 1);
      } catch (e) {
        return false;
      }
      return true;
    }

    addClient(allClientInfo: ClientInfo[]): void {
      const newClient = {
        firstName: this.newClientFirstName,
        lastName: this.newClientLastName,
        title: this.newClientTitle,
        phone: this.newClientPhone,
        phoneCode: this.newPhoneCode,
        phoneDetails: this.newPhoneRegion,
        email: this.newClientEmail,
        dept: '',
        role: this.newClientRole,
        address: this.newClientAddress,
        company: this.newClientCompany,
      };

      if (this.isEditing && this.editIndex !== -1) {
        this.removeClient(allClientInfo[this.editIndex], allClientInfo);
      }

      allClientInfo.push(newClient);
      this.resetContactForm();
    }

    editContact(info: ClientInfo, allClientInfo: ClientInfo[]): void {
      this.newClientFirstName = info.firstName;
      this.newClientLastName = info.lastName;
      this.newClientTitle = info.title;
      this.newClientPhone = info.phone;
      this.newPhoneCode = info.phoneCode;
      this.newPhoneRegion = info.phoneDetails;
      this.newClientEmail = info.email;
      this.newClientRole = info.role;
      this.newClientAddress = info.address;
      this.newClientCompany = info.company;
      this.addClientDialog = true;
      this.isEditing = true;
      this.editIndex = allClientInfo.findIndex((c) => c.email === info.email);
    }

    resetContactForm(): void {
      this.newClientFirstName = '';
      this.newClientLastName = '';
      this.newClientTitle = '';
      this.newClientPhone = '';
      this.newClientEmail = '';
      this.newClientRole = '';
      this.newClientAddress = '';
      this.newClientCompany = '';
      this.addClientDialog = false;
      this.isEditing = false;
      this.editIndex = -1;
      this.validClient = false;
      this.resetPhone();
    }

    editMember(info: TeamMember, TeamMembers: TeamMember[]): void {
      this.newMemberName = `${info.firstName} ${info.lastName}`;
      this.newMemberTitle = info.title;
      this.newMemberPhone = info.phone;
      this.newPhoneCode = info.phoneCode;
      this.newPhoneRegion = info.phoneDetails;
      this.newMemberEmail = info.email;
      this.newMemberDept = info.dept;
      this.addMemberDialog = true;
      this.isEditing = true;
      this.editIndex = TeamMembers.findIndex((m) => m.email === info.email);
    }

    resetMemberForm(): void {
      this.newMemberName = '';
      this.validName = false;
      this.validUser = false;
      this.newMemberTitle = '';
      this.newMemberPhone = '';
      this.newMemberEmail = '';
      this.newMemberDept = '';
      this.addMemberDialog = false;
      this.editIndex = -1;
      this.resetPhone();
    }

    resetPhone(): void {
      this.newClientPhone = '';
      this.newMemberPhone = '';
      this.newPhoneRegion = {
        name: 'United States',
        placeholder: '(123) 456-7890',
        rules: [(v: string): string | boolean | number => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) || 'Phone number not valid'],
      };
      this.newPhoneCode = '+1';
    }

    addMember(TeamMembers: TeamMember[]): void {
      const newMember = {
        firstName: this.newMemberName.split(' ')[0],
        lastName: this.newMemberName.split(' ').length > 1 ? this.newMemberName.split(' ')[1] : '',
        title: this.newMemberTitle,
        phone: this.newMemberPhone,
        phoneCode: this.newPhoneCode,
        phoneDetails: this.newPhoneRegion,
        email: this.newMemberEmail,
        dept: this.newMemberDept,
      };

      if (this.isEditing && this.editIndex !== -1) {
        this.removeMember(TeamMembers[this.editIndex], TeamMembers);
      }

      TeamMembers.push(newMember);
      this.resetMemberForm();
      this.isEditing = false;
    }

    async addUserAsMember(
      allDetailedUsers: DetailedUserData[],
      user: User,
      TeamMembers: TeamMember[],
    ): Promise<void> {
      const detailedUser = allDetailedUsers.find((usr) => usr.useridentity === `auth0|${user.id}`);

      if (detailedUser == null) {
        return;
      }

      this.newMemberName = `${detailedUser.firstname} ${detailedUser.lastname}`;
      this.newMemberTitle = 'Project Manager';
      this.newMemberPhone = '';
      this.newMemberEmail = user.email;
      this.newMemberDept = 'Project Management';
      this.addMember(TeamMembers);
    }
}
