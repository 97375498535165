









































































































































































/* eslint-disable @typescript-eslint/no-explicit-any, no-param-reassign */
import { namespace } from 'vuex-class';
import { Component, Watch } from 'vue-property-decorator';
import { ProjectActions } from '@/store/project/actions';
import { CollectionMetrics, ProjectData, ProjectListing } from '@/store/project/types';
import SideDrawer from '@/components/SideDrawer/SideDrawer.vue';
import MPSBase from '@/views/MasterProjectSummary/MPSBase.vue';
import { DataTableHeader } from '@/components/AssetTable/types';
import IntegrityTable, {
  AdditionalFilterFunction, FillFunctionContainer, FilterFunction, processDate,
} from '@/components/IntegrityTable/IntegrityTable.vue';
import utils from '@/components/IntegrityTable/utils';
import IntegrityDelete from '@/components/IntegrityDelete/IntegrityDelete.vue';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import { UserPermission } from '@/store/userpermissions/types';
import { uuid } from 'vue-uuid';

const projectModule = namespace('project');
const userPrefsModule = namespace('userPrefs');

@Component({
  components: {
    SideDrawer, MPSBase, IntegrityTable, IntegrityDelete,
  },
})
export default class ProjectSelection extends UserPermissionsMixin {
  drawerOpen = false;

  expanded = [];

  toggleHUDValue = true;

  sortByDesc = false;

  sortBy = 'name';

  projectVisible = [] as Array<ProjectListing>;

  tableData: Array<ProjectListing> = undefined;

  tableHeight: '100%';

  loading = true;

  validPlatforms = [
    'CCTV Collection',
    'GPS Collection',
    'MACP Form Collection',
    'P3D Collection',
    'Responder Collection',
    'Solo Data Collection',
    'Solo Manhole Collection',
    'Vertue Collection',
  ]

  matchFilters = [
    {
      header: 'name', type: 'string', value: '', method: '', options: [], tempValue: '',
    },
    {
      header: 'projectNumber', type: 'string', value: '', method: '', options: [], tempValue: '',
    },
    {
      header: 'totalFootage', type: 'number', value: '', method: '', options: [], tempValue: '',
    },
    {
      header: 'totalManholes', type: 'number', value: '', method: '', options: [], tempValue: '',
    },
    {
      header: 'dueDate', type: 'date', value: '', method: '', options: [], tempValue: '',
    },
    {
      header: 'status', type: 'string', value: '', method: '', options: [], tempValue: '',
    },
  ]

  get tableFillFunctions(): FillFunctionContainer {
    const returnValue: FillFunctionContainer = {
      updateKey: uuid.v4(),
      fillFunctions: [
        {
          headerValue: 'status',
          functionVariables: [utils.getConvertedDistance.toString()],
          fillFunction: function fillStatus(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            _item: ProjectListing,
          ): string {
            return 'Active';
          },
        },
        {
          headerValue: 'dueDate',
          functionVariables: [processDate.toString()],
          fillFunction: function fillDueDate(
            item: ProjectListing,
            processDateFunctionString: string,
          ): string {
            // eslint-disable-next-line no-new-func
            return new Function(`return ${processDateFunctionString};`)()(item.dueDate);
          },
        },
      ],
    };
    return returnValue;
  }

  get additionalFilterFunctions(): AdditionalFilterFunction {
    const filterFunctions: FilterFunction[] = [
      {
        functionVariables: [],
        filterFunction: function isAssetVisible(item) {
          return item.visible === true;
        },
      },
    ];
    return {
      updateKey: uuid.v4(),
      filterFunctions,
    };
  }

  get subTableFillFunctions(): FillFunctionContainer {
    const returnValue: FillFunctionContainer = {
      updateKey: uuid.v4(),
      fillFunctions: [
        {
          headerValue: 'amount',
          functionVariables: [utils.getConvertedDistance.toString()],
          fillFunction: function fillAmount(
            item: CollectionMetrics,
            getConvertedDistanceFunction: string,
          ): string {
            if (item.type === 'Line Segment') {
              // eslint-disable-next-line no-new-func
              return new Function(`return ${getConvertedDistanceFunction};`)()(item.amount);
            }
            return Math.round(item.amount).toLocaleString();
          },
        },
        {
          headerValue: 'percent',
          functionVariables: [],
          fillFunction: function fillPercent(
            item: CollectionMetrics,
          ): string {
            return Math.round(item.percent).toString();
          },
        },
      ],
    };
    return returnValue;
  }

  subTableHeaders = [
    {
      text: 'Asset Type', value: 'type', sortable: true, filterable: true,
    },
    {
      text: 'Total', value: 'amount', sortable: true, filterable: false,
    },
    {
      text: 'Platform', value: 'platform', sortable: true, filterable: true,
    },
    {
      text: 'Collected', value: 'percent', sortable: true, filterable: false,
    },
  ]

  subMatchFilters = [
    {
      header: 'type', type: 'string', value: '', method: '', options: [], tempValue: '',
    },
    {
      header: 'amount', type: 'number', value: '', method: '', options: [], tempValue: '',
    },
    {
      header: 'platform', type: 'string', value: '', method: '', options: [], tempValue: '',
    },
    {
      header: 'percent', type: 'number', value: '', method: '', options: [], tempValue: '',
    },
  ]

  selectedProjects = [];

  search = '';

  @projectModule.Action(ProjectActions.FETCH_ALL_PROJECTS_DATA) fetchAllProjectsData;

  @projectModule.Action(ProjectActions.FETCH_DETAILED_PROJECT_METRICS) fetchDetailedProjectMetrics;

  @projectModule.Action(ProjectActions.DELETE_PROJECT) deleteProject;

  @projectModule.Action(ProjectActions.CLEAR_PROJECT_DATA) clearProjectData;

  @projectModule.State('detailedProjectMetrics') detailedProjectMetrics: CollectionMetrics[];

  @projectModule.State('loadingMetrics') loadingMetrics: boolean;

  @projectModule.State('allProjectsData') allProjects: ProjectListing[] | undefined;

  @projectModule.State('data') projectData: ProjectData | undefined;

  @projectModule.State('loadError') loadError;

  @projectModule.State('loading') projectLoading: boolean;

  @userPrefsModule.State('displayImperial') displayImperial: boolean;

  @Watch('expanded', { deep: true })
  onExpandedChange(): void {
    if (this.expanded.length > 0) this.fetchDetailedProjectMetrics(this.expanded[0].guid);
  }

  @Watch('projectLoading')
  onProjectLoadChange(): void {
    if (this.projectLoading === false) {
      this.setTableData();
    }
  }

  get filteredProjectMetrics(): CollectionMetrics[] {
    return this.detailedProjectMetrics
      ? this.detailedProjectMetrics.filter((pm) => this.isValidPlatform(pm.platform)
      && (pm.percent as any) !== 'NaN')
      : [];
  }

  get headers(): DataTableHeader[] {
    const headers = [
      {
        text: 'Project',
        align: 'start',
        sortable: true,
        filterable: true,
        value: 'name',
        class: 'sticky',
        cellClass: 'sticky',
      },
      {
        text: 'Project Number',
        align: 'start',
        sortable: true,
        filterable: true,
        value: 'projectNumber',
        class: 'sticky',
        cellClass: 'sticky',
      },
      {
        text: '',
        align: 'center',
        sortable: false,
        filterable: false,
        value: 'guid',
        class: 'sticky',
        cellClass: 'sticky',
      },
      {
        text: 'Total Footage',
        align: 'start',
        sortable: true,
        filterable: true,
        value: 'totalFootage',
      },
      {
        text: 'Total Manholes',
        align: 'start',
        sortable: true,
        filterable: true,
        value: 'totalManholes',
      },
      {
        text: 'Due Dates',
        align: 'start',
        sortable: true,
        filterable: true,
        value: 'dueDate',
      },
      {
        text: 'Status',
        align: 'start',
        sortable: true,
        filterable: true,
        value: 'status',
      },
      {
        text: 'Actions',
        align: 'start',
        sortable: false,
        filterable: false,
        value: 'actions',
        class: 'sticky-end',
        cellClass: 'sticky-end',
      },
    ];

    if (!this.hasPermissionProjectSelectionCommon) {
      const launchIndex = headers.findIndex((h) => h.value === 'guid');
      headers.splice(launchIndex, 1);
    }

    return headers;
  }

  get metricDataExists(): boolean {
    return this.filteredProjectMetrics && this.filteredProjectMetrics.length > 0;
  }

  /**
   * @returns true if the user has the permission PROJECT_SELECTION_COMMON
   */
  get hasPermissionProjectSelectionCommon(): boolean {
    return this.hasPermission(UserPermission.PROJECT_SELECTION_COMMON);
  }

  /**
   * @returns true if the user has the permission PROJECT_SELECTION_CREATE_A_PROJECT
   */
  get hasPermissionProjectSelectionCreateProject(): boolean {
    return this.hasPermission(UserPermission.PROJECT_SELECTION_CREATE_A_PROJECT);
  }

  /**
   * @returns true if the user has the permission PROJECT_SELECTION_EDIT_A_PROJECT
   */
  get hasPermissionProjectSelectionEditProject(): boolean {
    return this.hasPermission(UserPermission.PROJECT_SELECTION_EDIT_A_PROJECT);
  }

  /**
   * @returns true if the user has the permission PROJECT_SELECTION_DELETE_A_PROJECT
   */
  get hasPermissionProjectSelectionDeleteProject(): boolean {
    return this.hasPermission(UserPermission.PROJECT_SELECTION_DELETE_A_PROJECT);
  }

  getFilteredData(): ProjectListing[] {
    if (this.allProjects !== null) {
      const returnArray = this.projectVisible;
      return returnArray;
    }
    return undefined;
  }

  async handleDelete(projectGuid: string): Promise<boolean> {
    try {
      const delProj = this.projectVisible.find((x) => x.guid === projectGuid);

      if (this.expanded.length > 0 && delProj.guid === this.expanded[0].guid) {
        this.expanded = [];
      }
      await this.deleteProject(projectGuid);

      if (delProj) delProj.visible = false;
      this.tableData = this.getFilteredData();
    } catch {
      return false;
    }
    return true;
  }

  handleEdit(guid: string): void {
    this.$router.push({
      name: 'MPS',
      params: { guid },
    });
  }

  handleNew(): void {
    this.$router.push({
      name: 'MPS',
    });
  }

  isValidPlatform(platform: string): boolean {
    return this.validPlatforms.some((p) => p === platform);
  }

  searchChange(value: string): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.assetList as any).searchChange(value);
  }

  // Retuns an image representing the collection platform
  getPlatformImage(platform: string): unknown {
    let retVal = require.context('../../assets/', false, /\.png$/);

    switch (platform) {
      case 'CCTV Collection':
        retVal = retVal('./platform_md.png');
        break;
      case 'GPS Collection':
        // TODO: Implement icon for GPS Collection
        break;
      case 'MACP Form Collection':
        // TODO: Implement icon for MACP Form Collection
        break;
      case 'P3D Collection':
        // TODO: Implement icon for P3D Collection
        break;
      case 'Responder Collection':
        retVal = retVal('./platform_responder.png');
        break;
      case 'Solo Data Collection':
      case 'Solo Manhole Collection':
        retVal = retVal('./platform_solo.png');
        break;
      case 'Vertue Collection':
        retVal = retVal('./platform_vertue.png');
        break;
      default:
        break;
    }

    return retVal;
  }

  navigateToSelectedProjects(): void {
    this.$router.push({
      name: 'projects',
      params: { ids: JSON.stringify(this.selectedProjects.map((proj) => proj.guid)) },
    });
  }

  async mounted(): Promise<void> {
    if (!this.hasPermissionProjectSelectionCommon) {
      this.goToErrorPage();
    }

    if (this.projectData !== undefined) {
      await this.clearProjectData();
    }

    if (this.allProjects === undefined) {
      await this.fetchAllProjectsData().catch(() => {
        this.$router.push({
          name: 'Error',
          params: { catchAll: 'Error', message: 'There was an error retrieving your projects. Please try again later. If this issue persists, please contact support.' },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        }).catch(() => {});
      });
    } else {
      this.setTableData();
    }
  }

  async getRoles(): Promise<string[]> {
    return this.$auth.getRoles(`auth0|${this.$auth.user.id}`);
  }

  getConvertedDistance(distance: number): string {
    return utils.getConvertedDistance(distance, this.displayImperial);
  }

  setTableData(): void {
    this.allProjects.forEach((project) => {
      const p = this.projectVisible.find((proj) => proj.guid === project.guid);
      // eslint-disable-next-line no-param-reassign
      if (!p) { this.projectVisible.push({ ...project, visible: true }); }
    });
    this.tableData = this.getFilteredData();
    this.loading = false;
    this.$forceUpdate();
  }

  uniqueValues(value: string, index: number, arr: string[]): boolean {
    return arr.indexOf(value) === index;
  }
}
