















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Mapping } from '@/store/types';
import { AssetActions } from '@/store/asset/actions';
import {
  FILE_MAPPING_OPERATOR_RESOURCE,
  FILE_MAPPING_EQUIPMENT_RESOURCE,
  FILE_MAPPING_MATERIAL_RESOURCE,
  FILE_MAPPING_SERVICE_RESOURCE,
} from '@/common/Constants';
import filemapper from '../ScopeAddition/FileMapper.vue';
import {
  iBaseCampFileMapper,
  iBasecampColumn,
  iBaseCampFileMapperOptions,
  IBaseCampFileMapperWithOptions,
} from '../ScopeAddition/iBaseCampFileMapper';
import { iFileMapperWithOptions } from '../ScopeAddition/iFileMapper';

const assetModule = namespace('asset');
const projectModule = namespace('project');

const ALERT_TYPE_ERROR = 'error';
const ALERT_TYPE_SUCCESS = 'success';
const ALERT_TYPE_INFO = 'info';

@Component({
  components: {
    filemapper,
  },
})
export default class ImportResourceFileUpload extends Vue {
  @Prop() selectedResource: string;

  selectedFile = null;

  haveUploadFileColumns = false as boolean;

  uploadFileColumns = [];

  uploadFileName = '';

  showAlert = false as boolean;

  alertType = 'error' as string;

  alertText = '' as string;

  rzFileColumns: Mapping[] = [];

  @assetModule.Action(AssetActions.POST_FILE_DATA) postFileData;

  @assetModule.Action(AssetActions.POST_FILE_MAPPING_DATA) postMappingData;

  @projectModule.State('projectGuids') projectGuids: string[];

  generateFileImportMappings(): string[] {
    switch (this.selectedResource) {
      case 'Operator':
        return [FILE_MAPPING_OPERATOR_RESOURCE];
      case 'Equipment':
        return [FILE_MAPPING_EQUIPMENT_RESOURCE];
      case 'Material':
        return [FILE_MAPPING_MATERIAL_RESOURCE];
      case 'Service':
        return [FILE_MAPPING_SERVICE_RESOURCE];
      default:
        return [];
    }
  }

  isValidForm(): boolean {
    if ((this.$refs.resourceForm as any).validate()) {
      return true;
    }
    this.alertType = 'error';
    this.alertText = 'Please enter required fields';
    this.showAlert = true;
    return false;
  }

  async onClickUpload(): Promise<void> {
    if (!this.isValidForm()) return;
    // temporary values for assetChosen, gasGISData, hasInspections
    const payload = {
      selectedFile: this.selectedFile,
      mappings: this.generateFileImportMappings(),
    };
    try {
      const status = await this.postFileData(payload);
      this.alertType = 'info';
      this.alertText = 'Please map the columns';
      this.showAlert = true;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.uploadFileColumns = status.FileHeaders;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.rzFileColumns = status.RzColumns;
      this.uploadFileName = status.FileName;
      this.haveUploadFileColumns = true;
    } catch (e) {
      this.alertType = 'error';
      this.alertText = (e as Error).message;
      this.showAlert = true;
    }
  }

  onClickCancel(): void {
    (this.$refs.resourceForm as any).reset();
    this.selectedFile = null;
    this.haveUploadFileColumns = false;
    this.showAlert = false;
  }

  async columnsMappedComplete(
    fileMapperWithOptions: iFileMapperWithOptions,
  ): Promise<void> {
    if (!this.projectGuids) {
      this.alertType = ALERT_TYPE_ERROR;
      this.alertText = 'No valid project';
      this.showAlert = true;
      return;
    }
    const fileMapper = fileMapperWithOptions.Mappings;
    const basecampFileMapper = {} as iBaseCampFileMapper;
    basecampFileMapper.FileName = this.uploadFileName;
    // eslint-disable-next-line prefer-destructuring
    basecampFileMapper.ProjectGuid = this.projectGuids[0];
    basecampFileMapper.FileImportMappingGuids = this.generateFileImportMappings();
    basecampFileMapper.Columns = [];
    for (let i = 0; i < fileMapper.length; i += 1) {
      const bc = {} as iBasecampColumn;
      bc.RedzoneColumn = fileMapper[i].RzColumn;
      bc.FileColumn = fileMapper[i].FileColumn;
      basecampFileMapper.Columns.push(bc);
    }

    const fileMapperOptions: iBaseCampFileMapperOptions = {
      email: fileMapperWithOptions.Options.Email,
    };

    const fileMapperDTO: IBaseCampFileMapperWithOptions = {
      options: fileMapperOptions,
      mapping: basecampFileMapper,
    };

    try {
      await this.postMappingData(JSON.stringify(fileMapperDTO));
    } catch (e) {
      this.alertType = ALERT_TYPE_ERROR;
      this.alertText = 'There was an error sending your scope request';
      this.showAlert = true;
      return;
    }
    this.showAlert = true;
    this.alertText = 'Submission Succesful! An email will be sent when the request is completed.';
    this.alertType = ALERT_TYPE_SUCCESS;
    this.selectedFile = null;
    this.haveUploadFileColumns = false;
    (this.$refs.resourceForm as HTMLFormElement).resetValidation();
  }
}
