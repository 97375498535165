










































































import { Component, Prop, PropSync } from 'vue-property-decorator';
import { DetailedUserData } from '@/store/users/types';
import { PhoneInfo } from '@/components/IntegrityPhoneNumberInput/types';
import { OrganizationData } from '@/store/organizations/types';
import { SYSTEM_ADMIN_GUID } from '@/auth/roles';
import { FilterFunction } from '@/components/IntegrityTable/IntegrityTable.vue';
import NewUserPopup from '../NewUserPopup.vue';
import IntegrityPhoneNumberInput from '../../IntegrityPhoneNumberInput/IntegrityPhoneNumberInput.vue';
import AdminPanelMixin from '../AdminPanelMixin.vue';

const defaultOrganization = {
  guid: undefined,
  organizationName: '',
  address: '',
  systemAdmin: null,
  email: '',
  phoneNumber: {
    phone: '',
    phoneCode: '+1',
    isInternational: false,
  },
};

@Component({
  components: {
    NewUserPopup,
    IntegrityPhoneNumberInput,
  },
})
export default class OrganizationEditDialog extends AdminPanelMixin {
  @PropSync('show') showDialog: boolean;

  @Prop() saveOrganizationData;

  @Prop({ default: false }) organizationLoading: boolean;

  @Prop() title: string;

  @Prop({
    default: () => ({ ...defaultOrganization }),
  })
  editItem: {
    guid: string | undefined;
    organizationName: string;
    address: string;
    systemAdmin: DetailedUserData | undefined;
    email: string;
    phoneNumber: PhoneInfo | undefined;
  };

  valid = false as boolean;

  systemAdminGuid = SYSTEM_ADMIN_GUID as string;

  filterFunctions: FilterFunction[] = [
    {
      functionVariables: [],
      filterFunction: function filterOutExistingOrgUsers(item: DetailedUserData): boolean {
        return item.organizationGuids == null || item.organizationGuids.length === 0;
      },
    },
  ]

  cancel(): void {
    (this.$refs.organizationForm as HTMLFormElement).resetValidation();
    this.showDialog = false;
  }

  /**
   * @description Calls endpoint to post or patch an organization and fetches new organization data.
   */
  async saveOrganization(): Promise<void> {
    const phoneCode = this.editItem.phoneNumber?.isInternational
      ? `${this.editItem.phoneNumber.phoneCode} `
      : '';
    const phone = this.editItem.phoneNumber?.phone
      ? `${this.editItem.phoneNumber.phone}`
      : '';

    const organizationSaveObject: OrganizationData = {
      guid: this.editItem.guid,
      name: this.editItem.organizationName,
      address: this.editItem.address,
      systemAdminGuid: this.editItem.systemAdmin?.guid,
      email: this.editItem.email,
      phoneNumber: `${phoneCode}${phone}`,
    };

    await this.saveOrganizationData(organizationSaveObject);
    const fetches = [
      this.fetchOrganizationData(),
      this.fetchCustomerData(),
      this.fetchAllDetailedUserData(),
    ];
    await Promise.all(fetches);
    this.editItem = { ...defaultOrganization };
    // No need to await this update for all users
    this.updateAllManagedUsers();
    this.$emit('organizationSaved', organizationSaveObject);
    this.showDialog = false;
  }
}
