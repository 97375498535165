import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { mutations } from './mutations';
import { actions } from './actions';
import { DefectCodingState } from './types';

const state: DefectCodingState = {

  defectCodingData: undefined,
  defectCodingLoading: false,
  defectCodingLoadError: undefined,
  defectCodingPostError: undefined,
  defectCodingPostLoading: false,
  defectCodingDeleteError: undefined,
  defectCodingDeleteLoading: false,
  defectCodingPatchError: undefined,
  defectCodingPatchLoading: false,
  validateAndScoreData: undefined,
  validateAndScoreLoading: undefined,
  confirmScoreReset: false,
};

const defectCoding: Module<DefectCodingState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default defectCoding;
