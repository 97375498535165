


















































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { MPSActions } from '@/store/mps/actions';
import {
  DATASTANDARD_MACP7_LEVEL1,
  DATASTANDARD_MACP7_LEVEL2,
  EMPTY_GUID,
} from '@/common/Constants';
import { uuid } from 'vue-uuid';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import { UserPermission } from '@/store/userpermissions/types';
import { OrganizationActions } from '@/store/organizations/actions';
import { CustomerActions } from '@/store/customers/actions';
import { OrganizationData } from '@/store/organizations/types';
import { CustomerData } from '@/store/customers/types';
import { ProjectResource } from '@/store/resources/types';
import { ResourceActions } from '@/store/resources/actions';
import { UserActions } from '@/store/users/actions';
import { DetailedUserData } from '@/store/users/types';
import { ProjectMutations } from '@/store/project/mutations';
import MPSSectionInformation from './MPSSectionInformation.vue';
import MPSSectionData from './MPSSectionData.vue';
import MPSSectionContacts from './MPSSectionContacts.vue';
import MPSSectionResources from './MPSSectionResources.vue';
import MPSFooter from './MPSFooter.vue';
import {
  ClientInfo,
  DataRequirement,
  MacpSection,
  TeamMember,
} from './types';
import {
  Currency, customField, iMPS, PostMPSDTO,
} from './iMPS';
import MPSSectionContactsDTO from './common/MPSSectionContactsDTO';

const MPSModule = namespace('mps');
const organizationModule = namespace('organizations');
const customerModule = namespace('customers');
const resourcesModule = namespace('resources');
const userModule = namespace('users');
const projectModule = namespace('project');

@Component({
  components: {
    MPSSectionInformation,
    MPSSectionData,
    MPSSectionContacts,
    MPSSectionResources,
    MPSFooter,
  },
})
export default class MPS extends UserPermissionsMixin {
  @Prop() readonly guid: string | undefined;

  @MPSModule.Action(MPSActions.POST_INSERT_MPS) postMPS;

  @MPSModule.Action(MPSActions.FETCH_MPS_DATA) fetchMPSData;

  @MPSModule.Action(MPSActions.PATCH_MPS) patchMPS;

  @MPSModule.State('loadError') mpsLoadError;

  @MPSModule.State('mpsData') mpsData: iMPS;

  @MPSModule.State('loading') isMPSRequestLoading;

  @organizationModule.Action(OrganizationActions.FETCH_ORGANIZATION_DATA)
  fetchOrganizationData;

  @organizationModule.State('organizationData') organizationDataList:
    | OrganizationData[]
    | undefined;

  @customerModule.Action(CustomerActions.FETCH_CUSTOMER_DATA)
  fetchCustomerData;

  @customerModule.State('customerData') customerDataList:
    | CustomerData[]
    | undefined;

  @resourcesModule.Action(ResourceActions.FETCH_PROJECT_RESOURCE)
  fetchProjectResource;

  @resourcesModule.State('projectResource') projectResource:
    | ProjectResource
    | undefined;

  @userModule.Action(UserActions.FETCH_ALL_DETAILED_USER_DATA) fetchAllDetailedUserData;

  @userModule.State('allDetailedUserData') allDetailedUsers: DetailedUserData[] | undefined;

  @userModule.State('loading') loadingUsers: boolean;

  @projectModule.State('projectGuids') projectGuids: string[];

  @projectModule.Mutation(ProjectMutations.SET_PROJECT_GUIDS) setProjectGuids;

  readonly NUM_MAX_LENGTH = 9999999999.99;

  readonly STR_MAX_LENGTH = 255;

  additionalComments = '';

  city = '';

  clientInfo = [] as ClientInfo[];

  contractNumber = '';

  currency = { code: 'USD', description: 'United States Dollar', symbol: '$' } as Currency;

  customFields = [] as customField[];

  dataRequirements = [] as DataRequirement[];

  dataTypes = ['String', 'Numeric', 'Boolean'];

  errorMessage = '' as string;

  isError = false as boolean;

  jobNumber = '';

  macpLevel = '';

  mpsSectionContactsDTO = new MPSSectionContactsDTO();

  otherProjectReason = '';

  poNumber = '';

  projectName = '';

  projectScope = '';

  projectOwner = '';

  inspectionPurpose = '';

  reasonForProject = '';

  organization = null;

  customer = null;

  requiredField = [
    (v: string | boolean | number): string | boolean => !!v || v === 0 || 'This is a required field.',
  ];

  savingEdit = false;

  stateSelected = '';

  selectedTypeModel: {component: unknown, name: string, id: string, index: number} = {
    component: MPSSectionInformation,
    name: 'Information',
    id: 'information',
    index: 0,
  };

  options = [
    {
      component: MPSSectionInformation,
      name: 'Information',
      id: 'information',
      index: 0,
    },
    {
      component: MPSSectionData,
      name: 'Data',
      id: 'data',
      index: 1,
    },
    {
      component: MPSSectionContacts,
      name: 'Contacts',
      id: 'contacts',
      index: 2,
    },
    {
      component: MPSSectionResources,
      name: 'Resources',
      id: 'resources',
      index: 3,
    },
  ]

  get componentProps(): any {
    let componentProps = null;
    try {
      switch (this.selectedTypeModel.name) {
        case 'Information':
          componentProps = {
            ref: 'info',
            canEditMPS: this.hasPermissionEditMps,
            requiredField: this.requiredField,
          };
          break;
        case 'Data':
          componentProps = {
            ref: 'data',
            canEditMPS: this.hasPermissionEditMps,
            requiredField: this.requiredField,
          };
          break;
        case 'Contacts':
          componentProps = {
            ref: 'mpscontacts',
            canEditMPS: this.hasPermissionEditMps,
            requiredField: this.requiredField,
          };
          break;
        case 'Resources':
          componentProps = {
            ref: 'mpsresources',
            canEditMPS: this.hasPermissionEditMps,
            requiredField: this.requiredField,
          };
          break;
        default:
          break;
      }
    } catch (err) {
      return componentProps;
    }

    return componentProps;
  }

  tab = 0;

  tabOptions = [
    'Information',
    'Data',
    'Contacts',
    'Resources',
  ];

  teamMembers = [] as TeamMember[];

  totalFootage = 0.0;

  totalVertical = 0;

  validInfo = false;

  validData = false;

  validContacts = false;

  validResources = true;

  photoRequirementSurfaceCover = false;

  photoRequirementRim = false;

  photoRequirementConnections = false;

  photoRequirementSurroundingAreas = false;

  photoRequirementOtherContent = '';

  photoRequirementOtherCheckBox = false;

  macpSections = [
    {
      header: 'General Information',
      fields: [
        { label: '1. Surveyed By', value: false, levels: [1, 2] },
        { label: '2. Certificate Number', value: false, levels: [1, 2] },
        { label: '3. Reviewed By', value: false, levels: [] },
        { label: '4. Reviewer Certificate No.', value: false, levels: [] },
        { label: '5. Owner', value: false, levels: [] },
        { label: '6. Customer', value: false, levels: [] },
        { label: '7. PO Number', value: false, levels: [] },
        { label: '8. Work Order', value: false, levels: [] },
        { label: '9. Media Label', value: false, levels: [] },
        { label: '10. Project', value: false, levels: [] },
        { label: '11. Date', value: false, levels: [1, 2] },
        { label: '12. Time', value: false, levels: [] },
        { label: '13. Sheet Number', value: false, levels: [1, 2] },
        { label: '14. Weather', value: false, levels: [] },
        { label: '15. Pre-Cleaning', value: false, levels: [2] },
        { label: '16. Date Cleaned', value: false, levels: [] },
        { label: '17. Purpose of Survey', value: false, levels: [1, 2] },
        { label: '18. Inspection Level', value: false, levels: [1, 2] },
        { label: '19. Inspection Status', value: false, levels: [1, 2] },
        { label: '20. Consequence of Failure', value: false, levels: [] },
      ],
    },
    {
      header: 'Location and Measurements',
      fields: [
        { label: '21. Drainage Area', value: false, levels: [] },
        { label: '22. MH/Access Point No.', value: false, levels: [1, 2] },
        { label: '23. Street', value: false, levels: [1, 2] },
        { label: '24. City', value: false, levels: [1, 2] },
        { label: '25. Location Code', value: false, levels: [2] },
        { label: '26. Surface Type', value: false, levels: [2] },
        { label: '27. Inflow Potential from Runoff', value: false, levels: [] },
        { label: '28. Location Details', value: false, levels: [] },
        { label: '29. MH Use', value: false, levels: [2] },
        { label: '30. Access Type', value: false, levels: [1, 2] },
        { label: '31. Year Constructed', value: false, levels: [] },
        { label: '32. Year Renewed', value: false, levels: [] },
        { label: '33. Evidence of Surcharge', value: false, levels: [2] },
        { label: '34. Rim to Invert', value: false, levels: [2] },
        { label: '35. Rim to Grade', value: false, levels: [2] },
        { label: '36. Grade to Invert', value: false, levels: [2] },
        { label: '37. Rim to Grade Exposed', value: false, levels: [] },
        { label: '38. Northing', value: false, levels: [] },
        { label: '39. Easting', value: false, levels: [] },
        { label: '40. Elevation', value: false, levels: [] },
        { label: '41. Coordinate System', value: false, levels: [] },
        { label: '42. Vertical Datum', value: false, levels: [] },
        { label: '43. GPS Accuracy', value: false, levels: [] },
        { label: '44. Additional Information ', value: false, levels: [] },
      ],
    },
    {
      header: 'Cover and Frame',
      fields: [
        { label: '45. Type', value: false, levels: [2] },
        { label: '46. Shape', value: false, levels: [2] },
        { label: '47. Size', value: false, levels: [2] },
        { label: '48. Center Cover Size', value: false, levels: [] },
        { label: '49. Size Width', value: false, levels: [2] },
        { label: '50. Cover Material', value: false, levels: [] },
        { label: '51. Hole Diameter (Vent)', value: false, levels: [2] },
        { label: '52. Hole Number', value: false, levels: [2] },
        { label: '53. Bearing Surface Diameter', value: false, levels: [2] },
        { label: '54. Bearing Surface Width', value: false, levels: [2] },
        { label: '55. Cover/Frame Fit', value: false, levels: [2] },
        { label: '56. Cover Condition', value: false, levels: [1, 2] },
        { label: '57. Insert Type', value: false, levels: [2] },
        { label: '58. Cover Insert Condition', value: false, levels: [2] },
        { label: '59. Ring Type', value: false, levels: [2] },
        { label: '60. Ring Material', value: false, levels: [2] },
        { label: '61. Ring Condition', value: false, levels: [1, 2] },
        { label: '62. Ring Height', value: false, levels: [] },
        { label: '63. Frame Material', value: false, levels: [2] },
        { label: '64. Bearing Surface Width', value: false, levels: [2] },
        { label: '65. Bearing Surface Depth', value: false, levels: [2] },
        { label: '66. Clear Opening Diam.', value: false, levels: [2] },
        { label: '67. Clear Opening Width', value: false, levels: [2] },
        { label: '68. Frame Condition', value: false, levels: [1, 2] },
        { label: '69. Seal Condition', value: false, levels: [2] },
        { label: '70. Offset Distance', value: false, levels: [2] },
        { label: '71. Seal Inflow', value: false, levels: [2] },
        { label: '72. Frame Depth', value: false, levels: [] },
      ],
    },
    {
      header: 'Chimney, Cone, Wall, and Bench',
      fields: [
        { label: '73. Chimney Present', value: false, levels: [2] },
        { label: '75. First Material', value: false, levels: [2] },
        { label: '74. Second Material', value: false, levels: [] },
        { label: '76. Chimney I/I', value: false, levels: [] },
        { label: '77. Clear Opening', value: false, levels: [] },
        { label: '78. Chimney Depth', value: false, levels: [2] },
        { label: '79. Chimney Lining Interior', value: false, levels: [] },
        { label: '80. Chimney Lining Exterior', value: false, levels: [] },
        { label: '81. Chimney Condition', value: false, levels: [1] },
        { label: '82. Cone Type', value: false, levels: [2] },
        { label: '83. Cone Material', value: false, levels: [2] },
        { label: '84. Cone Depth', value: false, levels: [] },
        { label: '85. Cone Lining Interior', value: false, levels: [] },
        { label: '86. Cone Lining Exterior', value: false, levels: [] },
        { label: '87. Cone Condition', value: false, levels: [1] },
        { label: '88. Wall Diameter (Length)', value: false, levels: [] },
        { label: '89. Wall by Size (Width)', value: false, levels: [] },
        { label: '90. Wall Material', value: false, levels: [2] },
        { label: '91. Wall Depth', value: false, levels: [2] },
        { label: '92. Wall Lining Interior', value: false, levels: [] },
        { label: '93. Wall Lining Exterior', value: false, levels: [] },
        { label: '94. Wall Condition', value: false, levels: [1] },
        { label: '95. Bench Present', value: false, levels: [2] },
        { label: '96. Bench Material', value: false, levels: [2] },
        { label: '97. Bench Lining', value: false, levels: [] },
        { label: '98. Bench Condition', value: false, levels: [1] },
        { label: '99. Channel Installed', value: false, levels: [2] },
        { label: '100. Channel Material', value: false, levels: [2] },
        { label: '101. Channel Type', value: false, levels: [2] },
        { label: '102. Channel Exposure', value: false, levels: [2] },
        { label: '103. Channel Condition', value: false, levels: [1] },
        { label: '104. Steps Number', value: false, levels: [2] },
        { label: '105. Steps Material', value: false, levels: [2] },
        {
          label: '106. Additional Component Information',
          value: false,
          levels: [],
        },
      ],
    },
    {
      header: 'Pipe Connections',
      fields: [
        { label: '107. Pipe No.', value: false, levels: [2] },
        { label: '108. Clock Pos.', value: false, levels: [2] },
        { label: '109. Rim to Invert', value: false, levels: [2] },
        { label: '110. Direction', value: false, levels: [2] },
        { label: '111. Material', value: false, levels: [2] },
        { label: '112. Shape', value: false, levels: [2] },
        { label: '113. Height (Diam.)', value: false, levels: [2] },
        { label: '114. Width', value: false, levels: [2] },
        { label: '115. Cond.', value: false, levels: [2] },
        { label: '116. Seal Cond.', value: false, levels: [2] },
        { label: '117. Pipe Type', value: false, levels: [2] },
        { label: '118. Struct. ID', value: false, levels: [] },
        { label: '119. Comment', value: false, levels: [] },
      ],
    },
  ] as MacpSection[];

  setCurrency(item: Currency): void {
    this.currency = item;
  }

  get dataStandardGuid(): string {
    let retVal = EMPTY_GUID;

    if (this.macpLevel === 'Level 1') retVal = DATASTANDARD_MACP7_LEVEL1;
    if (this.macpLevel === 'Level 2') retVal = DATASTANDARD_MACP7_LEVEL2;

    return retVal;
  }

  get isValidTab(): boolean {
    // in edit mode, user should be able to navigate to any tab
    if (this.projectGuid != null) {
      // submit should only be enabled if entire form is valid
      if (this.isFinalTab) return this.validTabs[0];
      return true;
    }
    return this.validTabs[this.tab];
  }

  /**
   * @returns true if the user has the permission PROJECT_SELECTION_VIEW_MPS
   */
  get hasPermissionProjectSelectionViewMps(): boolean {
    return this.hasPermission(UserPermission.PROJECT_SELECTION_VIEW_MPS);
  }

  validTabs: boolean[] = [
    this.validInfo,
    this.validData,
    this.validContacts,
    this.validResources,
  ];

  isSaveMode = false;

  async mounted(): Promise<void> {
    return this.handleMounted();
  }

  async handleMounted(): Promise<void> {
    await this.checkAndSetSaveMode();
    if (!this.hasPermissionProjectSelectionViewMps) {
      this.goToErrorPage();
    }

    if (this.projectGuid != null) {
      this.fetchProjectResource(this.projectGuid);
    }
    this.initMPSSectionContacts();
  }

  async checkAndSetSaveMode(): Promise<void> {
    if (this.projectGuid != null) {
      await this.update(this.projectGuid);
      this.isSaveMode = true;
    }
  }

  get projectGuid(): string {
    return this.projectGuids != null && this.projectGuids.length > 0
      ? this.projectGuids[0] : this.guid;
  }

  get isFinalTab(): boolean {
    return this.tab === this.validTabs.length - 1;
  }

  createMPSObject(): iMPS {
    const mps = {} as iMPS;

    mps.projectName = this.projectName;
    mps.projectGuid = this.projectGuid ? this.projectGuid : uuid.v4();
    mps.poNumber = this.poNumber;
    mps.jobNumber = this.jobNumber;
    mps.contractNumber = this.contractNumber;
    mps.reasonForProject = this.reasonForProject;
    mps.otherProjectReason = this.otherProjectReason;
    mps.jobSummary = this.projectScope;
    mps.projectOwner = this.projectOwner;
    mps.inspectionPurpose = this.inspectionPurpose;

    mps.dataRequirements = JSON.stringify(this.dataRequirements);
    mps.totalFootage = Number(this.totalFootage);
    mps.totalVertical = Number(this.totalVertical);

    mps.projectScope = this.projectScope;
    mps.projectTeam = JSON.stringify(this.teamMembers);
    // mps.additionalComments = this.additionalComments;

    mps.projectClient = JSON.stringify(this.clientInfo);
    mps.city = this.city;
    mps.state = this.stateSelected;

    mps.macpLevel = this.macpLevel;
    mps.dataStandardGuid = this.dataStandardGuid;
    mps.macpSections = JSON.stringify(this.macpSections);
    mps.customFields = this.customFields;

    if (
      this.mpsData
      && this.mpsData.dataStandardGuid
      && this.mpsData.dataStandardGuid !== EMPTY_GUID
    ) {
      mps.dataStandardGuid = this.mpsData.dataStandardGuid;
    }
    mps.photoRequirementSurfaceCover = this.photoRequirementSurfaceCover;
    mps.photoRequirementRim = this.photoRequirementRim;
    mps.photoRequirementConnections = this.photoRequirementConnections;
    mps.photoRequirementSurroundingAreas = this.photoRequirementSurroundingAreas;
    mps.photoRequirementOtherContent = this.photoRequirementOtherContent;

    mps.organizationGuid = this.organization?.guid;
    mps.customerGuid = this.customer?.guid;
    mps.currency = this.currency;

    // As per my usual notes, this can definitely be done better.
    // Still figuring things out.
    return mps;
  }

  isTabDisabled(index: number): boolean {
    if (index === 0 || !this.hasPermissionEditMps || this.projectGuid != null) return false;
    return !this.validTabs.slice(0, index).every((x) => x);
  }

  updateTab(index: number): void {
    this.tab = index;
  }

  async nextTab(): Promise<void> {
    if (this.tab === 0 && !this.isSaveMode) {
      try {
        await this.createSubmitFormPromise().then(async (newProjectGuid: string) => {
          await this.$router.push(`/projectSelection/mps/${newProjectGuid}`);
          // Override router project guid. Does not grab it unless the page refreshes.
          await this.setProjectGuids([newProjectGuid]);
          await this.$nextTick();
          // Change into edit mode
          await this.handleMounted();
        });
      } catch (e) {
        this.handleDuplicateDbProject();
        return;
      }
    }
    this.tab += 1;
    this.selectedTypeModel = this.options[this.tab];
  }

  async initMPSSectionContacts(): Promise<void> {
    if (this.teamMembers.length <= 0) {
      // Find the current user and add them as the pm
      // if the team member list is empty
      // We need to do this on init because we create the project
      // upon clicking next page for the first time
      // We need the project created by the time we hit the resources tab!
      if (
        (this.allDetailedUsers == null || this.allDetailedUsers.length === 0)
        && !this.loadingUsers
      ) {
        await this.fetchAllDetailedUserData();
      } else if (this.loadingUsers) {
        // Wait for loading users to finish
        await new Promise((resolve) => {
          const watcher = this.$watch(() => this.loadingUsers, (newVal) => {
            resolve(newVal);
            watcher();
          });
        });
      }
      if (this.allDetailedUsers != null) {
        // If we have our users, add the current user as a member
        await this.mpsSectionContactsDTO.addUserAsMember(
          this.allDetailedUsers, this.$auth.user, this.teamMembers,
        );
      }
    }
  }

  resetForm(): void {
    window.location.href = '/projectSelection';
  }

  resetValues(): void {
    this.city = '';
    this.contractNumber = '';
    this.customFields = [] as customField[];
    this.dataRequirements = [] as DataRequirement[];
    this.jobNumber = '';
    this.otherProjectReason = '';
    this.poNumber = '';
    this.projectName = '';
    this.projectScope = '';
    this.projectOwner = '';
    this.inspectionPurpose = '';
    this.reasonForProject = '';
    this.stateSelected = '';
    this.clientInfo = [] as ClientInfo[];
    this.teamMembers = [] as TeamMember[];
    this.tab = 0;
    this.totalFootage = 0.0;
    this.totalVertical = 0;
    this.photoRequirementSurfaceCover = false;
    this.photoRequirementRim = false;
    this.photoRequirementConnections = false;
    this.photoRequirementSurroundingAreas = false;
    this.photoRequirementOtherContent = '';
    this.photoRequirementOtherCheckBox = false;
    this.currency = { code: 'USD', description: 'United States Dollar', symbol: '$' } as Currency;
  }

  resetValidation(): void {
    (this.$refs.info as any).resetValidation();
    (this.$refs.data as any).resetValidation();
    (this.$refs.mpscontacts as any).resetValidation();
    (this.$refs.mpsresources as any).resetValidation();
  }

  save(): void {
    this.savingEdit = true;
    this.createSaveFormPromise()
      .then(() => {
        this.resetForm();
        this.savingEdit = false;
      })
      .catch(() => {
        this.handleDuplicateDbProject();
        this.savingEdit = false;
      });
  }

  submitForm(): void {
    // This code is unused now since we submit the project on the first next press
    // I'm keeping this in case the user somehow gets to the end of the form
    // Without a project guid.
    if (this.isError === true) {
      this.displayErrorMessage();
    } else {
      this.createSubmitFormPromise()
        .then(this.resetForm)
        .catch(this.handleDuplicateDbProject);
    }
  }

  private async createSubmitFormPromise(): Promise<string> {
    const mps = this.createMPSObject();
    return this.postMPS({
      mps,
      // Set to false here so the patch can update to true.
      // Set's the active state of the project
      isActive: false,
    } as PostMPSDTO);
  }

  private async createSaveFormPromise(): Promise<void> {
    const mps = this.createMPSObject();
    return this.patchMPS(mps);
  }

  private handleDuplicateDbProject() {
    if (this.mpsLoadError === '409: The project already exists') {
      alert('The project number that you have entered already exists.');
    } else {
      alert(
        'There was a problem with the submitted MPS.  Please make sure all data fields'
          + ' have been entered '
          + 'and a Project Manager has been added in the Project Team section.',
      );
    }
  }

  displayErrorMessage(): void {
    alert(this.errorMessage);
    this.isError = false;
    this.errorMessage = '';
  }

  async update(guid: string): Promise<void> {
    if (guid === null) {
      try {
        (this.$refs.mpscontacts as any).addUserAsMember();
      } catch (ex) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const silence = ex;
      }
      return;
    }

    await this.fetchMPSData(guid)
      .then(() => {
        this.setProjectGuids([guid]);
        this.additionalComments = this.mpsData.additionalComments;
        this.city = this.mpsData.city;
        this.dataRequirements = JSON.parse(this.mpsData.dataRequirements);
        this.jobNumber = this.mpsData.jobNumber;
        this.projectScope = this.mpsData.jobSummary;
        this.clientInfo = JSON.parse(this.mpsData.projectClient);
        this.projectName = this.mpsData.projectName;
        this.projectScope = this.mpsData.projectScope;
        this.projectOwner = this.mpsData.projectOwner;
        this.inspectionPurpose = this.mpsData.inspectionPurpose;
        this.teamMembers = JSON.parse(this.mpsData.projectTeam);
        this.stateSelected = this.mpsData.state;
        this.totalFootage = Number(this.mpsData.totalFootage);
        this.customFields = this.mpsData.customFields ?? [];
        this.reasonForProject = this.mpsData.reasonForProject;
        this.otherProjectReason = this.mpsData.otherProjectReason;
        this.contractNumber = this.mpsData.contractNumber;
        this.poNumber = this.mpsData.poNumber;
        this.totalVertical = this.mpsData.totalVertical;
        this.macpLevel = this.mpsData.macpLevel;
        this.photoRequirementSurfaceCover = this.mpsData.photoRequirementSurfaceCover;
        this.photoRequirementRim = this.mpsData.photoRequirementRim;
        this.photoRequirementConnections = this.mpsData.photoRequirementConnections;
        this.photoRequirementSurroundingAreas = this.mpsData.photoRequirementSurroundingAreas;
        this.photoRequirementOtherContent = this.mpsData.photoRequirementOtherContent;
        if (
          this.photoRequirementOtherContent !== ''
          && this.photoRequirementOtherContent != null
        ) {
          this.photoRequirementOtherCheckBox = true;
        }
        if (this.mpsData.macpSections) {
          this.macpSections = JSON.parse(this.mpsData.macpSections);
        }
        if (this.mpsData.organizationGuid) {
          this.fetchOrganizationData().then(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            (_: any) => {
              this.organization = this.organizationDataList
                .find((org) => org.guid === this.mpsData.organizationGuid) ?? null;
            },
          );
        }
        if (this.mpsData.customerGuid) {
          this.fetchCustomerData().then(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            (_: any) => {
              this.customer = this.customerDataList
                .find((cust) => cust.guid === this.mpsData.customerGuid) ?? null;
            },
          );
        }
        if (this.mpsData.currency) {
          this.currency = this.mpsData.currency;
        }
      })
      .catch(() => {
        alert('There was an error opening this MPS project');
        this.$emit('emitClose');
      });
  }

  /**
   * @returns true if the user has the permission PROJECT_SELECTION_EDITMPS
   */
  get hasPermissionEditMps(): boolean {
    return this.hasPermission(UserPermission.PROJECT_SELECTION_EDITMPS);
  }
}
