/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutationTree } from 'vuex';
import { ProjectResource, ResourceState } from './types';
// eslint-disable-next-line no-shadow
export enum ResourceMutations {
  SET_FETCH_LOADING_STATE = 'SET_FETCH_LOADING_STATE',
  SET_PATCH_LOADING_STATE = 'SET_PATCH_LOADING_STATE',
  SET_POST_LOADING_STATE = 'SET_POST_LOADING_STATE',
  SET_DELETE_LOADING_STATE = 'SET_DELETE_LOADING_STATE',
  SET_FETCH_RESOURCE_ERROR = 'SET_FETCH_RESOURCE_ERROR',
  SET_PATCH_RESOURCE_ERROR = 'SET_PATCH_RESOURCE_ERROR',
  SET_POST_RESOURCE_ERROR = 'SET_POST_RESOURCE_ERROR',
  SET_DELETE_RESOURCE_ERROR = 'SET_DELETE_RESOURCE_ERROR',
  SET_PROJECT_RESOURCE = 'SET_PROJECT_RESOURCE',
  SET_PATCH_PROJECT_RESOURCE = 'SET_PATCH_PROJECT_RESOURCE',
  SET_POST_PROJECT_RESOURCE = 'SET_POST_PROJECT_RESOURCE',
}

export const mutations: MutationTree<ResourceState> = {
  [ResourceMutations.SET_FETCH_LOADING_STATE](state, payload: boolean) {
    state.resourceLoading = payload;
  },
  [ResourceMutations.SET_PATCH_LOADING_STATE](state, payload: boolean) {
    state.patchResourceLoading = payload;
  },
  [ResourceMutations.SET_POST_LOADING_STATE](state, payload: boolean) {
    state.postResourceLoading = payload;
  },
  [ResourceMutations.SET_DELETE_LOADING_STATE](state, payload: boolean) {
    state.deleteResourceLoading = payload;
  },
  [ResourceMutations.SET_FETCH_RESOURCE_ERROR](state, payload: string) {
    state.fetchResourceError = payload;
  },
  [ResourceMutations.SET_PATCH_RESOURCE_ERROR](state, payload: string) {
    state.patchResourceError = payload;
  },
  [ResourceMutations.SET_POST_RESOURCE_ERROR](state, payload: string) {
    state.postResourceError = payload;
  },
  [ResourceMutations.SET_DELETE_RESOURCE_ERROR](state, payload: string) {
    state.deleteResourceError = payload;
  },
  [ResourceMutations.SET_PROJECT_RESOURCE](state, payload: ProjectResource) {
    state.projectResource = payload;
  },
  [ResourceMutations.SET_PATCH_PROJECT_RESOURCE](state, payload: ProjectResource) {
    state.patchProjectResource = payload;
  },
  [ResourceMutations.SET_POST_PROJECT_RESOURCE](state, payload: ProjectResource) {
    state.postProjectResource = payload;
  },
};
