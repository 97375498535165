



















































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { InspectionData } from '@/store/asset/types';
import {
  Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DetailedUserData } from '@/store/users/types';
import { GenericDropDownData } from '@/common/types';
import CheckBoxGroup from './CheckBoxGroup/CheckBoxGroup.vue';
import { CodingFormData, FormPageData, ValidationError } from '../types';
import CodingTableForm from '../CodingTableForm.vue';
import radioGroup from './RadioGroup/RadioGroup.vue';

const userModule = namespace('users');

@Component({
  components: {
    CheckBoxGroup,
    CodingTableForm,
    radioGroup,
  },
})
export default class FormPage extends Vue {
  @userModule.State('allDetailedUserData') allUsers: DetailedUserData[];

  @Prop() currentFormPageData: FormPageData[];

  @Prop() fullCodingForm: CodingFormData[];

  @Prop() checkBoxHeaderNumbers: number[];

  @Prop() errors: ValidationError[];

  @Prop() dataStandardSchema: any;

  @Prop() dataStandardJson: any;

  @Prop({ default: 'Yes' })trueLabelOverride: string;

  @Prop({ default: 'No' })falseLabelOverride: string;

  @Prop({ default: [] }) readOnlyFields: string[];

  @Prop({ default: false }) readonly: boolean;

  @Prop() readonly canEdit: boolean;

  @PropSync('syncedInspection') inspection!: InspectionData;

  indexBools = Array(500).fill(false);

  tempStrings = Array(500).fill('');

  tempNumbers = Array(500).fill(null);

  checkBoxHeaderData = [];

  simpleFormPageData = [];

  // Formatted HH:MM
  standardTimeRegex = /^\d{2}:\d{2}$/;

  // Formatted ...THH:MM...
  // Matches on solo deployment time, ex: 2024-12-13T11:05:16
  soloTimeRegex = /(?<=T)\d{2}:\d{2}/;

  mounted(): void {
    this.onFormPageDataChange();
    this.switchFocus();
  }

  @Watch('currentFormPageData')
  onFormPageDataChange(): void {
  // this.checkBoxHeaderData = this.currentFormPageData.filter(
  //   (cf) => this.checkBoxHeaderNumbers.includes(cf.headernumber),
  // );
    const currentNumbers = this.currentFormPageData.map((cf) => cf.headernumber);
    this.checkBoxHeaderData = this.checkBoxHeaderNumbers.filter(
      (hn) => currentNumbers.includes(hn),
    );
    this.checkBoxHeaderData = [...new Set(this.checkBoxHeaderData)];
    this.simpleFormPageData = this.currentFormPageData.reduce((s, c) => {
      if (!s.find((item) => item.InputType !== 'Section' && item.headernumber === c.headernumber)) {
        s.push(c);
      }
      return s;
    }, []);
    this.prepopulateData();
  }

  @Watch('currentFormPageData')
  switchFocus(): void {
    setTimeout(() => {
      const focusRef = this.$refs.focus ? this.$refs.focus[0] as HTMLElement : undefined;
      if (focusRef !== undefined) {
        focusRef.focus();
      }
    }, 100);
  }

  getEnumValueKey(items: string[] | GenericDropDownData[]): string | undefined {
    return items != null
      && items.length > 0
      && (items[0] as unknown as GenericDropDownData)?.guid != null
      ? 'guid'
      : undefined;
  }

  setFocus(fpd: FormPageData, form: FormPageData[]): string {
    let refVal = '';
    if ((fpd.Order === 1 && fpd.InputType !== 'Checkbox') || (fpd.Order === 2 && form[0].InputType === 'Checkbox')) {
      refVal = 'focus';
    }
    return refVal;
  }

  getErr(fpd: FormPageData): ValidationError {
    let retVal = null;
    retVal = this.errors.find((e: ValidationError) => e.instancePath.split('/')[1] === fpd.headername);
    if (retVal == null) {
      retVal = this.errors.find((e) => e.message.includes(fpd.headername) === true);
    }
    return retVal;
  }

  getErrorMsg(fpd: FormPageData): string {
    const err = this.getErr(fpd);
    if (err.message.includes('must match pattern')) {
      err.message = this.regexErrCheck(err.message);
    }
    return `${fpd.description} ${err.message}`;
  }

  /**
   * @description Looks to see if the header is in the read only list
   * @returns true if the header is in the read only list
   * uses description for Assigned To dropdown
   */
  getIsReadOnlyHeader(headerName: string): boolean {
    return this.readOnlyFields.indexOf(headerName) !== -1;
  }

  checkError(fpd: FormPageData): boolean[] {
    const err = this.getErr(fpd);
    return [err === undefined];
  }

  checkValidation(): void {
    this.$emit('validateForm');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  stringToNumber(event: any, formData: FormPageData, tempNumbersIndex: number): void {
    const { headername } = formData;
    const { type } = formData;
    const item = this.fullCodingForm.find((f) => f.header === headername);
    // eslint-disable-next-line no-param-reassign
    item.value = event;
    if (item.value !== null) {
      if ((item.value as string).length === 0 || Number.isNaN(item.value)) {
      // eslint-disable-next-line no-param-reassign
        item.value = null;
      } else if (type === 'integer') {
      // eslint-disable-next-line no-param-reassign
        item.value = parseInt((item.value as string), 10);
      } else if (type === 'number') {
      // eslint-disable-next-line no-param-reassign
        item.value = parseFloat((item.value as string));
      }
    }
    if (item.value === null) {
      this.tempNumbers[tempNumbersIndex] = null;
    } else {
      this.tempNumbers[tempNumbersIndex] = item.value;
    }
    this.checkValidation();
  }

  formatDate(item: CodingFormData, i: number): void {
    this.indexBools[i] = false;
    if (item.value != null) {
      const date = new Date(item.value as string);
      // eslint-disable-next-line no-param-reassign
      item.value = date.toISOString();

      const splitStrings = (item.value as string).split('T');
      const hourMinute = this.tempStrings[i] ? this.tempStrings[i] : '00:00';

      const offset = date.getTimezoneOffset();
      const offsetHours = `${Math.floor(offset / 60)}`.padStart(2, '0');
      const offsetMins = `${Math.abs(offset % 60)}`.padStart(2, '0');
      const positive = parseInt(offsetHours, 10) > 0 ? '+' : '';
      // eslint-disable-next-line no-param-reassign
      item.value = `${splitStrings[0]}T${hourMinute}:00${positive}${offsetHours}:${offsetMins}`;
    }
    this.checkValidation();
  }

  formatTime(item: CodingFormData, event: string): void {
    let time: string = item.value as string;
    // standard time matches event format, no extra work needed
    if (this.standardTimeRegex.test(time)) {
      time = event;
    } else
    // solo formatted time will need to match and replace time value
    if (this.soloTimeRegex.test(time)) {
      time = time.replace(this.soloTimeRegex, event);
    } else {
      // No time or bad format, just use event
      time = event;
    }
    // eslint-disable-next-line no-param-reassign
    item.value = time;
    this.checkValidation();
  }

  getTime(headername: string): string {
    const item = this.fullCodingForm.find((f) => f.header === headername);
    if (item.value == null) {
      return '';
    }
    // Formatted HH:MM
    let timeRegex = this.standardTimeRegex;

    const itemValue = item.value as string;
    if (timeRegex.test(itemValue)) {
      return itemValue;
    }
    // Formatted ...THH:MM...
    // Matches on solo deployment time, ex: 2024-12-13T11:05:16
    timeRegex = this.soloTimeRegex;
    if (timeRegex.test(itemValue)) {
      return timeRegex.exec(itemValue)[0];
    }
    return '';
  }

  isError(item: FormPageData): boolean {
    let retVal = false;
    // check for standard error
    retVal = this.errors.find((e) => item.headername === e.instancePath.split('/')[1]) !== undefined;
    if (retVal === true) return retVal;
    // check for sub style error
    retVal = this.errors.find((e) => e.message.includes(item.headername) === true) !== undefined;
    return retVal;
  }

  regexErrCheck(err: string): string {
    if (err.includes('[A-Z]{1}[.] [A-Z][A-z]*')) {
      return 'entry must be First Initial. Last Name';
    }
    if (err.includes('^U[0-9]*$')) {
      return 'entry must be NASSCO ID without hyphens';
    }
    if (err.includes('[0-9]{4}-[0-9]{2}-[0-9]{2}T')) {
      return 'entry must be properly formatted. Please re-select date';
    }
    return err;
  }

  getDate(headername: string): string {
    if (this.fullCodingForm.find((f) => f.header === headername).value == null) {
      return '';
    }
    const split = (
      this.fullCodingForm.find((f) => f.header === headername).value as string).split('/');
    if (split.length <= 1) {
      return (this.fullCodingForm.find(
        (f) => f.header === headername,
      ).value as string).substring(0, 10);
    }
    const year = split[2].split(' ')[0];
    let month = split[0];
    if (month.length === 1) month = `0${month}`;
    const day = split[1];
    this.fullCodingForm.find((f) => f.header === headername).value = `${year}-${month}-${day}`;
    return this.fullCodingForm.find((f) => f.header === headername).value as string;
  }

  getDateTime(headername: string): string {
    if (this.fullCodingForm.find((f) => f.header === headername).value == null) {
      return '';
    }
    const split = (
      this.fullCodingForm.find((f) => f.header === headername).value as string).split('/');
    if (split.length <= 1) {
      const dateStr = (
        this.fullCodingForm.find((f) => f.header === headername).value as string).substring(0, 10);
      const timeStr = (
        this.fullCodingForm.find((f) => f.header === headername).value as string).substring(11, 16);
      return dateStr.concat(' ', timeStr);
    }
    const year = split[2].split(' ')[0];
    let month = split[0];
    if (month.length === 1) month = `0${month}`;
    const day = split[1];
    this.fullCodingForm.find((f) => f.header === headername).value = `${year}-${month}-${day}`;
    return this.fullCodingForm.find((f) => f.header === headername).value as string;
  }

  textChange(): void {
    this.checkValidation();
  }

  checkHeaderNumber(headernumber: number): boolean {
    const headerData = this.checkBoxHeaderData.find((hd) => hd.num === headernumber);
    if (headerData !== undefined && headerData.displayed === false) {
      headerData.displayed = true;
      return true;
    }
    return false;
  }

  checkBoxChange(): void {
    this.checkValidation();
  }

  clearDate(headername: string): void {
    if (this.fullCodingForm) {
      const currDate = this.fullCodingForm.find((fc) => fc.header === headername);
      currDate.value = null;
      this.checkValidation();
    }
  }

  dropdownChange(headerName: string): void {
    if (this.fullCodingForm) {
      const currentDropdown = this.fullCodingForm.find((fc) => fc.header === headerName);

      if (headerName === 'SewerUse' && this.inspection) {
        this.inspection.initialPipeUse = currentDropdown.value as string;
      }
      this.checkValidation();
    }
  }

  getRequiredClass(item: FormPageData): string {
    let retVal = '';

    if (item.required != null && item.required) retVal = 'required-field';

    else if (this.errors.find((e) => e.instancePath === `/${item.headername}`)) retVal = 'required-field';

    return retVal;
  }

  getBackgroundColor(item: FormPageData): string {
    return item.prefilled === false ? 'white' : '#f0f0f0';
  }

  getColor(item: FormPageData): string {
    return (item.required !== null && item.required === true)
    || (this.errors.find((e) => e.instancePath === `/${item.headername}`)) ? '#e61e25' : '#0c6599';
  }

  prepopulateData(): void {
    if (this.fullCodingForm) {
      const surveyedBy = this.fullCodingForm.find((form) => form.header === 'SurveyedBy');
      const certNum = this.fullCodingForm.find((form) => form.header === 'CertificateNumber');

      if (surveyedBy && surveyedBy.value === '') {
        surveyedBy.value = this.$auth.user.name;
      }

      if (certNum && certNum.value === '') {
        const user = this.allUsers?.find(
          (u) => u.useridentity && u.useridentity.includes(this.$auth.user.id),
        );
        certNum.value = user?.nasscoid;
      }
    }
  }

  getHint(item: FormPageData): string {
    return item.hint;
  }

  hintExists(item: FormPageData): boolean {
    return item.hint != null && item.hint !== '';
  }

  fieldClass(item: FormPageData): string {
    if (item.InputType === 'Array') {
      return 'array-field';
    }
    return this.checkBoxHeaderNumbers.includes(item.headernumber) ? 'checkbox-field' : 'field';
  }

  tableFullCodingForm(item: FormPageData): any {
    if (this.fullCodingForm) {
      return this.fullCodingForm.find((fc) => fc.header === item.headername);
    }
    return 0;
  }

  tableSchema(item: FormPageData): any {
    if (!this.dataStandardSchema) {
      return {};
    }
    return this.dataStandardSchema.properties[item.headername].items;
  }

  radioChange(): void {
    this.checkValidation();
  }
}
