
























































































import {
  Component, Prop, VModel, Watch,
} from 'vue-property-decorator';
import { DetailedUserData } from '@/store/users/types';
import { User } from '@/store/admin/types';
import { namespace } from 'vuex-class';
import { SnackBarActions } from '@/store/integritySnackBar/actions';
import { UserAlreadyExists } from '@/auth/auth0Types';
import AdminPanelMixin from './AdminPanelMixin.vue';
import { FilterFunction } from '../IntegrityTable/IntegrityTable.vue';

const integritySnackBar = namespace('integritySnackBar');

@Component({})
export default class NewUserPopup extends AdminPanelMixin {
  @VModel() modelValue!: DetailedUserData | undefined;

  @Prop({ default: 'System Manager' }) readonly label: string;

  @Prop() readonly userRole: string;

  @Prop({ default: [] }) readonly filterFunctions: FilterFunction[];

  @integritySnackBar.Action(SnackBarActions.SHOW) showSnackBar;

  showNewUserDialog = false;

  systemManager = '';

  firstName = '';

  lastName = '';

  email = '';

  saveLoading = false;

  systemManagerSearch = null;

  valid = false as boolean;

  mounted(): void {
    if (this.modelValue) {
      this.setLocalValues();
    }
  }

  @Watch('modelValue')
  onModelValueChange(): void {
    this.setLocalValues();
  }

  /**
   * @description Sets all local values from the modelValue.
   */
  setLocalValues(): void {
    this.firstName = this.modelValue?.firstname ?? '';
    this.lastName = this.modelValue?.lastname ?? '';
    const searchVal = this.modelValue ? `${this.firstName} ${this.lastName}` : '';
    this.systemManager = searchVal;
    this.systemManagerSearch = searchVal;
  }

  @Watch('showNewUserDialog')
  onShowNewUserDialogChange(): void {
    if (!this.showNewUserDialog) {
      // If closing the dialog, then reset the search to the current modelValue
      this.setLocalValues();
    }
  }

  /**
   * @description Checks for a new user in the combobox
   * @remarks If an existing user is selected, then select them. If not, then open up the popup
   */
  checkforNewUserAdd(): void {
    if (!this.systemManager) {
      return;
    }
    const splitName = this.systemManager.split(' ');

    // eslint-disable-next-line prefer-destructuring
    this.firstName = splitName[0];
    // eslint-disable-next-line prefer-destructuring
    this.lastName = splitName.length > 1 ? splitName[1] : '';

    const foundValue = this.allUsers.find(
      (u) => u.firstname === this.firstName && u.lastname === this.lastName,
    );

    if (!foundValue) {
      this.showNewUserDialog = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.systemManagerComboBox as any).isFocused = false;
    } else {
      this.modelValue = foundValue;
    }
  }

  /**
   * @description Save user to auth0 and db with a system Manager or system admin role.
   */
  async saveUser(): Promise<void> {
    this.saveLoading = true;
    const user = {} as User;
    user.Firstname = this.firstName;
    user.Lastname = this.lastName;
    user.Email = this.email;
    user.Nickname = `${this.firstName} ${this.lastName}`;
    user.Role = this.userRole;

    let auth0User;
    try {
      auth0User = await this.$auth.createUser(user);
      await this.postUserData(auth0User);
      await this.fetchAllDetailedUserData();
    } catch (e: unknown) {
      let message = 'An issue occurred while creating the user';
      if (e instanceof UserAlreadyExists) {
        message = 'The user already exists in our records';
      }
      this.showSnackBar({
        message,
        color: 'red',
        showSnackBar: true,
      });
      this.saveLoading = false;
      throw e;
    }

    this.modelValue = this.allUsers.find(
      (u) => u.firstname === this.firstName && u.lastname === this.lastName,
    );
    this.updateAllManagedUsers();
    this.showSnackBar({
      message: 'User created successfully',
      color: 'green',
      showSnackBar: true,
    });

    this.showNewUserDialog = false;
    this.saveLoading = false;
  }

  /**
   * @description Get a list of all user's full names.
   * @returns {string[]} list of user full names
   */
  get usersFullNames(): string[] {
    if (!this.allUsers) {
      return [];
    }
    let returnValue = [...this.allUsers];
    this.filterFunctions.forEach((func) => {
      returnValue = returnValue
        .filter((item) => func.filterFunction(item, ...func.functionVariables));
    });
    return returnValue
      .map((item) => `${item.firstname} ${item.lastname}`)
      .sort();
  }
}
