import { ActionTree } from 'vuex';
import { RootState } from '../types';
import storeUtility from '../utils';
import { DefectCodingMutations } from './mutations';
import { DefectCodingPostModel, DefectCodingState } from './types';

// eslint-disable-next-line no-shadow
export enum DefectCodingAction {
    GET_DEFECT_CODING_DATA = 'GET_DEFECT_CODING_DATA',
    RESET_DEFECT_CODING_POST_ERROR = 'RESET_DEFECT_CODING_POST_ERROR',
    POST_DEFECT_CODING_DATA = 'POST_DEFECT_CODING_DATA',
    DELETE_DEFECT_DATA = 'DELETE_DEFECT_DATA',
    PATCH_DEFECT_DATA = 'PATCH_DEFECT_DATA',
    VALIDATE_AND_SCORE = 'VALIDATE_AND_SCORE',
    CLEAR_VALIDATE_AND_SCORE = 'CLEAR_VALIDATE_AND_SCORE',
    SET_CONFIRM_SCORE_RESET = 'SET_CONFIRM_SCORE_RESET',
  }

export const actions: ActionTree<DefectCodingState, RootState> = {
  async [DefectCodingAction.GET_DEFECT_CODING_DATA]({ commit }, payload: string): Promise<void> {
    commit(DefectCodingMutations.SET_DEFECT_CODING_LOADING_STATE, true);
    commit(DefectCodingMutations.SET_DEFECT_CODING_DATA, undefined);
    commit(DefectCodingMutations.SET_DEFECT_CODING_LOAD_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getDefectCodingList(payload);
      commit(DefectCodingMutations.SET_DEFECT_CODING_DATA, response.data);
    } catch (e) {
      commit(DefectCodingMutations.SET_DEFECT_CODING_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(DefectCodingMutations.SET_DEFECT_CODING_LOADING_STATE, false);
    }
  },
  async [DefectCodingAction.RESET_DEFECT_CODING_POST_ERROR]({ commit }): Promise<void> {
    commit(DefectCodingMutations.SET_DEFECT_CODING_POST_LOADING, false);
    commit(DefectCodingMutations.SET_DEFECT_CODING_POST_ERROR, undefined);
  },
  async [DefectCodingAction.POST_DEFECT_CODING_DATA](
    { commit }, payload: {inspectionGuid: string, DCPM: DefectCodingPostModel},
  ): Promise<void> {
    commit(DefectCodingMutations.SET_DEFECT_CODING_POST_LOADING, true);
    commit(DefectCodingMutations.SET_DEFECT_CODING_POST_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.postDefectCodingData(payload.inspectionGuid, payload.DCPM);
    } catch (e) {
      commit(DefectCodingMutations.SET_DEFECT_CODING_POST_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(DefectCodingMutations.SET_DEFECT_CODING_POST_LOADING, false);
    }
  },
  async [DefectCodingAction.DELETE_DEFECT_DATA](
    { commit }, payload: {inspectionGuid: string, defectGuids: string[]},
  ): Promise<void> {
    commit(DefectCodingMutations.SET_DEFECT_DELETE_LOADING, true);
    commit(DefectCodingMutations.SET_DEFECT_DELETE_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.deleteDefectData(payload.inspectionGuid, payload.defectGuids);
    } catch (e) {
      commit(DefectCodingMutations.SET_DEFECT_DELETE_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(DefectCodingMutations.SET_DEFECT_DELETE_LOADING, false);
    }
  },
  async [DefectCodingAction.PATCH_DEFECT_DATA](
    { commit }, payload: {inspectionGuid: string, defectGuid: string,
        defect: DefectCodingPostModel},
  ): Promise<void> {
    commit(DefectCodingMutations.SET_DEFECT_PATCH_LOADING, true);
    commit(DefectCodingMutations.SET_DEFECT_PATCH_ERROR, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.patchDefectData(payload.inspectionGuid, payload.defectGuid, payload.defect);
    } catch (e) {
      commit(DefectCodingMutations.SET_DEFECT_PATCH_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(DefectCodingMutations.SET_DEFECT_PATCH_LOADING, false);
    }
  },
  async [DefectCodingAction.VALIDATE_AND_SCORE](
    { commit }, payload: string,
  ): Promise<void> {
    commit(DefectCodingMutations.SET_VALIDATE_AND_SCORE_LOADING, true);
    commit(DefectCodingMutations.SET_VALIDATE_AND_SCORE_DATA, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.validateAndScore(payload);
      if (storeUtility.isNasscoValidationWarning(response)) {
        commit(DefectCodingMutations.SET_VALIDATE_AND_SCORE_DATA,
          {
            isError: false,
            isValidationError: true,
            message: response.data?.data?.message,
          });
      } else {
        commit(DefectCodingMutations.SET_VALIDATE_AND_SCORE_DATA,
          {
            isError: false,
            isValidationError: false,
            message: 'Validation and Scoring Successful!',
          });
      }
    } catch (e) {
      commit(DefectCodingMutations.SET_VALIDATE_AND_SCORE_DATA,
        {
          isError: true,
          isValidationError: false,
          message: (e as Error)?.message,
        });
      throw new Error('API Error occurred.');
    } finally {
      commit(DefectCodingMutations.SET_VALIDATE_AND_SCORE_LOADING, false);
    }
  },
  async [DefectCodingAction.CLEAR_VALIDATE_AND_SCORE](
    { commit },
  ): Promise<void> {
    commit(DefectCodingMutations.SET_VALIDATE_AND_SCORE_LOADING, false);
    commit(DefectCodingMutations.SET_VALIDATE_AND_SCORE_DATA, undefined);
  },
  [DefectCodingAction.SET_CONFIRM_SCORE_RESET](
    { commit }, value: boolean,
  ): void {
    commit(DefectCodingMutations.SET_CONFIRM_SCORE_RESET, value);
  },
};
