/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { ProcessingObject, ProcessingState } from './types';
import storeUtility from '../utils';
import { ProcessingMutations } from './mutations';

// eslint-disable-next-line no-shadow
export enum ProcessingActions {
  FETCH_PROCESSINGS = 'FETCH_PROCESSINGS',
  PATCH_PROCESSINGS = 'PATCH_PROCESSINGS',
  FETCH_SOLO_PROCESSING_VERSION = 'FETCH_SOLO_PROCESSING_VERSION',
}

export const actions: ActionTree<ProcessingState, RootState> = {

  async [ProcessingActions.FETCH_PROCESSINGS]({ commit },
    projectGuid: string):
    Promise<void> {
    commit(ProcessingMutations.SET_PATCH_PROCESSING_LOADING, true);
    commit(ProcessingMutations.SET_GET_PROCESSING_ERROR, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getProcessings(projectGuid);
      commit(ProcessingMutations.SET_PROCESSINGS, response.data.data);
    } catch (e) {
      const message = (e as Error).message != null ? (e as Error).message : 'There was an error saving the edited Processings';
      commit(ProcessingMutations.SET_GET_PROCESSING_ERROR, message);
      throw Error('API Error occurred.');
    } finally {
      commit(ProcessingMutations.SET_GET_PROCESSING_LOADING, false);
    }
  },

  async [ProcessingActions.PATCH_PROCESSINGS]({ commit },
    processings: ProcessingObject[]):
    Promise<void> {
    commit(ProcessingMutations.SET_PATCH_PROCESSING_LOADING, true);
    commit(ProcessingMutations.SET_PATCH_PROCESSING_ERROR, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.patchProcessings(processings);
    } catch (e) {
      const message = (e as Error).message != null ? (e as Error).message : 'There was an error saving the edited Processings';
      commit(ProcessingMutations.SET_PATCH_PROCESSING_ERROR, message);
      throw Error('API Error occurred.');
    } finally {
      commit(ProcessingMutations.SET_PATCH_PROCESSING_LOADING, false);
    }
  },

  async [ProcessingActions.FETCH_SOLO_PROCESSING_VERSION]({ commit },
    projectGuid: string):
    Promise<void> {
    commit(ProcessingMutations.SET_SOLO_PROCESSING_VERSION_LOADING, true);
    commit(ProcessingMutations.SET_SOLO_PROCESSING_VERSION_ERROR, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getSoloProcessingVersion();
      commit(ProcessingMutations.SET_SOLO_PROCESSING_VERSION, response.data.data);
    } catch (e) {
      const message = (e as Error).message != null ? (e as Error).message : 'There was an error retrieving the solo processing version';
      commit(ProcessingMutations.SET_SOLO_PROCESSING_VERSION_ERROR, message);
      throw Error('API Error occurred.');
    } finally {
      commit(ProcessingMutations.SET_SOLO_PROCESSING_VERSION_LOADING, false);
    }
  },

};
