import IntegrityApi from '@/services/integrityClient';
import axios, { AxiosResponse } from 'axios';

async function useIntegrityAPI(): Promise<IntegrityApi> {
  if (typeof fetch === 'function') {
    try {
      const test = await fetch('/config.json');
      const text = await test.text();
      const { VUE_APP_INTEGRITY_API_URL, VUE_APP_INTEGRITY_API_VERSION } = JSON.parse(text);
      return new IntegrityApi(VUE_APP_INTEGRITY_API_URL + VUE_APP_INTEGRITY_API_VERSION);
    } catch (e) {
      console.warn(e);
    }
  }
  return new IntegrityApi();
}

function isNasscoValidationWarning(okResponse: AxiosResponse): boolean {
  const status = okResponse?.status;
  const type: string = okResponse?.data?.responseType;
  return status && status === 200 && type && type.includes('NasscoValidationWarning');
}

function isDbEntryNotFoundError(error: unknown): boolean {
  if (!axios.isAxiosError(error)) {
    return false;
  }
  const status = error?.response?.status;
  const errorType = error?.response?.data?.errorType;
  return status && status === 404 && errorType && errorType === 'DbEntryNotFound';
}

function isDuplicateDbEntry(error: unknown): boolean {
  if (!axios.isAxiosError(error)) {
    return false;
  }
  const status = error?.response?.status;
  const errorType = error?.response?.data?.errorType;
  return status && status === 409 && errorType && errorType === 'DuplicateDbEntry';
}

function isPartialProcessingError(error: unknown): boolean {
  if (!axios.isAxiosError(error)) {
    return false;
  }
  const status = error?.response?.status;
  const errorType = error?.response?.data?.errorType;
  return status && status === 404 && errorType && errorType === 'PartialProcessingError';
}

export default {
  useIntegrityAPI,
  isDbEntryNotFoundError,
  isDuplicateDbEntry,
  isPartialProcessingError,
  isNasscoValidationWarning,
};
