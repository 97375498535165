
import { TASK_RESULT_DETAILS } from '@/common/Constants';
import { InspectionRef } from '@/store/asset/types';
import { DeploymentsActions } from '@/store/deployments/actions';
import { DeploymentsMutations } from '@/store/deployments/mutations';
import { DeploymentCount, DeploymentData } from '@/store/deployments/types';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import { namespace } from 'vuex-class';

const deploymentsModule = namespace('deployments');

export default class DeploymentsMixin extends UserPermissionsMixin {
  @deploymentsModule.State('data') deploymentsData: DeploymentData[];

  @deploymentsModule.State('deploymentCounts') deploymentCounts: DeploymentCount[];

  @deploymentsModule.State('loading') loadingData: boolean;

  @deploymentsModule.State('countsLoading') countsLoading: boolean;

  @deploymentsModule.State('patchLoading') patchLoading: boolean;

  @deploymentsModule.Action(DeploymentsActions.FETCH_DEPLOYMENTS_DATA)
  fetchDeploymentsData;

  @deploymentsModule.Action(DeploymentsActions.FETCH_DEPLOYMENT_COUNTS)
  fetchDeploymentCounts;

  @deploymentsModule.Action(DeploymentsActions.PATCH_DEPLOYMENT_DATA)
  patchDeploymentData;

  @deploymentsModule.Action(DeploymentsActions.EXPORT_DEPLOYMENT_CSV)
  exportDeploymentCsv;

  @deploymentsModule.Mutation(DeploymentsMutations.SET_DEPLOYMENTS_DATA) setDeploymentsData;

  assets: InspectionRef[] = [];

  get yesNoOption(): { name: string }[] {
    return [
      {
        name: 'Yes',
      },
      {
        name: 'No',
      },
    ];
  }

  get directionOptions(): { name: string }[] {
    return [
      {
        name: 'Upstream',
      },
      {
        name: 'Downstream',
      },
    ];
  }

  get taskResultOptions(): { name: string }[] {
    return TASK_RESULT_DETAILS.map((trd) => ({
      name: trd.description,
    }));
  }

  getEntryMHOptions(item: DeploymentData): string[] {
    return [item.usmh, item.dsmh];
  }

  // #region default headers
  defaultHeaders = [
    {
      text: 'Asset',
      value: 'name',
      sortable: true,
      filterable: true,
      class: 'sticky',
      cellClass: 'sticky',
      editable: true,
    },
    {
      text: 'USMH', value: 'usmh', sortable: true, filterable: true,
    },
    {
      text: 'DSMH', value: 'dsmh', sortable: true, filterable: true,
    },
    {
      text: 'EntryMH', value: 'entryMH', sortable: true, filterable: true, editable: true, optionFunction: this.getEntryMHOptions,
    },
    {
      text: 'Date Inspected', value: 'date', sortable: true, filterable: true,
    },
    {
      text: 'Due Date', value: 'dueDate', sortable: true, filterable: true,
    },
    {
      text: 'Delivery Status', value: 'deliveryStatus', sortable: true, filterable: true,
    },
    {
      text: 'Direction', value: 'direction', sortable: true, filterable: true, editable: true, options: this.directionOptions,
    },
    {
      text: 'Expected Length', value: 'expectedLength', sortable: true, filterable: true,
    },
    {
      text: 'Distance', value: 'distance', sortable: true, filterable: true,
    },
    {
      text: 'Turnaround Reason',
      value: 'turnaroundReason',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Mission/Deployment #',
      value: 'deploymentNumber',
      sortable: true,
      filterable: false,
      stopFilterValueGeneration: true,
    },
    {
      text: 'Robot Name',
      value: 'robotName',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Robot Number',
      value: 'robotNumber',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Additional Info',
      value: 'additionalInfo',
      sortable: true,
      filterable: false,
      editable: true,
      stopFilterValueGeneration: true,
    },
    {
      text: 'Operator',
      value: 'operator',
      sortable: true,
      filterable: true,
      editable: true,
    },
    {
      text: 'Coding Status',
      value: 'codingStatus',
      sortable: true,
      filterable: true,
      editable: true,
      options: this.yesNoOption,
    },
    {
      text: 'Media Uploaded',
      value: 'mediaUploaded',
      sortable: true,
      filterable: true,
      editable: false,
      options: this.yesNoOption,
    },
    {
      text: 'Active',
      value: 'isActive',
      sortable: true,
      filterable: true,
      editable: true,
      options: this.yesNoOption,
    },
    {
      text: 'Ready To Code',
      value: 'readyToCode',
      sortable: true,
      filterable: true,
      editable: false,
      options: this.yesNoOption,
    },
  ];

  defaultMatchFilters = [
    {
      header: 'name',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'date',
      type: 'date',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'dueDate',
      type: 'date',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'deliveryStatus',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'usmh',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    }, {
      header: 'dsmh',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'entryMH',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'direction',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'distance',
      type: 'number',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'turnaroundReason',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'deploymentNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'robotName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'robotNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'additionalInfo',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'operator',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'codingStatus',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'mediaUploaded',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'isActive',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'readyToCode',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'expectedLength',
      type: 'number',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  // #endregion
  // #region Solo/Vertue Manhole Headers
  soloVertueManholeHeaders = [
    {
      text: 'Asset',
      value: 'name',
      sortable: true,
      filterable: true,
      class: 'sticky',
      cellClass: 'sticky',
      editable: true,
    },
    {
      text: 'Mission #',
      value: 'deploymentNumber',
      sortable: true,
      filterable: false,
      stopFilterValueGeneration: true,
    },
    {
      text: 'Robot Name',
      value: 'robotName',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Robot Number',
      value: 'robotNumber',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Date Inspected', value: 'date', sortable: true, filterable: true,
    },
    {
      text: 'Due Date', value: 'dueDate', sortable: true, filterable: true,
    },
    {
      text: 'Delivery Status', value: 'deliveryStatus', sortable: true, filterable: true,
    },
    {
      text: 'Distance', value: 'distance', sortable: true, filterable: true,
    },
    {
      text: 'Result',
      value: 'result',
      sortable: true,
      filterable: true,
      editable: true,
      options: this.taskResultOptions,
    },
    {
      text: 'Additional Info',
      value: 'additionalInfo',
      sortable: true,
      filterable: false,
      editable: true,
      stopFilterValueGeneration: true,
    },
    {
      text: 'Operator',
      value: 'operator',
      sortable: true,
      filterable: true,
      editable: true,
    },
    {
      text: 'MACP',
      value: 'macp',
      sortable: true,
      filterable: true,
      editable: true,
      options: this.yesNoOption,
    },
    {
      text: 'Coding Status',
      value: 'codingStatus',
      sortable: true,
      filterable: true,
      editable: true,
      options: this.yesNoOption,
    },
    {
      text: 'Media Uploaded',
      value: 'mediaUploaded',
      sortable: true,
      filterable: true,
      editable: false,
      options: this.yesNoOption,
    },
    {
      text: 'Active',
      value: 'isActive',
      sortable: true,
      filterable: true,
      editable: true,
      options: this.yesNoOption,
    },
    {
      text: 'Ready to Code',
      value: 'readyToCode',
      sortable: true,
      filterable: true,
      editable: false,
      options: this.yesNoOption,
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
      filterable: false,
      editable: false,
      class: 'sticky-end',
      cellClass: 'sticky-end',
      stopFilterValueGeneration: true,
    },
  ];

  soloVertueManholeMatchFilters = [
    {
      header: 'name',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'deploymentNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'robotName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'robotNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'date',
      type: 'date',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'dueDate',
      type: 'date',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'deliveryStatus',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'distance',
      type: 'number',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'result',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'additionalInfo',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'operator',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'macp',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'codingStatus',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'mediaUploaded',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'isActive',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'readyToCode',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'actions',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
    },
  ];

  // #endregion
  // #region Solo Pipe Headers
  soloPipeHeaders = [
    {
      text: 'Asset', value: 'name', sortable: true, filterable: true, class: 'sticky', cellClass: 'sticky', editable: true,
    },
    {
      text: 'US MH', value: 'usmh', sortable: true, filterable: true,
    },
    {
      text: 'DS MH', value: 'dsmh', sortable: true, filterable: true,
    },
    {
      text: 'Entry MH', value: 'entryMH', sortable: true, filterable: true, editable: true, optionFunction: this.getEntryMHOptions,
    },
    {
      text: 'Direction', value: 'direction', sortable: true, filterable: true, editable: true, options: this.directionOptions,
    },
    {
      text: 'Mission #', value: 'deploymentNumber', sortable: true, filterable: false, stopFilterValueGeneration: true,
    },
    {
      text: 'Robot Name', value: 'robotName', sortable: true, filterable: true,
    },
    {
      text: 'Robot Number', value: 'robotNumber', sortable: true, filterable: true,
    },
    {
      text: 'Date Inspected', value: 'date', sortable: true, filterable: true,
    },
    {
      text: 'Due Date', value: 'dueDate', sortable: true, filterable: true,
    },
    {
      text: 'Delivery Status', value: 'deliveryStatus', sortable: true, filterable: true,
    },
    {
      text: 'Expected Length', value: 'expectedLength', sortable: true, filterable: true,
    },
    {
      text: 'Distance', value: 'distance', sortable: true, filterable: true,
    },
    {
      text: 'Turnaround Reason', value: 'turnaroundReason', sortable: true, filterable: true,
    },
    {
      text: 'Result', value: 'result', sortable: true, filterable: true, editable: true, options: this.taskResultOptions,
    },
    {
      text: 'Additional Info', value: 'additionalInfo', sortable: true, filterable: false, editable: true, stopFilterValueGeneration: true,
    },
    {
      text: 'Operator', value: 'operator', sortable: true, filterable: true, editable: true,
    },
    {
      text: 'Coding Status', value: 'codingStatus', sortable: true, filterable: true, editable: true, options: this.yesNoOption,
    },
    {
      text: 'Media Uploaded', value: 'mediaUploaded', sortable: true, filterable: true, editable: false, options: this.yesNoOption,
    },
    {
      text: 'Active', value: 'isActive', sortable: true, filterable: true, editable: true, options: this.yesNoOption,
    },
    {
      text: 'Ready To Code', value: 'readyToCode', sortable: true, filterable: true, editable: false, options: this.yesNoOption,
    },
    {
      text: 'Actions', value: 'actions', sortable: false, filterable: false, editable: false, class: 'sticky-end', cellClass: 'sticky-end', stopFilterValueGeneration: true,
    },
  ];

  soloPipeMatchFilters = [
    {
      header: 'name',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'usmh',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    }, {
      header: 'dsmh',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'entryMH',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'direction',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'deploymentNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'robotName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'robotNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'date',
      type: 'date',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'dueDate',
      type: 'date',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'deliveryStatus',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'expectedLength',
      type: 'number',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'distance',
      type: 'number',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'turnaroundReason',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'result',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'additionalInfo',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'operator',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'codingStatus',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'mediaUploaded',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'isActive',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'readyToCode',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,

    },

    {
      header: 'actions',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
    },
  ];
  // #endregion

  // #region Big Pipe Headers
  bigPipeHeaders = [
    {
      text: 'Deployment #',
      value: 'deploymentNumber',
      class: 'sticky',
      cellClass: 'sticky',
      sortable: true,
      filterable: true,
      stopFilterValueGeneration: true,
    },
    {
      text: 'Entry MH', value: 'entryMH', sortable: true, filterable: true, optionFunction: this.getEntryMHOptions,
    },
    {
      text: 'Destination MH', value: 'destinationMH', sortable: true, filterable: true,
    },
    {
      text: 'Direction', value: 'direction', sortable: true, filterable: true, options: this.directionOptions,
    },
    {
      text: 'Date Inspected', value: 'date', sortable: true, filterable: true,
    },
    {
      text: 'Expected Length', value: 'expectedLength', sortable: true, filterable: true,
    },
    {
      text: 'Surveyed Distance', value: 'distance', sortable: true, filterable: true,
    },
    {
      text: 'Truck #', value: 'truckNumber', sortable: true, filterable: true,
    },
    {
      text: 'Robot',
      value: 'robotName',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Deployment Notes',
      value: 'additionalInfo',
      sortable: true,
      filterable: false,
      editable: true,
    },
    {
      text: 'Crew Lead',
      value: 'operator',
      sortable: true,
      filterable: true,
      editable: true,
    },
    {
      text: 'Media Uploaded',
      value: 'mediaUploaded',
      sortable: true,
      filterable: true,
      editable: false,
      options: this.yesNoOption,
    },
    {
      text: 'Active',
      value: 'isActive',
      sortable: true,
      filterable: true,
      editable: true,
      options: this.yesNoOption,
    },
    {
      text: 'Coding Status',
      value: 'codingStatus',
      sortable: true,
      filterable: true,
      editable: true,
      options: this.yesNoOption,
    },
    {
      text: 'Ready to Code',
      value: 'readyToCode',
      sortable: true,
      filterable: true,
      editable: false,
      options: this.yesNoOption,
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
      filterable: false,
      editable: false,
      class: 'sticky-end',
      cellClass: 'sticky-end',
      stopFilterValueGeneration: true,
    },
  ];

  bigPipeMatchFilters = [
    {
      header: 'deploymentNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'entryMH',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'destinationMH',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    }, {
      header: 'direction',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'date',
      type: 'date',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'distance',
      type: 'number',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'expectedLength',
      type: 'number',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'truckNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'robotName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'additionalInfo',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'operator',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'mediaUploaded',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'isActive',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'codingStatus',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'readyToCode',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,

    },
    {
      header: 'actions',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
    },
  ];

  // #endregion
}
