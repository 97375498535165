

























































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserActions } from '@/store/users/actions';
import { DetailedUserData } from '@/store/users/types';
import {
  Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { BasicSelect } from 'vue-search-select';
import IntegrityTable, { FillFunctionContainer } from '@/components/IntegrityTable/IntegrityTable.vue';
import IntegrityDelete from '@/components/IntegrityDelete/IntegrityDelete.vue';
import { uuid } from 'vue-uuid';
import { REDZONE_ORGANIZATION_GUID } from '@/common/Constants';
import { CustomerData } from '@/store/customers/types';
import { ClientInfo, TeamMember } from './types';
import MPSSectionContactsDTO from './common/MPSSectionContactsDTO';

const userModule = namespace('users');

@Component({
  components: {
    BasicSelect,
    IntegrityTable,
    IntegrityDelete,
  },
})
export default class MPSSectionContacts extends Vue {
  @Prop() readonly canEditMPS: boolean;

  @Prop() readonly requiredField;

  @PropSync('valid') synchedValid;

  @PropSync('clientInfo') syncedClientInfo: ClientInfo[];

  @PropSync('teamMembers') syncedTeamMembers: TeamMember[];

  @PropSync('customer') synchedCustomer: CustomerData;

  @PropSync('sectionContactsDTO') synchedSectionContactsDTO: MPSSectionContactsDTO;

  @userModule.Action(UserActions.FETCH_ALL_DETAILED_USER_DATA) fetchAllDetailedUserData;

  @userModule.State('allDetailedUserData') allDetailedUsers: DetailedUserData[] | undefined;

  @userModule.State('loadError') loadUsersError;

  @userModule.State('loading') loadingUsers: boolean;

  @Watch('synchedSectionContactsDTO.newClientPhone')
  onNewClientPhoneChange(): void {
    this.synchedSectionContactsDTO.newClientPhone = this
      .synchedSectionContactsDTO.formatPhone(
        this.synchedSectionContactsDTO.newClientPhone,
      );
  }

  @Watch('synchedSectionContactsDTO.newMemberPhone')
  onNewMemberPhoneChange(): void {
    this.synchedSectionContactsDTO.newMemberPhone = this
      .synchedSectionContactsDTO.formatPhone(
        this.synchedSectionContactsDTO.newMemberPhone,
      );
  }

  @Watch('synchedSectionContactsDTO.newPhoneRegion')
  onNewPhoneRegionChange(): void {
    this.synchedSectionContactsDTO.newClientPhone = this
      .synchedSectionContactsDTO.formatPhone(
        this.synchedSectionContactsDTO.newClientPhone,
      );
    this.synchedSectionContactsDTO.newMemberPhone = this
      .synchedSectionContactsDTO.formatPhone(
        this.synchedSectionContactsDTO.newMemberPhone,
      );
    this.synchedSectionContactsDTO.formatRegionCode();
  }

  @Watch('synchedSectionContactsDTO.newPhoneCode')
  onNewPhoneRegionCodeChange(): void {
    this.synchedSectionContactsDTO.formatRegionCode();
  }

  resetMemberForm(): void {
    (this.$refs.memberForm as any).reset();
    this.synchedSectionContactsDTO.resetMemberForm();
  }

  resetContactForm(): void {
    (this.$refs.clientForm as any).reset();
    this.synchedSectionContactsDTO.resetContactForm();
  }

  departments = [
    'Project Management',
    'Operations',
    'Reporting / Internal Support',
    'Sales Support',
    'Client Information',
  ];

  roles = [
    'Primary Contact',
    'Deliverable Contact',
    'Invoicing Contact',
  ]

  rzTitles = [
    'Operations Manager',
    'Project Manager',
    'Reporting Manager',
    'Reporting Technician',
    'Field Supervisor',
    'Crew Lead',
  ];

  phoneRegions = [
    {
      name: 'United States',
      placeholder: '(123) 456-7890',
      rules: [(v: string): string | boolean | number => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) || 'Phone number not valid'],
    },
    {
      name: 'International',
      placeholder: '123 456 7890',
      rules: [(v: string): string | boolean | number => /^(\+[1-9]{1,2}\s)?(\d{3}\s){2}\d{4}(\s\d{4})?$/.test(v) || 'Phone number not valid'],
    },
  ];

  emailRules = [(v: string): string | boolean => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email must be valid'];

  clientInfoHeaders = [
    {
      text: 'Name',
      value: 'fullName',
      sortable: true,
      filterable: false,
    },
    {
      text: 'Company',
      value: 'company',
      sortable: true,
      filterable: false,
    },
    {
      text: 'Title',
      value: 'title',
      sortable: true,
      filterable: false,
    },
    {
      text: 'Role',
      value: 'role',
      sortable: true,
      filterable: false,
    },
    {
      text: 'Phone Number',
      value: 'phoneNumber',
      sortable: true,
      filterable: false,
    },
    {
      text: 'Email',
      value: 'email',
      sortable: true,
      filterable: false,
    },
    {
      text: 'Mailing Address',
      value: 'address',
      sortable: true,
      filterable: false,
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'start',
      class: 'sticky-end',
      cellClass: 'sticky-end',
      sortable: false,
    },
  ];

  projectTeamHeaders = [
    {
      text: 'Name',
      value: 'fullName',
      sortable: true,
      filterable: false,
    },
    {
      text: 'Title',
      value: 'title',
      sortable: true,
      filterable: false,
    },
    {
      text: 'Department',
      value: 'dept',
      sortable: true,
      filterable: false,
    },
    {
      text: 'Phone Number',
      value: 'phoneNumber',
      sortable: true,
      filterable: false,
    },
    {
      text: 'Email',
      value: 'email',
      sortable: true,
      filterable: false,
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'start',
      class: 'sticky-end',
      cellClass: 'sticky-end',
      sortable: false,
    },
  ];

  get fillTableDataFunctions(): FillFunctionContainer {
    const returnValue: FillFunctionContainer = {
      updateKey: uuid.v4(),
      fillFunctions: [
        {
          headerValue: 'fullName',
          functionVariables: [],
          fillFunction:
            function fillFullName(item: any): string {
              return `${item.firstName} ${item.lastName}`;
            },
        },
        {
          headerValue: 'role',
          functionVariables: [],
          fillFunction:
            function fillRoleString(item: any): string {
              return item.role == null ? '' : item.role;
            },
        },
        {
          headerValue: 'phoneNumber',
          functionVariables: [],
          fillFunction:
            function fillPhoneNumber(item: any): string {
              return `${item.phoneCode} ${item.phone}`;
            },
        },
      ],
    };
    return returnValue;
  }

  @Watch('syncedClientInfo', { immediate: true, deep: true })
  onSyncedClientInfoChange(): void {
    this.synchedValid = true;
  }

  @Watch('synchedSectionContactsDTO.newMemberName')
  onNewMemberName(): void {
    if (this.synchedSectionContactsDTO.newMemberName != null && this.synchedSectionContactsDTO.newMemberName !== '') {
      this.synchedSectionContactsDTO.validName = true;
    } else {
      this.synchedSectionContactsDTO.validName = false;
    }
    const newMember = this.synchedSectionContactsDTO.userNameList
      .find((u) => this.synchedSectionContactsDTO.newMemberName === u.name);
    this.synchedSectionContactsDTO.newMemberEmail = newMember.email;
  }

  @Watch('loadingUsers')
  onLoadingUsersChange(): void {
    if (
      (this.allDetailedUsers != null)
      && !this.loadingUsers
    ) {
      this.synchedSectionContactsDTO.userNameList = [];
      this.allDetailedUsers.forEach((user) => {
        if (this.isAvailableUser(user)) {
          this.synchedSectionContactsDTO.userNameList.push(
            {
              guid: user.guid,
              email: user.email,
              name: `${user.firstname} ${user.lastname}`,
            },
          );
        }
      });
    }
  }

  isUsersRedzoneOrganizationMember(user: DetailedUserData): boolean {
    return user.organizationGuids.includes(REDZONE_ORGANIZATION_GUID);
  }

  isUserPartOfCustomer(user: DetailedUserData, customer: CustomerData): boolean {
    return user.customerGuids.includes(customer.guid);
  }

  isAvailableUser(user: DetailedUserData): boolean {
    if (this.synchedSectionContactsDTO.userNameList.indexOf(`${user.firstname} ${user.lastname}`) !== -1
    || user.firstname.length === 0
    || user.lastname.length === 0) {
      return false;
    }
    return this.isUsersRedzoneOrganizationMember(user)
    || this.isUserPartOfCustomer(user, this.synchedCustomer);
  }

  async mounted(): Promise<void> {
    if (
      (this.allDetailedUsers == null || this.allDetailedUsers.length === 0)
      && !this.loadingUsers
    ) {
      await this.fetchAllDetailedUserData();
    }
    this.onLoadingUsersChange();
  }

  resetValidation(): void {
    (this.$refs.contactsForm as any).reset();
  }
}
