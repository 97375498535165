
























































































































































































































































































































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { InspectionData, Condition, AssetData } from '@/store/asset/types';
import {
  Component, Prop,
  Watch,
} from 'vue-property-decorator';
import { format } from 'date-fns';
import ReportObservation from '../ReportObservation.vue';
import PipeConditionReport from '../../PipeConditionReport/PipeConditionReport.vue';
import ReportFooter from '../ReportFooter.vue';
import util from '../util';
import { MacpReportFields } from './types';
import ReportInterface from '../ReportHelpers/ReportInterface.vue';

@Component({
  components: {
    ReportFooter,
    ReportObservation,
    PipeConditionReport,
  },
})
export default class MACP2 extends ReportInterface {
  @Prop() readonly assetName!: string;

  @Prop() readonly asset!: AssetData;

  @Prop() readonly inspection?: InspectionData;

  @Prop({ default: -1 }) readonly fullVideoTime: number;

  howManyIterations = 0;

  inspectionsGrouped: Array<InspectionData> = [];

  workOrderData: MacpReportFields | any = {};

  workOrderFields = [
    { name: 'project', value: 'Project' },
    { name: 'street_L1_2', value: 'Address' },
    { name: 'city_L1_2', value: 'City' },
    { name: 'inspectionStatus_L1_2', value: 'MHInspectionStatus' },
    { name: 'inspectionLevel_L1_2', value: 'MHInspectionLevel' },
    { name: 'overallQuick', value: 'OverallQuick' },
    { name: 'overallIndex', value: 'OverallIndex' },
    { name: 'overallRating', value: 'OverallRating' },
    { name: 'structuralQuick', value: 'StructuralQuick' },
    { name: 'structuralIndex', value: 'StructuralIndex' },
    { name: 'structuralRating', value: 'StructuralRating' },
    { name: 'omQuick', value: 'OMQuick' },
    { name: 'omIndex', value: 'OMIndex' },
    { name: 'omRating', value: 'OMRating' },
    { name: 'owner', value: 'Owner' },
    { name: 'customer', value: 'Customer' },
    { name: 'mhUse_L2', value: 'SewerUse' },
    { name: 'accessType_L1_2', value: 'MHAccessType' },
    { name: 'yearConstructed', value: 'YearBuilt' },
    { name: 'yearRenewed', value: 'YearRenewed' },
    { name: 'evidenceofSurcharge_L2', value: 'MHEvidenceSurcharge' },
    { name: 'northing', value: 'Northing' },
    { name: 'easting', value: 'Easting' },
    { name: 'elevation', value: 'Elevation' },
    { name: 'verticalDatum', value: 'VerticalDatum' },
    { name: 'gpsAccuracy', value: 'GPSAccuracy' },
    { name: 'additionalInformation', value: 'AdditionalInfo' },
    { name: 'shape_L2_legacy', value: 'MHCoverShape' },
    { name: 'shape_L2', value: 'Cover_Shape' },
    { name: 'coverMaterial_L2', value: 'MHCoverMaterial' },
    { name: 'holeNumber_L2', value: 'HolesNumber' },
    { name: 'cover_FrameFit_L2', value: 'MHCoverFrameFit' },
    { name: 'insertType_L2', value: 'MHCoverInsert' },
    { name: 'ringType_L1_2', value: 'MHAdjustmentRingType' },
    { name: 'ringMaterial_L2', value: 'MHCoverMaterial_AdjRing' },
    { name: 'frameMaterial_L2', value: 'MHCoverMaterial_frame' },
    { name: 'sealInflow_L2', value: 'MHInfiltrationInflow_FrameSeal' },
    { name: 'chimneyPresent_L2', value: 'MHChimneyPresent' },
    { name: 'chimneyI_I', value: 'MHInfiltrationInflow_Chimney' },
    { name: 'chimneyfirstMaterial_L2', value: 'MHMaterial_Chimney1' },
    { name: 'secondMaterial', value: 'MHMaterial_Chimney2' },
    { name: 'liningInterior', value: 'MHLiningMaterial_Chimney_Interior' },
    { name: 'liningExterior', value: 'MHLiningMaterial_Chimney_Exterior' },
    { name: 'chimneyCondition_L1', value: 'MHCondition_Chimney' },
    { name: 'conetype_L2', value: 'MHConeType' },
    { name: 'first_Material_L2', value: 'MHMaterial_Wall' },
    { name: 'second_Material_L2', value: 'MHMaterial_Cone_2' },
    { name: 'coneCondition_L1', value: 'MHCondition_Cone' },
    { name: 'conefirstMaterial_L2', value: 'MHMaterial_Cone' },
    { name: 'wallfirstMaterial_L2', value: 'MHMaterial_Wall' },
    { name: 'secondMaterial_L2', value: 'MHSecondMaterial_Wall' },
    { name: 'wallLiningInterior', value: 'MHLiningMaterial_Wall_Interior' },
    { name: 'wallLiningExterior', value: 'MHLiningMaterial_Wall_Exterior' },
    { name: 'wallCondition_L1', value: 'MHCondition_Wall' },
    { name: 'benchPresent_L2', value: 'MHBenchPresent' },
    { name: 'benchMaterial_L2', value: 'MHMaterial_Bench' },
    { name: 'benchLining', value: 'MHLiningMaterial_Bench' },
    { name: 'benchCondition_L1', value: 'MHCondition_Bench' },
    { name: 'channelInstalled_L2', value: 'MHChannelInstalled' },
    { name: 'channelMaterial_L2', value: 'MHMaterial_Channel' },
    { name: 'exposure_L2', value: 'MHChannelExposure' },
    { name: 'channelCondition_L1', value: 'MHCondition_Channel' },
    { name: 'channeltype_L2', value: 'MHChannelType' },
    { name: 'number_L2', value: 'StepNumber' },
    { name: 'material_L2', value: 'MHStepMaterial' },
    { name: 'surveyedBy_L1_2', value: 'SurveyedBy' },
    { name: 'reviewedBy', value: 'ReviewedBy' },
    { name: 'weather', value: 'WeatherType' },
    { name: 'purposeofSurvey_L1_2', value: 'MHPurpose' },
    { name: 'certificateNumber_L1_2', value: 'CertificateNumber' },
    { name: 'reviewerCertificateNo', value: 'ReviewerCertificateNumber' },
    { name: 'pre_Cleaning_L2', value: 'PreCleaning' },
    { name: 'p_ONumber', value: 'PONumber' },
    { name: 'mediaLabel', value: 'MediaLabel' },
    { name: 'date_L1_2', value: 'InspDate' },
    { name: 'sheetNumber_L1_2', value: 'SheetNumber' },
    { name: 'dateCleaned', value: 'DateCleaned' },
    { name: 'workOrder', value: 'WorkOrderNum' },
    { name: 'consequenceofFailure', value: 'ConsequenceOfFailure' },
    { name: 'rimtoInvert_L2', value: 'RimtoInvert' },
    { name: 'rimtoGrade_L2', value: 'RimtoGrade' },
    { name: 'gradetoInvert_L2', value: 'GradetoInvert' },
    { name: 'rimtoGradeExposed', value: 'RimToGradeExposed' },
    { name: 'size_L2', value: 'Cover_Size' },
    { name: 'sizeWidth_L2', value: 'CoverSizeWidth' },
    { name: 'centerCoverSize', value: 'CenterCoverSize' },
    { name: 'bearingSurfaceDiameter_L2', value: 'CoverBearingSurfaceDiameter' },
    { name: 'coverbearingSurfaceWidth_L2', value: 'CoverBearingSurfaceDiaWidth' },
    { name: 'ringHeight', value: 'AdjustmentRingHeight' },
    { name: 'framebearingSurfaceWidth_L2', value: 'FrameBearingSurfaceWidth' },
    { name: 'coverbearingSurfaceDepth_L2', value: 'CoverBearingSurfaceDiameter' },
    { name: 'framebearingSurfaceDepth_L2', value: 'FrameBearingSurfaceDepth' },
    { name: 'clearOpeningDiam_L2', value: 'FrameClearOpeningDia' },
    { name: 'clearOpeningWidth_L2', value: 'FrameClearOpenWidth' },
    { name: 'frameDepth', value: 'FrameDepth' },
    { name: 'offsetDistance_L2', value: 'FrameOffsetDistance' },
    { name: 'chimneyDepth_L2', value: 'ChimneyDepth' },
    { name: 'chimneyclearOpening', value: 'ChimneyClearOpening' },
    { name: 'coneclearOpening', value: 'ConeClearOpening' },
    { name: 'conedepth_L2', value: 'ConeDepth' },
    { name: 'walldepth_L2', value: 'WallDepth' },
    { name: 'wallDiameter_Length', value: 'WallDiameter_Length' },
    { name: 'wallbySize_Width', value: 'WallDiameter_Width' },
    { name: 'holeDiameter_Vent_L2', value: 'MHHoleDiameter' },
    { name: 'shape', value: 'MHCoverShape' },
    { name: 'lining', value: 'LiningMethod' },
    { name: 'location', value: 'Location' },
    { name: 'locationDetails', value: 'LocationDetails' },
    { name: 'surveyor', value: 'SurveyedBy' },
    { name: 'direction', value: 'Direction' },
    { name: 'purpose', value: 'Purpose' },
    { name: 'category', value: 'SewerCategory' },
    { name: 'flowControl', value: 'FlowControl' },
    { name: 'additionalInfo', value: 'AdditionalInfo' },
    { name: 'po', value: 'PO_Number' },
  ];

  get codingDetailReport(): Record<string, unknown> {
    return this.codingDetail ? JSON.parse(this.codingDetail.inspections[0].jsonData) : {};
  }

  get pageCountSkip(): number {
    const isPipeConnectionPage = (this.reportPipeConnections.length > 0) ? 1 : 0;
    return 3 + isPipeConnectionPage;
  }

  @Watch('workOrderData', { deep: true })
  onWorkOrderDataChange(): void {
    this.onInspectionChange();
    // Fill Cover Condition
    this.$set(this.workOrderData, 'CoverCondition_L1_2', this.getCheckboxValues(
      ['CoverCond_Sound', 'CoverCond_Broken', 'CoverCond_Cracked', 'CoverCond_Missing', 'CoverCond_Corroded', 'CoverCond_BoltsMissing', 'CoverCond_RestraintMissing', 'CoverCond_RestraintDefective'],
      ['Sound', 'Broken', 'Cracked', 'Missing', 'Corroded', 'Bolts Missing', 'Restraint Missing', 'Restraint Defective'],
    ));
    // Fill Cover Type
    this.$set(this.workOrderData, 'coverType', this.getCheckboxValues(
      ['CoverType_Solid', 'CoverType_Bolted', 'CoverType_Vented', 'CoverType_Locking', 'CoverType_Gasketed', 'CoverType_Lamphole', 'CoverType_InnerCover', 'CoverType_HatchDouble', 'CoverType_HatchSingle', 'CoverType_RemovableCenter'],
      ['Solid', 'Bolted', 'Vented', 'Locking', 'Gasketed', 'Lamphole', 'Inner Cover', 'Hatch Double', 'Hatch Single', 'Removable Center'],
    ));
    // Fill Cover Insert Condition
    this.$set(this.workOrderData, 'coverInsertCondition', this.getCheckboxValues(
      ['InsertCond_PoorlyFitting', 'InsertCond_Sound', 'InsertCond_Cracked', 'InsertCond_Leaking', 'InsertCond_Corroded', 'InsertCond_InsertFell'],
      ['Poorly Fitting', 'Sound', 'Cracked', 'Leaking', 'Corroded', 'Insert Fell'],
    ));
    // Fill Ring Condition
    this.$set(this.workOrderData, 'ringCondition_L1_2', this.getCheckboxValues(
      ['RingCondition_Sound', 'RingCondition_Broken', 'RingCondition_Cracked', 'RingCondition_Leaking', 'RingCondition_Corroded', 'RingCondition_PoorInstall'],
      ['Sound', 'Broken', 'Cracked', 'Leaking', 'Corroded', 'Poor Install'],
    ));
    // Fill Seal Condition
    this.$set(this.workOrderData, 'sealCondition_L2', this.getCheckboxValues(
      ['SealCondition_Loose', 'SealCondition_Sound', 'SealCondition_Offset', 'SealCondition_Cracked', 'SealCondition_Missing'],
      ['Loose', 'Sound', 'Offset', 'Cracked', 'Missing'],
    ));
    // Fill Frame Condition
    this.$set(this.workOrderData, 'frameCondition_L1_2', this.getCheckboxValues(
      ['FrameCondition_Sound', 'FrameCondition_Broken', 'FrameCondition_Coated', 'FrameCondition_Cracked', 'FrameCondition_Missing', 'FrameCondition_Corroded'],
      ['Sound', 'Broken', 'Coated', 'Cracked', 'Missing', 'Corroded'],
    ));
  }

@Watch('inspection')
  onInspectionChange(): void {
    this.inspectionsGrouped = [];
    this.howManyIterations = 0;
    if (
      this.inspection != null
    && this.inspection.conditionReport != null
    && this.inspection.conditionReport.length > 0
    ) {
      this.howManyIterations = Math.ceil(
        this.inspection.conditionReport.length / 30,
      );

      let tempList = [];
      this.inspection.conditionReport.forEach((e, i) => {
        if (i % 30 === 0 && i !== 0) {
          const newInspection: InspectionData = {
            name: this.inspection.name,
            guid: this.inspection.guid,
            inspectionDate:
            this.inspection.inspectionDate != null
              ? this.inspection.inspectionDate
              : null,
            macpLevel: 'Level 1',
            maxPayout: this.inspection.maxPayout,
            measurementUnits: this.inspection.measurementUnits,
            conditionReport: this.inspection.conditionReport,
            flatImage: this.inspection.flatImage,
            video: this.inspection.video,
            wmVideo: this.inspection.wmVideo,
            msiExist: this.inspection.msiExist,
            model: this.inspection.model,
            deliverablesJson: this.inspection.deliverablesJson,
            report: this.inspection.report,
            payoutIPD:
            this.inspection.payoutIPD != null
              ? this.inspection.payoutIPD
              : null,
            version: this.inspection.version,
          };
          newInspection.conditionReport = tempList;
          this.inspectionsGrouped.push(newInspection);
          tempList = [];
        }
        tempList.push(e);
      });
      if (tempList.length > 0) {
        const newInspection: InspectionData = {
          name: this.inspection.name,
          guid: this.inspection.guid,
          inspectionDate:
          this.inspection.inspectionDate != null
            ? this.inspection.inspectionDate
            : null,
          macpLevel: 'Level 1',
          maxPayout: this.inspection.maxPayout,
          measurementUnits: this.inspection.measurementUnits,
          conditionReport: this.inspection.conditionReport,
          flatImage: this.inspection.flatImage,
          video: this.inspection.video,
          wmVideo: this.inspection.wmVideo,
          msiExist: this.inspection.msiExist,
          model: this.inspection.model,
          deliverablesJson: this.inspection.deliverablesJson,
          report: this.inspection.report,
          payoutIPD:
          this.inspection.payoutIPD != null
            ? this.inspection.payoutIPD
            : null,
          version: this.inspection.version,
        };
        newInspection.conditionReport = tempList;
        this.inspectionsGrouped.push(newInspection);
      }
    }
  }

getCheckboxValues(inspectionNames: string[], textNames: string[]): string {
  if (inspectionNames.length !== textNames.length) {
    return '';
  }
  const strings: string[] = [];
  for (let i = 0; i < inspectionNames.length; i += 1) {
    const foundKey = Object.keys(this.workOrderJson).find((wof) => wof.toLowerCase()
        === inspectionNames[i].toLowerCase());
    if (foundKey && this.workOrderJson[foundKey]) {
      strings.push(textNames[i]);
    }
  }

  return strings.join(', ');
}

// eslint-disable-next-line class-methods-use-this
formatText(text: string): string {
  if (!text) return '';
  let newText = text;

  newText = newText.replace(/&gt;/g, '>');
  newText = newText.replace(/&lt;/g, '<');
  newText = newText.replace(/&quot;/g, '"');

  return newText;
}

formatDate(date: string): string {
  if (date == null || date.trim() === '') {
    return '';
  }
  return format(Date.parse(date), 'yyyy-MM-dd HH:mm');
}

getDisplayDistanceInMM(value: number): string {
  try {
    // eslint-disable-next-line eqeqeq
    return value && value != 0
      ? util.getDisplayDistanceInMM(this.displayImperial, value)
          + util.getDistanceUnitsInMM(this.displayImperial)
      : '';
  } catch {
    return '';
  }
}

getDisplayDistanceFtM(value: number): string {
  try {
    // eslint-disable-next-line eqeqeq
    return value && value != 0
      ? util.getDisplayDistanceFtM(this.displayImperial, value)
          + util.getDistanceUnitsFtM(this.displayImperial)
      : '';
  } catch {
    return '';
  }
}

get assetStreet(): string {
  return this.workOrderData?.street_L1_2 != null ? this.workOrderData.street_L1_2 : '';
}

get assetCity(): string {
  return this.workOrderData?.city_L1_2 != null ? this.workOrderData.city_L1_2 : '';
}

get reportPipeConnections(): any {
  if (this.codingDetail?.inspections == null) {
    return [];
  }
  const foundInspection = this.codingDetail.inspections
    .find((insp) => insp.inspectionGuid === this.inspection.guid);
  if (foundInspection?.subInspectionJsonData == null) {
    return [];
  }
  const pipeConnections = foundInspection.subInspectionJsonData
    .map((value) => JSON.parse(value.jsonData));
  if (pipeConnections instanceof Array || Array.isArray(pipeConnections)) {
    return pipeConnections.sort((a, b) => a['PipeNumber'].toString().localeCompare(b['PipeNumber'].toString()));
  }
  return [];
}

get conditionReportPages(): Array<Condition[]> {
  let index = 1;
  let pageCount = 0;
  let page = [];
  const pages = [[]];

  if (this.inspection?.conditionReport != null && this.inspection?.conditionReport?.length === 0) {
    return [];
  }

  if (this.inspection && this.inspection.conditionReport) {
    const sortedArr = [...this.inspection.conditionReport]
      .sort((a, b) => a.distance - b.distance);
    sortedArr.forEach((condRep) => {
      pages[pageCount].push(condRep);

      if (index % 3 === 0 && index !== this.inspection.conditionReport.length) {
        page = [];
        pages.push(page);
        pageCount += 1;
      }

      index += 1;
    });
  }
  return pages;
}

getQuickStyle(score: string): any {
  const quickScore = score?.length > 0 ? parseInt(score.charAt(0), 10) : 0;
  return {
    color: quickScore === 3 ? 'black' : 'white',
    backgroundColor: util.getScoreColor(quickScore),
  };
}
}
