









































































































































































import { Component } from 'vue-property-decorator';
import { uuid } from 'vue-uuid';
import ReportFooter from '../ReportFooter.vue';
import { SmokeTestFields } from './types';
import ReportInterface, {
  WorkOrderfieldType,
} from '../ReportHelpers/ReportInterface.vue';
import ReportCalendarInput from '../ReportHelpers/ReportInputHelpers/ReportCalendarInput.vue';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import ReportAssignedToDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportAssignedToDropDownInput.vue';
import ReportTableInput from '../ReportHelpers/ReportInputHelpers/ReportTableInput.vue';
import { SubInspectionWorkOrder } from '../ReportHelpers/types';

@Component({
  components: {
    ReportFooter,
    ReportCalendarInput,
    ReportDropDownInput,
    ReportTextInput,
    ReportAssignedToDropDownInput,
    ReportTableInput,
  },
})
export default class SmokeTestReport extends ReportInterface {
  // #region Abstract fields
  workOrderData: SmokeTestFields | any = {};

  workOrderFields = [
    { name: 'assignedTo', value: 'AssignedTo', dontUpdateInProgress: true },
    { name: 'dateIssued', value: 'DateIssued', type: WorkOrderfieldType.DATE },
    {
      name: 'completeBy', value: 'PlanDueDate', type: WorkOrderfieldType.DATE, dontUpdateInProgress: true,
    },
    { name: 'workDescription', value: 'WorkDescription' },
    { name: 'specialInstructions', value: 'SpecialInstructions' },
    { name: 'testDate', value: 'TestDate', type: WorkOrderfieldType.DATE },
    { name: 'startTime', value: 'StartTime', type: WorkOrderfieldType.TIME },
    { name: 'endTime', value: 'EndTime', type: WorkOrderfieldType.TIME },
    { name: 'setupManhole', value: 'Manhole' },
    { name: 'noOfReturns', value: 'Returns' },
    { name: 'inflowReturns', value: 'InflowReturns' },
    { name: 'infilReturns', value: 'InfiltrationReturns' },
    { name: 'comments', value: 'Comments' },
  ];

  // #endregion

  mounted(): void {
    if (this.item?.guid && !this.deploymentId) {
      this.fetchDeploymentId(this.item.guid);
    }
  }

  SewerStructuresColumn = 'Sewer Structures';

  SewerMainsColumn = 'Sewer Mains'

  get smokeTestColumnNames(): string[] {
    return [this.SewerStructuresColumn, this.SewerMainsColumn];
  }

  smokeTestFilterFunction(data: SubInspectionWorkOrder): string {
    return data?.jsonData?.AssetType === 'Main'
      ? this.SewerMainsColumn
      : this.SewerStructuresColumn;
  }

  smokeTestNewAsset(column: string): SubInspectionWorkOrder {
    return {
      subInspectionGuid: uuid.v4(),
      jsonData: {
        AssetID: '',
        AssetType: column === this.SewerMainsColumn ? 'Main' : '',
        AssetNumber: this.subInspectionData.length + 1,
      },
    };
  }
}
