import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from '@/store/types';

import project from './project';
import asset from './asset';
import inspection from './inspection/index';
import mapPages from './mapPages';
import planning from './planning';
import reportExports from './reportExports';
import userPrefs from './userPrefs';
import mps from './mps';
import users from './users';
import dbExports from './dbExports';
import scopeHistory from './scopeHistory';
import dataStandards from './dataStandards';
import admin from './admin';
import agol from './agol';
import crossSections from './crossSections';
import flatGraph from './flatGraph';
import lineGraph from './lineGraph';
import defectCoding from './defectCoding';
import report from './report';
import wallLoss from './wallLoss';
import assignments from './assignments';
import metrics from './metrics';
import deployments from './deployments';
import integritySnackBar from './integritySnackBar';
import routing from './routing';
import msiPayout from './msiPayout';
import releases from './releases';
import userPermissions from './userpermissions';
import organizations from './organizations';
import customers from './customers';
import processing from './processing';
import resources from './resources';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    title: 'ICOM Viewer',

  },
  modules: {
    project,
    asset,
    inspection,
    dbExports,
    mapPages,
    planning,
    reportExports,
    userPrefs,
    mps,
    users,
    scopeHistory,
    dataStandards,
    admin,
    agol,
    crossSections,
    flatGraph,
    lineGraph,
    defectCoding,
    report,
    wallLoss,
    assignments,
    metrics,
    deployments,
    routing,
    integritySnackBar,
    msiPayout,
    releases,
    userPermissions,
    organizations,
    customers,
    processing,
    resources,
  },
};

export default new Vuex.Store<RootState>(store);
