





























































































































import {
  Component, Prop,
  Watch,
} from 'vue-property-decorator';
import { AssetActions } from '@/store/asset/actions';
import { ReportActions } from '@/store/report/actions';
import { namespace } from 'vuex-class';
import { AssetData, InspectionData } from '@/store/asset/types';

import { AssetMutations } from '@/store/asset/mutations';
import MACP from '@/components/Report/MACP2/MACP2.vue';
import { ReportType } from '@/types';
import VueHtml2pdf from 'vue-html2pdf';
import ObservationFullReport from '@/components/Report/ObservationFullReport/ObservationFullReport.vue';
import CodingFormCommonMixin from '@/components/Report/CodingFormCommonMixin.vue';
import OtherInfoReport from '../../components/AdditionalInformation/OtherInfoReport.vue';

const assetModule = namespace('asset');
const reportModule = namespace('report');

@Component({
  components: {
    MACP,
    ObservationFullReport,
    OtherInfoReport,
    VueHtml2pdf,
  },
})
export default class Report extends CodingFormCommonMixin {
  @Prop() readonly assetId!: string;

  @Prop({ default: 2 }) readonly reportType: ReportType;

  @Prop({ default: true }) readonly displayImperial!: boolean;

  @Prop({ default: false }) readonly msiExist: boolean;

  @Prop({ default: -1 }) readonly fullVideoTime: number;

  @Prop() readonly canExport: boolean;

  @Prop({ default: true }) canPlan: boolean;

  @assetModule.State('inspection') inspectionData: InspectionData | undefined =
    undefined;

  @assetModule.State('asset') asset: AssetData | undefined = undefined;

  @assetModule.Action(AssetActions.FETCH_ASSET_DATA) fetchAssetData;

  @assetModule.Mutation(AssetMutations.SET_INSPECTION_BY_ID) setInspectionById;

  @reportModule.State('data') cannedPDFUri: string | undefined;

  @reportModule.Action(ReportActions.GET_CANNED_PDF_URI) fetchCannedPDFURI;

  reportJson = {};

  schedulingJson = {};

  selectedType = this.reportTypeOptions[0];

  hasDownloaded = true;

  reportRefreshKey = 0;

  codingDetailInspections = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get reportData(): any {
    const reportData = {};
    if (this.fullCodingForm != null) {
      this.fullCodingForm.forEach((data) => {
        reportData[data.header] = data.value;
      });
    }
    return reportData;
  }

  @Watch('codingDetail')
  onCodingDetailUpdate(): void {
    this.codingDetailsUpdateFunc();
  }

  generateReport(): void {
    this.hasDownloaded = false;
    // eslint-disable-next-line dot-notation
    this.$refs.html2Pdf['generatePdf']();
  }

  @Watch('selectedType')
  selectType(option: string): void {
    this.selectedType = option;
    this.$nextTick().then(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const pdfReport = this.$refs.pdfReport as any;
      let codingDetailJson = {};
      if (this.codingDetail?.inspections != null) {
        let foundCodingDetail = this.codingDetail.inspections
          .find((insp) => insp.inspectionGuid === this.inspection?.guid);
        if (foundCodingDetail == null) {
          if (this.codingDetail?.inspections != null && this.codingDetail.inspections.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            foundCodingDetail = this.codingDetail.inspections[0];
            codingDetailJson = JSON.parse(foundCodingDetail.jsonData);
          }
        } else {
          codingDetailJson = JSON.parse(foundCodingDetail.jsonData);
        }
      }
      if (pdfReport?.updateReportData) {
        pdfReport.updateReportData(Object.assign(codingDetailJson, this.reportData));
      }
      if (pdfReport?.updateReportSubData) {
        this.codingDetailInspections.map((subForm, index) => ({
          subInspectionGuid: subForm.subInspectionGuid,
          jsonData: this.subFullCodingForm[index]?.reduce(
            (a, v) => ({ ...a, [v.header]: v.value }),
            {},
          ) ?? {},
        }));
        const reportData = {};
        this.fullCodingForm.forEach((data) => {
          reportData[data.header] = data.value;
        });
        pdfReport.updateReportData(Object.assign(codingDetailJson, reportData));
      }
    });
  }

  get reportTypeOptions(): string[] {
    return [
      (this.reportType === ReportType.MACP) && 'MACP 7.0',
      (this.reportType === ReportType.Observation) && 'Observation',
      (this.msiExist) && 'MSI',
    ].filter(Boolean);
  }

  get report(): string {
    switch (this.selectedType) {
      case 'MACP 7.0':
        return 'MACP';
      case 'Observation':
        return 'ObservationFullReport';
      default:
        return '';
    }
  }

  // If the following data has not been populated the we need to fetch the data
  mounted(): void {
    if (this.msiExist) {
      this.fetchCannedPDFURI(this.inspectionId);
    }
    const request = {
      assetId: this.assetId,
      inspectionId: this.inspectionId,
    };

    if (!this.asset || this.asset.guid !== this.assetId) {
      this.fetchAssetData(request);
    }

    if (!this.inspection || this.inspection.guid !== this.inspectionId) {
      this.setInspectionById(this.inspectionId);
    }
    this.onCodingDetailUpdate();
    this.selectType(this.reportTypeOptions[0]);
  }

  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  updateWorkOrderJson(workOrderJson: any): void {
    Object.assign(this.reportJson, workOrderJson);
  }

  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  updateAdditionalReportData(schedulingJson: any): void {
    Object.assign(this.schedulingJson, schedulingJson);
  }
}
