





























import { Component, Vue } from 'vue-property-decorator';
import { Mapping } from '@/store/types';
import ImportResourceFileUpload from './ImportResourceFileUpload.vue';

@Component({
  components: {
    ImportResourceFileUpload,
  },
})
export default class ImportResource extends Vue {
  resourceTypeOptions = ['Operator', 'Equipment', 'Material', 'Service'];

  selectedResource = 'Operator';

  get requiredColumns(): Mapping[] {
    switch (this.selectedResource) {
      case 'Operator':
        return [
          { frontEndName: 'Crew Name' },
          { frontEndName: 'Email' },
          { frontEndName: 'Regular Hourly Rate' },
          { frontEndName: 'Overtime Hourly Rate' },
          { frontEndName: 'Double Time Hourly Rate' },
        ] as Mapping[];
      case 'Equipment':
        return [
          { frontEndName: 'Equipment Name' },
          { frontEndName: 'Equipment Type' },
          { frontEndName: 'Hourly Rate' },
        ] as Mapping[];
      case 'Material':
        return [
          { frontEndName: 'Material Name' },
          { frontEndName: 'Description' },
          { frontEndName: 'Unit of Measure' },
          { frontEndName: 'Unit Cost' },
        ] as Mapping[];
      case 'Service':
        return [
          { frontEndName: 'Service Provider' },
          { frontEndName: 'Description' },
          { frontEndName: 'Unit of Measure' },
          { frontEndName: 'Unit Cost' },
        ] as Mapping[];
      default:
        return [] as Mapping[];
    }
  }
}
