import { Module } from 'vuex';
import { RootState } from '../types';
import { actions } from './actions';
import { ResourceState } from './types';
import { mutations } from './mutations';

const state: ResourceState = {
  resourceLoading: false,
  patchResourceLoading: false,
  postResourceLoading: false,
  deleteResourceLoading: false,
  fetchResourceError: '',
  patchResourceError: '',
  postResourceError: '',
  deleteResourceError: '',
  projectResource: null,
  patchProjectResource: null,
  postProjectResource: null,
};

const integrityResource: Module<ResourceState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default integrityResource;
