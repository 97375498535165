




















































/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component, Prop, VModel, Vue,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  EquipmentResource,
  MaterialResource,
  OperatorResource,
  ProjectResource,
  ServiceResource,
} from '@/store/resources/types';
import ReportResourceTableInput from '../ReportHelpers/ReportInputHelpers/ReportResourceTableInput.vue';
import ReportFooter from '../ReportFooter.vue';

const resourcesModule = namespace('resources');

@Component({
  components: {
    ReportResourceTableInput,
    ReportFooter,
  },
})
export default class TimeAndMaterialsTable extends Vue {
  @resourcesModule.State('projectResource') projectResource:
    | ProjectResource
    | undefined;

  @VModel() modelValue!: any | undefined;

  @Prop() readonly pageNumber!: number;

  itemsPerPage = 18;

  padding = 3; // Take into account two headers and bottom row

  laborColumns = [
    { name: 'Operator', value: 'operator' },
    { name: 'Start Date', value: 'startDate', type: 'date' },
    {
      name: 'OT Rate',
      value: 'overTimeRate',
      type: 'number',
      fillValue: 'overTimeRate',
    },
    {
      name: 'DT Rate',
      value: 'doubleTimeRate',
      type: 'number',
      fillValue: 'doubleTimeRate',
    },
    {
      name: 'Regular Rate',
      value: 'hourlyRate',
      type: 'number',
      fillValue: 'hourlyRate',
    },
    { name: 'OT Hours', value: 'otHours', type: 'number' },
    { name: 'DT Hours', value: 'dtHours', type: 'number' },
    { name: 'Regular Hours', value: 'regularHours', type: 'number' },
    {
      name: 'Total Time',
      value: 'totalTime',
      function: this.laborTimeFunction,
      sum: true,
    },
    {
      name: 'Cost',
      value: 'cost',
      function: this.laborCostFunction,
      sum: true,
    },
  ];

  equipmentColumns = [
    { name: 'Equipment', value: 'equipment' },
    { name: 'Start Date', value: 'startDate', type: 'date' },
    {
      name: 'Equipment Type',
      value: 'equipmentType',
      fillValue: 'equipmentType',
    },
    {
      name: 'Hourly Rate',
      value: 'hourlyRate',
      type: 'number',
      fillValue: 'hourlyRate',
    },
    { name: 'Regular Hours', value: 'regularHours', type: 'number' },
    {
      name: 'Cost',
      value: 'cost',
      function: this.equipmentCostFunction,
      sum: true,
    },
  ];

  materialColumns = [
    { name: 'Material', value: 'material' },
    {
      name: 'Description',
      value: 'description',
      fillValue: 'description',
    },
    {
      name: 'Unit Of Measure',
      value: 'unitOfMeasure',
      fillValue: 'unitOfMeasure',
    },
    {
      name: 'Unit Cost',
      value: 'unitCost',
      type: 'number',
      fillValue: 'unitCost',
    },
    { name: 'Quantity', value: 'quantity', type: 'number' },
    { name: 'PO', value: 'po' },
    {
      name: 'Cost',
      value: 'cost',
      function: this.defaultCostFunction,
      sum: true,
    },
  ];

  serviceColumns = [
    { name: 'Service Provider', value: 'serviceProvider' },
    { name: 'Date', value: 'date', type: 'date' },
    {
      name: 'Description',
      value: 'description',
      fillValue: 'description',
    },
    {
      name: 'Unit Of Measure',
      value: 'unitOfMeasure',
      fillValue: 'unitOfMeasure',
    },
    {
      name: 'Unit Cost',
      value: 'unitCost',
      type: 'number',
      fillValue: 'unitCost',
    },
    { name: 'Quantity', value: 'quantity', type: 'number' },
    { name: 'PO', value: 'po' },
    {
      name: 'Cost',
      value: 'cost',
      function: this.defaultCostFunction,
      sum: true,
    },
  ];

  /**
   * Return array [1...n] where n is number of pages
   * @returns {number[]}
   */
  get pages(): number[] {
    const lastIndex = 3;
    return [...Array(this.pageIndecies[lastIndex] + 1).keys()];
  }

  /**
   * Check if a table should be shown on a given page
   * @param {number} page page to check
   * @param {number} tableIndex index of table
   * @returns {boolean} True if value should be shown on this page
   */
  inRange(page: number, tableIndex: number): boolean {
    return this.pageIndecies[tableIndex] === page;
  }

  /**
   * @returns {number[]} list of page numbers for each value in inputValues
   */
  get pageIndecies(): number[] {
    return this.getContainerIndices(
      [
        (this.modelValue.timeAndMaterialsLabor as any[])?.length ?? 0,
        (this.modelValue.timeAndMaterialsEquipment as any[])?.length ?? 0,
        (this.modelValue.timeAndMaterialsMaterial as any[])?.length ?? 0,
        (this.modelValue.timeAndMaterialsService as any[])?.length ?? 0,
      ],
      this.itemsPerPage,
      this.padding,
    );
  }

  /**
   *
   * @param {number[]} inputValues list of table entry sizes
   * @param {number} maxContainerSize max size for each page
   * @param {number} padding padding between each inputValue value
   * @returns {number[]} list of page numbers for each value in inputValues
   */
  getContainerIndices(
    inputValues: number[],
    maxContainerSize: number,
    padding: number,
  ): number[] {
    return inputValues.reduce<{ containers: number[]; indices: number[] }>(
      (acc, val) => {
        const size = val + padding;
        const lastContainerIndex = acc.containers.length - 1;

        // First index is always in first container
        if (acc.containers.length === 0) {
          return {
            containers: [size],
            indices: [...acc.indices, 0],
          };
        }

        // Check if new table can fit in container
        if (acc.containers[lastContainerIndex] + size <= maxContainerSize) {
          const updatedContainers = [...acc.containers];
          updatedContainers[lastContainerIndex] += size;
          return {
            containers: updatedContainers,
            indices: [...acc.indices, lastContainerIndex],
          };
        }

        return {
          containers: [...acc.containers, size],
          indices: [...acc.indices, lastContainerIndex + 1],
        };
      },
      { containers: [], indices: [] },
    ).indices;
  }

  parseNumber(numStr: string): number {
    const num = Number.parseInt(numStr, 10);
    return Number.isNaN(num) ? 0 : num;
  }

  laborTimeFunction(data: any): number {
    return (
      this.parseNumber(data.otHours)
      + this.parseNumber(data.dtHours)
      + this.parseNumber(data.regularHours)
    );
  }

  laborCostFunction(data: any): number {
    // (OT Hours * OT Rate) + (DT Hours * DT Rate) + (Regular Hours * Regular Hour Rate)
    return (
      this.parseNumber(data.otHours) * data.overTimeRate
      + this.parseNumber(data.dtHours) * data.doubleTimeRate
      + this.parseNumber(data.regularHours) * data.hourlyRate
    );
  }

  equipmentCostFunction(data: any): number {
    // Regular Hours * Hourly Rate
    return this.parseNumber(data.regularHours) * data.hourlyRate;
  }

  defaultCostFunction(data: any): number {
    // Unit Cost * Quantity
    return this.parseNumber(data.quantity) * this.parseNumber(data.unitCost);
  }

  get operators(): OperatorResource[] {
    return this.projectResource?.operators ?? [];
  }

  get equipments(): EquipmentResource[] {
    return this.projectResource?.equipment ?? [];
  }

  get materials(): MaterialResource[] {
    return this.projectResource?.materials ?? [];
  }

  get services(): ServiceResource[] {
    return (
      this.projectResource?.services.map((service) => ({
        ...service,
        name: service.serviceProvider,
      })) ?? []
    );
  }
}
