export interface ProjectExportObject{
    projectGuid: string,
    assetGuids: string[],
    inspectionGuids: string[],
}

export interface OptionsObject {
    projectExportObjectList: ProjectExportObject[],
    jobOptions: {
        addresses: string[],
        videoFileNameScheme: string,
        pacp: boolean,
        macp: boolean,
        pdf: boolean,
        db: boolean,
        csv,
        videos: boolean,
        photos: boolean,
        ignoreValidation: boolean,
        ignoreValidationMessage: boolean
    }
}

// eslint-disable-next-line no-shadow
export enum TableMode {
    NORMAL = 0,
    EDIT = 1,
    EXPORT = 2,
    CREATE = 3,
}
