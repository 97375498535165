





































































































































































































































import {
  Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';
import { namespace } from 'vuex-class';
import {
  EquipmentResource,
  IProjectResource,
  MaterialResource,
  OperatorResource,
  ProjectResource,
  ServiceResource,
} from '@/store/resources/types';
import { ResourceActions } from '@/store/resources/actions';
import IntegrityDelete from '@/components/IntegrityDelete/IntegrityDelete.vue';
import FileUploadDialog from '@/components/MPS/Resources/FileUploadDialog.vue';
import OperatorEntryDialog from '@/components/MPS/Resources/OperatorEntryDialog.vue';
import EquipmentEntryDialog from '@/components/MPS/Resources/EquipmentEntryDialog.vue';
import MaterialEntryDialog from '@/components/MPS/Resources/MaterialEntryDialog.vue';
import ServiceEntryDialog from '@/components/MPS/Resources/ServiceEntryDialog.vue';
import { Currency } from './iMPS';

const resourcesModule = namespace('resources');

@Component({
  components: {
    IntegrityTable,
    IntegrityDelete,
    FileUploadDialog,
    OperatorEntryDialog,
    EquipmentEntryDialog,
    MaterialEntryDialog,
    ServiceEntryDialog,
  },
})
export default class MPSSectionResources extends Vue {
  @Prop() readonly canEditMPS: boolean;

  @Prop() readonly requiredField;

  @PropSync('valid') synchedValid;

  @PropSync('currency') synchedCurrency;

  @resourcesModule.Action(ResourceActions.DELETE_PROJECT_RESOURCE)
  deleteProjectResource;

  @resourcesModule.State('projectResource') projectResource:
    | ProjectResource
    | undefined;

  @resourcesModule.State('deleteResourceLoading')
  deleteResourceLoading: boolean;

  tabOptions = [
    { name: 'Operators', value: 'operators', button: 'Operator' },
    { name: 'Equipment', value: 'equipment', button: 'Equipment' },
    { name: 'Materials', value: 'materials', button: 'Material' },
    { name: 'Services', value: 'services', button: 'Service' },
  ];

  currencyOptions = [
    { code: 'USD', description: 'United States Dollar', symbol: '$' },
    { code: 'EUR', description: 'Euro', symbol: '€' },
    { code: 'JPY', description: 'Japanese Yen', symbol: '¥' },
    { code: 'GBP', description: 'British Pound Sterling', symbol: '£' },
    { code: 'AUD', description: 'Australian Dollar', symbol: 'A$' },
    { code: 'CAD', description: 'Canadian Dollar', symbol: 'C$' },
    { code: 'CNY', description: 'Chinese Yuan Renminbi', symbol: '¥' },
    { code: 'INR', description: 'Indian Rupee', symbol: '₹' },
  ] as Currency[];

  selectedCurrency = {
    code: 'USD',
    description: 'United States Dollar',
    symbol: '$',
  };

  activeTab = 0;

  fileUploadDialog = false as boolean;

  addResourceDialog = false as boolean;

  mode = 'Add' as string;

  model = null;

  getLaborHeaders(): any[] {
    return [
      {
        text: 'Crew Name',
        value: 'name',
        sortable: true,
        filterable: false,
      },
      {
        text: 'Email',
        value: 'email',
        sortable: true,
        filterable: false,
      },
      {
        text: `Regular Hourly Rate (${this.selectedCurrency.code})`,
        value: 'hourlyRate',
        align: 'right',
        sortable: true,
        filterable: false,
      },
      {
        text: `Overtime (OT) Hourly Rate (${this.selectedCurrency.code})`,
        value: 'overTimeRate',
        align: 'right',
        sortable: true,
        filterable: false,
      },
      {
        text: `Double Time (DT) Hourly Rate (${this.selectedCurrency.code})`,
        value: 'doubleTimeRate',
        align: 'right',
        sortable: true,
        filterable: false,
      },
      {
        text: 'Actions',
        value: 'actions',
        align: 'left',
        sortable: false,
        class: 'sticky-end',
        cellClass: 'sticky-end',
      },
    ];
  }

  getEquipmentHeaders(): any[] {
    return [
      {
        text: 'Equipment Name',
        value: 'name',
        sortable: true,
        filterable: false,
      },
      {
        text: 'Equipment Type',
        value: 'equipmentType',
        sortable: true,
        filterable: false,
        options: [],
      },
      {
        text: `Hourly Rate (${this.selectedCurrency.code})`,
        value: 'hourlyRate',
        align: 'right',
        sortable: true,
        filterable: false,
      },
      {
        text: 'Actions',
        value: 'actions',
        align: 'left',
        sortable: false,
        class: 'sticky-end',
        cellClass: 'sticky-end',
      },
    ];
  }

  getMaterialHeaders(): any[] {
    return [
      {
        text: 'Material Name',
        value: 'name',
        sortable: true,
        filterable: false,
      },
      {
        text: 'Description',
        value: 'description',
        sortable: true,
        filterable: false,
      },
      {
        text: 'Unit of Measure',
        value: 'unitOfMeasure',
        sortable: true,
        filterable: false,
        options: [],
      },
      {
        text: `Unit Cost (${this.selectedCurrency.code})`,
        value: 'unitCost',
        align: 'right',
        sortable: true,
        filterable: false,
      },
      {
        text: 'Actions',
        value: 'actions',
        align: 'left',
        sortable: false,
        class: 'sticky-end',
        cellClass: 'sticky-end',
      },
    ];
  }

  getServiceHeaders(): any[] {
    return [
      {
        text: 'Service Provider',
        value: 'serviceProvider',
        sortable: true,
        filterable: false,
      },
      {
        text: 'Description',
        value: 'description',
        sortable: true,
        filterable: false,
      },
      {
        text: 'Unit of Measure',
        value: 'unitOfMeasure',
        sortable: true,
        filterable: false,
        options: [],
      },
      {
        text: `Unit Cost (${this.selectedCurrency.code})`,
        value: 'unitCost',
        align: 'unitCost',
        sortable: true,
        filterable: false,
      },
      {
        text: 'Actions',
        value: 'actions',
        align: 'left',
        sortable: false,
        class: 'sticky-end',
        cellClass: 'sticky-end',
      },
    ];
  }

  getProjectResource(resource: string): any[] {
    if (!this.projectResource) {
      return [];
    }
    return this.projectResource[resource] ?? [];
  }

  getCurrentHeaders(): any[] {
    switch (this.activeTabValue) {
      case 'operators':
        return this.getLaborHeaders();
      case 'equipment':
        return this.getEquipmentHeaders();
      case 'materials':
        return this.getMaterialHeaders();
      case 'services':
        return this.getServiceHeaders();
      default:
        return [];
    }
  }

  get activeTabValue(): string {
    if (this.tabOptions.length === 0) return '';
    return this.tabOptions[this.activeTab].value;
  }

  get buttonText(): string {
    if (this.tabOptions.length === 0) return '';
    return this.tabOptions[this.activeTab].button;
  }

  get operatorData(): OperatorResource[] {
    return this.getProjectResource('operators');
  }

  get equipmentData(): EquipmentResource[] {
    return this.getProjectResource('equipment');
  }

  get materialsData(): MaterialResource[] {
    return this.getProjectResource('materials');
  }

  get servicesData(): ServiceResource[] {
    return this.getProjectResource('services');
  }

  get currentResourceData(): any[] {
    switch (this.activeTabValue) {
      case 'operators':
        return this.operatorData;
      case 'equipment':
        return this.equipmentData;
      case 'materials':
        return this.materialsData;
      case 'services':
        return this.servicesData;
      default:
        return [];
    }
  }

  @Watch('selectedCurrency')
  onSelectedCurrencyChange(): void {
    if (this.selectCurrency) {
      this.synchedCurrency = this.selectedCurrency;
    }
    this.getCurrentHeaders();
  }

  async deleteResource(item: IProjectResource): Promise<boolean> {
    try {
      const currentTabData = this.currentResourceData;
      const deleteIndex = currentTabData.findIndex(
        (value) => value.guid === item.guid,
      );

      if (deleteIndex === -1) {
        // If we can not find the item in the currentResourceData
        return false;
      }

      await this.deleteProjectResource(item.guid);
      currentTabData.splice(deleteIndex, 1);
    } catch (e) {
      return false;
    }
    return true;
  }

  handleEdit(guid: string): void {
    this.mode = 'Edit';
    this.addResourceDialog = true;
    this.model = this.currentResourceData.find((r) => r.guid === guid);
  }

  selectCurrency(item: Currency): void {
    this.selectedCurrency = item;
    this.$emit('currencyChange', item);
  }

  resetValidation(): void {
    (this.$refs.resourcesForm as any).reset();
  }

  handleAdd(resource: any): void {
    console.log('add', resource);
  }
}
