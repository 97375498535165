
















































/* eslint-disable @typescript-eslint/no-explicit-any */
import { TASK_TYPE_STRING, WORK_ORDER_STATUSES } from '@/common/Constants';
import { Component, VModel, Vue } from 'vue-property-decorator';
import ReportCalendarInput from '../ReportHelpers/ReportInputHelpers/ReportCalendarInput.vue';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import ReportAssignedToDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportAssignedToDropDownInput.vue';

@Component({
  components: {
    ReportTextInput,
    ReportDropDownInput,
    ReportCalendarInput,
    ReportAssignedToDropDownInput,
  },
})
export default class PipeReliningReportHeader extends Vue {
  @VModel() modelValue!: any | undefined;

  get workOrderType(): string {
    return this.modelValue?.workOrderNumber != null
      ? this.modelValue.workOrderNumber.split('-')[0]
      : '';
  }

  get workOrderStatusOptions(): string[] {
    return WORK_ORDER_STATUSES.map((x) => x.description);
  }

  get workOrderTaskTypeName(): string {
    return (
      TASK_TYPE_STRING.find((x) => x.guid === this.modelValue.taskTypeGuid)
        ?.desc ?? ''
    );
  }
}
