
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const reportModule = namespace('report');

export default class ReportInputMixin extends Vue {
  @Prop({ default: false, type: Boolean }) ignoreEditMode: boolean;

  @Prop({ default: true }) hasPlanPermission: boolean;

  /**
  * Determine if the form should be allowed to edit
  */
  get allowEditing(): boolean {
    if (this.isExporting) {
      return false;
    }
    if (this.hasPlanPermission) {
      return this.ignoreEditMode || this.isEditMode;
    }
    return !this.ignoreEditMode && this.isEditMode;
  }

  @reportModule.State('isEditMode') isEditMode: boolean;

  @reportModule.State('isExporting') isExporting: boolean;
}
