











import {
  IProjectResource,
  ResourceType,
  OperatorResource,
} from '@/store/resources/types';
import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';
import ManualEntryDialog from './ManualEntryDialog.vue';

@Component({
  components: {
    ManualEntryDialog,
  },
})
export default class OperatorEntryDialog extends Vue {
  @Prop() readonly mode: string;

  @PropSync('model') resource: IProjectResource;

  @PropSync('addResourceDialog') showDialog: boolean;

  operatorFields = [
    {
      label: 'Full Name', value: 'name', required: true, type: 'text',
    },
    {
      label: 'Email', value: 'email', required: true, type: 'text',
    },
    {
      label: 'Regular Hourly Rate', value: 'hourlyRate', required: false, type: 'number',
    },
    {
      label: 'Overtime Hourly Rate', value: 'overTimeRate', required: false, type: 'number',
    },
    {
      label: 'Double Time Hourly Rate', value: 'doubleTimeRate', required: false, type: 'number',
    },
  ]

  newOperator = {
    userGuid: '',
    name: '',
    email: '',
    hourlyRate: null,
    overTimeRate: null,
    doubleTimeRate: null,
  } as OperatorResource;

  get operatorType(): string {
    return ResourceType.operators;
  }
}
