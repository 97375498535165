
























































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import {
  Component, Prop, PropSync, Watch,
} from 'vue-property-decorator';
import { DetailedUserData, ManagedUsersTableData } from '@/store/users/types';
import {
  canUserManageRole, getRoleIdByGuid, getRoleString,
} from '@/auth/roles';
import AdminPanelMixin, { EditUserData } from '../AdminPanelMixin.vue';
import CustomerDialogMultiSelectCustomer from '../Customers/CustomerDialogMultiSelectCustomer.vue';

@Component({
  components: {
    CustomerDialogMultiSelectCustomer,
  },
})
export default class AddManagedUserButton extends AdminPanelMixin {
  @Prop() readonly selectedUser: EditUserData;

  @Prop() readonly selectedUserGuid: string;

  @Prop() readonly allAuthUserRoles: any[];

  @PropSync('managedUsers') syncedManagedUsers: ManagedUsersTableData[];

  @PropSync('managedUsersForAddition') synchedManagedUsersForAddition: DetailedUserData[];

  valid = false;

  addManagedUserDialog = false;

  usersToAdd = [] as DetailedUserData[];

  get managedUserOptions(): DetailedUserData[] {
    return this.allUsers.filter(
      (user) => !this.syncedManagedUsers.find((mu) => mu.guid === user.guid)
      && this.hasCommonCustomers([user, this.selectedUser.detailedUserData])
      && canUserManageRole(
        this.selectedUserGuid,
        getRoleString(
          this.allAuthUserRoles.find((userRole) => userRole.userId === user.useridentity)?.role,
        ),
      ),
    );
  }

  @Watch('addManagedUserDialog')
  onAddManagedUserDialogChange(): void {
    if (!this.addManagedUserDialog) {
      this.usersToAdd = [];
    }
  }

  getCommonCustomers(users: DetailedUserData[]): string[] {
    // get selected user customer guids as arrays
    const selectedUserCustomers = users.map(
      (user) => user.customerGuids,
    );

    // filter selected user customer guids down to ones that exist in all arrays
    // this should result in an array of customer guids common to all selected users
    // if there are no common customers between all selected users, commonCustomer would be empty
    const commonCustomer: string[] = selectedUserCustomers
      .shift()
      .filter((c) => selectedUserCustomers.every((uc) => uc.indexOf(c) !== -1));

    return commonCustomer;
  }

  hasCommonCustomers(users: DetailedUserData[]): boolean {
    return this.getCommonCustomers(users).length > 0;
  }

  getFullName(user: DetailedUserData): string {
    return `${user.firstname} ${user.lastname}`;
  }

  saveAddManagedUser(): void {
    const usersToAddTableData: ManagedUsersTableData[] = this.usersToAdd.map((user) => ({
      guid: user.guid,
      userManagementGuid: '',
      user: this.getFullName(user),
      email: user.email,
      role: user.role.map((role) => getRoleIdByGuid(role.roleGuid)),
      organization: this.organizationDataList.find(
        (o) => o.guid === this.selectedUser.detailedUserData.organizationGuids[0],
      ).name,
      customers: this.customerDataList.find(
        (c) => c.guid === this.getCommonCustomers([this.selectedUser.detailedUserData, user])[0],
      ).name,
    }));

    this.synchedManagedUsersForAddition.push(...this.usersToAdd);
    this.syncedManagedUsers.push(...usersToAddTableData);
    this.addManagedUserDialog = false;
  }
}
