


















































































































































































































































/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import {
  Component, Prop, PropSync, Watch,
} from 'vue-property-decorator';
import {
  WORK_ORDER_STATUS_OPEN,
  NODEITEM_LINESEGMENT_GUID,
  NODEITEM_MANHOLE_GUID,
  PRIORITY_COLOR_LOOKUP, PRIORITY_LOW, TASK_TYPE_STRING,
} from '@/common/Constants';
import { WorkOrderTableData } from '@/store/planning/types';
import IntegrityTable, { AdditionalFilterFunction, FillFunctionContainer, FilterFunction, processDate, processDateWithTableObject } from '@/components/IntegrityTable/IntegrityTable.vue';
import { namespace } from 'vuex-class';
import { AssignmentActions } from '@/store/assignments/actions';
import { UserData } from '@/store/users/types';
import { UserPermission } from '@/store/userpermissions/types';
import { RoutingActions } from '@/store/routing/actions';
import { RoutingData } from '@/store/routing/types';
import { ProjectHeaders } from '@/store/project/types';
import { AuthUserRoles } from '@/auth/roles';
import { uuid } from 'vue-uuid';
import { PlanningActions } from '@/store/planning/actions';
import { ResourceActions } from '@/store/resources/actions';
import WorkOrderForm from './WorkOrderForm.vue';
import IntegrityDelete from '../IntegrityDelete/IntegrityDelete.vue';
import PlanningSchedule from './PlanningSchedule.vue';
import UserPermissionsMixin from '../UserPermissions/UserPermissionsMixin.vue';
import { AssignmentTaskType, commonTaskTypes, macpTaskTypes, pacpTaskTypes } from '../ScopeNavigation/NewAssignmentDialog/Constants';
import { DataTableHeader } from '../AssetTable/types';

const assignmentsModule = namespace('assignments');
const userModule = namespace('users');
const routingModule = namespace('routing');
const planningModule = namespace('planning');
const resourcesModule = namespace('resources');

export interface TaskTypeDisplay
{
  displayName: string,
  name: string,
}

@Component({
  components: {
    WorkOrderForm,
    PlanningSchedule,
    IntegrityTable,
    IntegrityDelete,
  },
})
export default class PlanningTable extends UserPermissionsMixin {
  @planningModule.Action(PlanningActions.FETCH_WORK_ORDER_CREW) fetchWorkOrderCrew;

  @assignmentsModule.State('followUpLoading') followUpLoading;

  @assignmentsModule.State('followUpError') followUpError;

  @assignmentsModule.State('followUpWorkOrder') followUpWorkOrder;

  @assignmentsModule.Action(AssignmentActions.POST_FOLLOW_UP_WORK_ORDER) postFollowUpWorkOrder;

  @userModule.State('allUserData') allUsers: UserData[] | undefined;

  @routingModule.Action(RoutingActions.FETCH_ROUTING_DATA) fetchRoutingData;

  @routingModule.State('routingData') routingData: RoutingData[] | undefined;

  @routingModule.State('routingLoading') routingLoading: boolean;

  @resourcesModule.Action(ResourceActions.FETCH_PROJECT_RESOURCE)
  fetchProjectResource;

  @Prop() project: ProjectHeaders;

  @Prop() readonly analyticsData: WorkOrderTableData[];

  @Prop() readonly loadingAnalyticsData: boolean;

  @Prop() readonly selectedCategoryLabel: string;

  @Prop() readonly deleteLoading: boolean;

  @Prop() deleteAssignment;

  @Prop() categoryFilterFunction: any;

  @Prop() addonAdditionaFilterFunctions: FilterFunction[];

  @PropSync('selectedItems') synchedSelectedItems: WorkOrderTableData[];

  @PropSync('selectedAssetType') activeTab: number;

  dateFrom: string | undefined = undefined;

  dateTo: string | undefined = undefined;

  routeFilters = [];

  search = '';

  page = 1;

  pageCount = 0;

  selected = [];

  priorityColors = {};

  editDialog = false;

  isMACPLevel1 = true;

  editWorkOrderDialog = false;

  currentItem = null;

  canEdit = false;

  invalidSelectedForEditDialog = false;

  hasAppliedRouteParams = false;

  tableRules = [
    'td { height: 50px !important;}'];

  assetTypeOptions = [
    { name: 'Manhole', value: 'manhole' },
    { name: 'Line Segment', value: 'lineSegment' },
  ];

  manholeHeaders: DataTableHeader[] = [
    { text: 'Work Order Number', value: 'workOrderNumber', align: 'start', filterable: true },
    { text: 'Asset Name', value: 'nodeName', align: 'start', filterable: true },
    { text: 'Map Page', value: 'mapPage', align: 'start', filterable: true },
    { text: 'Status', value: 'status', align: 'start', filterable: true },
    { text: 'Task Type', value: 'taskTypeDescription', align: 'start', filterable: true },
    { text: 'Task Result', value: 'taskResultDesc', align: 'start', filterable: true },
    { text: 'Parent Work Order', value: 'followUpRef', align: 'start', filterable: true },
    { text: 'Start Date', value: 'scheduledStartDate', align: 'start', filterable: true },
    { text: 'End Date', value: 'scheduledDueDate', align: 'start', filterable: true },
    { text: 'Completed Date', value: 'completeDate', align: 'start', filterable: true },
    { text: 'Scheduling', value: 'schedulingDataString', align: 'start', filterable: true, stopFilterValueGeneration: true },
    { text: 'Priority', value: 'priorityDescription', align: 'start', filterable: true },
    { text: 'Route', value: 'routeName', align: 'start', filterable: true },
    { text: 'Crew', value: 'crewLeadName', align: 'start', filterable: true },
    { text: 'Basin', value: 'basin', align: 'start', filterable: true },
    { text: 'Batch Number', value: 'batchNumber', align: 'start', filterable: true },
    { text: 'Actions', value: 'actions', align: 'start', sortable: false, class: 'sticky-end', cellClass: 'sticky-end', filterable: true, stopFilterValueGeneration: true },
  ];

  pipeHeaders: DataTableHeader[] = [
    { text: 'Work Order Number', value: 'workOrderNumber', align: 'start', filterable: true },
    { text: 'Asset Name', value: 'nodeName', align: 'start', filterable: true },
    { text: 'Upstream MH', value: 'wastewaterStructureUp', align: 'start', filterable: true },
    { text: 'Downstream MH', value: 'wastewaterStructureDn', align: 'start', filterable: true },
    { text: 'Map Page', value: 'mapPage', align: 'start', filterable: true },
    { text: 'Pipe Size', value: 'pipeSize', align: 'start', filterable: true },
    { text: 'Length', value: 'lengthGIS', align: 'start', filterable: true },
    { text: 'Status', value: 'status', align: 'start', filterable: true },
    { text: 'Task Type', value: 'taskTypeDescription', align: 'start', filterable: true },
    { text: 'Task Result', value: 'taskResultDesc', align: 'start', filterable: true },
    { text: 'Parent Work Order', value: 'followUpRef', align: 'start', filterable: true },
    { text: 'Start Date', value: 'scheduledStartDate', align: 'start', filterable: true },
    { text: 'End Date', value: 'scheduledDueDate', align: 'start', filterable: true },
    { text: 'Completed Date', value: 'completeDate', align: 'start', filterable: true },
    { text: 'Scheduling', value: 'schedulingDataString', align: 'start', filterable: true, stopFilterValueGeneration: true },
    { text: 'Priority', value: 'priorityDescription', align: 'start', filterable: true },
    { text: 'Route', value: 'routeName', align: 'start', filterable: true },
    { text: 'Crew', value: 'crewLeadName', align: 'start', filterable: true },
    { text: 'Basin', value: 'basin', align: 'start', filterable: true },
    { text: 'Batch Number', value: 'batchNumber', align: 'start', filterable: true },
    { text: 'Actions', value: 'actions', align: 'start', sortable: false, class: 'sticky-end', cellClass: 'sticky-end', filterable: true, stopFilterValueGeneration: true },
  ];

  matchManholeFilters = [
    { header: 'workOrderNumber', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'nodeName', type: 'string', value: '', method: '', options: [], tempValue: '', open: false, conversionFunction: this.nodeNameString },
    { header: 'mapPage', type: 'string', value: '', method: '', options: [], tempValue: '', open: false, conversionFunction: this.mapPageString },
    { header: 'status', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'taskTypeDescription', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'taskResultDesc', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'followUpRef', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'scheduledStartDate', type: 'date', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'scheduledDueDate', type: 'date', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'schedulingDataString', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'completeDate', type: 'date', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'priorityDescription', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'routeName', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'crewLeadName', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'basin', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'batchNumber', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'actions', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
  ]

  matchPipeFilters = [
    { header: 'workOrderNumber', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'nodeName', type: 'string', value: '', method: '', options: [], tempValue: '', open: false, conversionFunction: this.nodeNameString },
    { header: 'wastewaterStructureUp', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'wastewaterStructureDn', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'mapPage', type: 'string', value: '', method: '', options: [], tempValue: '', open: false, conversionFunction: this.mapPageString },
    { header: 'pipeSize', type: 'number', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'lengthGIS', type: 'number', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'status', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'taskTypeDescription', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'taskResultDesc', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'followUpRef', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'scheduledStartDate', type: 'date', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'scheduledDueDate', type: 'date', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'schedulingDataString', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'completeDate', type: 'date', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'priorityDescription', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'routeName', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'crewLeadName', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'basin', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'batchNumber', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'actions', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
  ]

  editItems = [];

  tableHeight: string | number = 'auto';

  today = new Date().toISOString();

  displayHeaders: DataTableHeader[][] = [
    this.manholeHeaders,
    this.pipeHeaders,
  ];

  displayMatchFilters: any[] = [
    this.matchManholeFilters,
    this.matchPipeFilters,
  ]

  tabOptions = [
    'Manhole',
    'Line Segment',
  ]

  completedInitialLoad: boolean = null;

  ignoreQuery = false;

  followUpDialog = false;

  workOrderToFollowUp: WorkOrderTableData = null;

  selectedFollowUpTaskType = '';

  filterAssigned = false;

  filteredWorkOrders: WorkOrderTableData[] = [];

  overrideFlteredWorkOrders: WorkOrderTableData[] = [];

  get invalidSelectedForEdit(): boolean {
    if (this.synchedSelectedItems.length === 0) {
      return false;
    }

    const firstNode = this.synchedSelectedItems[0];
    return !this.synchedSelectedItems.every((node) => (
      node.scheduledStartDate === firstNode.scheduledStartDate
      && node.scheduledDueDate === firstNode.scheduledDueDate
    ));
  }

  get additionalFilterFunctions(): AdditionalFilterFunction {
    let filterFunctions: FilterFunction[] = [
      {
        functionVariables: [],
        filterFunction: this.categoryFilterFunction,
      },
      {
        functionVariables: [
          [NODEITEM_MANHOLE_GUID, NODEITEM_LINESEGMENT_GUID],
          this.activeTab.toString(),
        ],
        filterFunction:
          function isAssetType(item, typeGuids, activeTab) {
            return item.nodeTypeGuid === typeGuids[activeTab];
          }
        ,
      },
    ];
    if (this.dateFrom != null) {
      filterFunctions.push({
        functionVariables: [this.dateFrom],
        filterFunction: function isDateAfter(item, date) {
          if (item?.scheduledStartDate == null) {
            return false;
          }
          return new Date(item?.scheduledStartDate) >= new Date(date);
        },
      });
    }
    if (this.dateTo != null) {
      filterFunctions.push({
        functionVariables: [this.dateTo],
        filterFunction: function isDateBefore(item, date) {
          if (item?.scheduledDueDate == null) {
            return false;
          }
          return new Date(item?.scheduledDueDate) <= new Date(date);
        },
      });
    }
    if (this.addonAdditionaFilterFunctions != null) {
      filterFunctions = filterFunctions.concat(this.addonAdditionaFilterFunctions);
    }
    this.filterAssigned = false;
    return {
      updateKey: uuid.v4(),
      filterFunctions,
    };
  }

  get featureHeaders(): DataTableHeader[] {
    return this.displayHeaders[this.activeTab];
  }

  get matchFilters(): any[] {
    return this.displayMatchFilters[this.activeTab];
  }

  get taskTypes(): TaskTypeDisplay[] {
    const currentAssetTaskTypes = this.activeTab === 0
      ? macpTaskTypes : pacpTaskTypes;
    const mappedAssetTaskTypes = currentAssetTaskTypes
      .map((x) => this.createTaskTypeDisplay(x)).flat();
    const mappedCommonTaskTypes = commonTaskTypes.map((x) => this.createTaskTypeDisplay(x)).flat();
    const currentTaskTypes = mappedAssetTaskTypes.concat(mappedCommonTaskTypes);
    const typeTaskDesc = TASK_TYPE_STRING.map((tts) => tts.desc);
    return currentTaskTypes.filter((tt) => typeTaskDesc.includes(tt.name));
  }

  get fillTableDataFunctions(): FillFunctionContainer {
    const returnValue: FillFunctionContainer = {
      updateKey: uuid.v4(),
      fillFunctions: [
        {
          headerValue: 'schedulingDataString',
          functionVariables: [],
          fillFunction: function fillSchedulingString(i) {
            if (i?.schedulingData === null) {
              return '';
            }
            try {
              const parsedData = JSON.parse(i?.schedulingData);
              return `Every ${parsedData.Interval} ${parsedData.Period} starting on ${parsedData.StartDate.substring(0, 10)}`;
            } catch {
              return '';
            }
          },
        },
        {
          headerValue: 'taskTypeDescription',
          functionVariables: [TASK_TYPE_STRING],
          fillFunction: function fillTaskType(i, taskTypes) {
            // eslint-disable-next-line eqeqeq
            const task = taskTypes.find((taskType) => taskType.guid == i.taskTypeGuid);
            return task?.desc;
          },
        },
        {
          headerValue: 'scheduledStartDate',
          functionVariables: ['scheduledStartDate'],
          fillFunction: processDateWithTableObject,
        },
        {
          headerValue: 'scheduledDueDate',
          functionVariables: ['scheduledDueDate'],
          fillFunction: processDateWithTableObject,
        },
        {
          headerValue: 'completeDate',
          functionVariables: ['completeDate'],
          fillFunction: processDateWithTableObject,
        },
      ],
    };
    if (this.routingData) {
      returnValue.fillFunctions.push(
        {
          headerValue: 'routeName',
          functionVariables: [this.routingData],
          fillFunction: function fillRouteNames(i, routingData) {
            const route = routingData.find((r) => r.guid === i.routeGuid);
            return route?.routeName;
          },
        },
      );
    }
    if (this.allUsers) {
      returnValue.fillFunctions.push(
        {
          headerValue: 'crewLeadName',
          functionVariables: [this.getUserNamesObjects()],
          fillFunction: function fillCrewLeadNames(i, usersNames) {
            let crewLeadName;
            if (i.crewLeadGuid) {
              crewLeadName = usersNames[i.crewLeadGuid]?.name;
            }
            return crewLeadName;
          },
        },
      );
    }
    return returnValue;
  }

  /**
  * @returns true if the user has the permission WORK_ORDER_RESET_PLANNING
  */
  get hasPermissionWorkOrderResetPlanning(): boolean {
    return this.hasPermission(UserPermission.WORK_ORDER_RESET_PLANNING);
  }

  /**
  * @returns true if the user has the permission WORK_ORDER_DELETE
  */
  get hasPermissionWorkOrderDelete(): boolean {
    return this.hasPermission(UserPermission.WORK_ORDER_DELETE);
  }

  @Watch('analyticsData', { deep: true })
  setFilteredWorkOrders(value: WorkOrderTableData[]): void {
    if (!this.ignoreQuery) this.getRouteParams();
    if (!value) {
      this.filteredWorkOrders = undefined;
    } else {
      this.filteredWorkOrders = this.analyticsData;
    }
    this.filterAssigned = false;
  }

  @Watch('loadingAnalyticsData')
  async onLoadingAnalyticsData(): Promise<void> {
    // initial load
    if (this.loadingAnalyticsData && this.completedInitialLoad === null) {
      this.completedInitialLoad = false;
      this.setFilteredWorkOrders(this.analyticsData);
      return;
    }
    if (!this.loadingAnalyticsData && this.completedInitialLoad === false) {
      this.setFilteredWorkOrders(this.analyticsData);
      // await this next tick to avoid flashing the loading symbol
      await this.$nextTick();
      this.completedInitialLoad = true;
    }
  }

  @Watch('filterAssigned')
  async filterAssignedToMe(): Promise<void> {
    const crewMatchFilter = this.matchFilters.find((match) => match.header === 'crewLeadName');
    if (this.filterAssigned) {
      const currentUser = await this.$auth.getUser();
      const currentUserData = this.allUsers.find((u) => u.email === currentUser.email);
      const currentUserName = `${currentUserData.firstname} ${currentUserData.lastname}`;
      crewMatchFilter.options = [currentUserName];
    } else {
      crewMatchFilter.options = [];
    }
  }

  @Watch('routeFilters')
  foundRouteFilters(): void {
    this.routeFilters.forEach((filter) => {
      let foundHeader = this.matchFilters.find((mf) => mf.header === filter.header);
      if (filter.header === 'assetName') {
        foundHeader = this.matchFilters.find((mf) => mf.header === 'nodeName');
      }
      if (filter.header === 'taskType') {
        foundHeader = this.matchFilters.find((mf) => mf.header === 'taskTypeDescription');
      }
      if (filter.header === 'issue') {
        foundHeader = this.matchFilters.find((mf) => mf.header === 'taskResultDesc');
      }
      if (foundHeader) {
        foundHeader.options = filter.options;
        foundHeader.value = filter.value;
        foundHeader.method = filter.method;
      }
    });
  }

  async mounted(): Promise<void> {
    this.completedInitialLoad = true;
    if (this.$auth.user.id !== null) {
      const PROJECT_MANAGER = AuthUserRoles.find((role) => role.name === 'Project Manager').id;
      const SUPER_USER = AuthUserRoles.find((role) => role.name === 'Super User').id;
      const roles = await this.$auth.getRoles(`auth0|${this.$auth.user.id}`);
      const isPM = roles.includes(PROJECT_MANAGER) || roles.includes(SUPER_USER);
      if (!isPM) {
        this.manholeHeaders = this.manholeHeaders.filter((header) => header.value !== 'crewLeadName');
        this.pipeHeaders = this.pipeHeaders.filter((header) => header.value !== 'crewLeadName');
      }
      this.setFilteredWorkOrders(this.analyticsData);
      this.fetchProjectResource(this.project.guids[0]);
    }
  }

  getUserNamesObjects(): any[] {
    return Object.fromEntries(this.allUsers
      .map((user) => [user.guid, { name: `${user.firstname} ${user.lastname}`, role: user.role }])
      .filter((x) => x));
  }

  getTableData(): any[] {
    if (this.overrideFlteredWorkOrders != null && this.overrideFlteredWorkOrders.length > 0) {
      return this.overrideFlteredWorkOrders;
    }
    return this.filteredWorkOrders;
  }

  createTaskTypeDisplay(taskType: AssignmentTaskType): TaskTypeDisplay | TaskTypeDisplay[] {
    if (taskType.subValue) {
      // concatenate the subvalue with the main value to make it more readable
      return taskType.subValue
        .map((sub) => ({ displayName: `${taskType.name} ${sub.name}`, name: sub.name }));
    }
    return { displayName: taskType.name, name: taskType.name };
  }

  getRouteParams(): void {
    const route = this.$route;
    if (Object.keys(route.query).length > 0) {
      const queries = Object.entries(route.query);
      const filterHeaders = this.matchFilters.map((mf) => mf.header);
      queries.forEach((pair) => {
        const [key, value] = pair;

        if (key === 'name') {
          if (Array.isArray(value)) {
            this.matchFilters.find((mf) => mf.header === 'nodeName').options.push(...value);
          } else {
            this.matchFilters.find((mf) => mf.header === 'nodeName').options.push(value);
          }
        } else if (filterHeaders.includes(key)) {
          this.matchFilters.find((mf) => mf.header === key).options.push(value);
        } else if (key === 'matchFilters') {
          const updatedValue = decodeURI(value as string);
          this.routeFilters = JSON.parse(updatedValue);
        } else if (key === 'guid') {
          const foundWorkOrder = this.analyticsData.filter((ad) => ad.guid === value);
          if (foundWorkOrder.length > 0) {
            const foundMatchFilter = this.matchFilters.find((mf) => mf.header === 'nodeName');
            foundMatchFilter.options = foundMatchFilter.options.concat(foundWorkOrder);
          }
        } else if (key === 'taskResult') {
          const taskdec: string[] = JSON.parse((decodeURI(value as string)));

          const notempty = taskdec.filter((td) => td !== '');

          const foundFilter = this.matchFilters.find((mf) => mf.header === 'taskResultDesc');

          foundFilter.options = foundFilter.options.concat(notempty);
        } else if (key === 'assetType' && !this.hasAppliedRouteParams) {
          if (value === 'LineSegments') {
            this.activeTab = 1;
          } else {
            this.activeTab = 0;
          }
        } else if (key === 'dateBefore') {
          this.dateFrom = processDate(value as string) as string;
        } else if (key === 'dateAfter') {
          this.dateTo = processDate(value as string) as string;
        }
      });
    }
    this.hasAppliedRouteParams = true;
  }

  getDataStandard(item: WorkOrderTableData): void {
    this.currentItem = {
      ...item,
      routeName: this.getRouteNameFromGuid(item.routeGuid),
    };
    this.editWorkOrderDialog = true;
  }

  getPriorityColor(priorityGUID: string): string {
    return PRIORITY_COLOR_LOOKUP[priorityGUID];
  }

  async removeSelectedAssignmentItems(): Promise<void> {
    await this.removeAssignmentItem(this.synchedSelectedItems.map((value) => value.guid), true);
  }

  async removeAssignmentItem(items: string | string[], isArray = false): Promise<void> {
    let itemsToUse: string[] = [];
    if (!isArray) {
      itemsToUse = [items as string];
    } else {
      itemsToUse = items as string[];
    }
    const deleteItemGuids = itemsToUse;
    await this.deleteAssignment(deleteItemGuids);
    this.$emit('removeAssignment', deleteItemGuids);
  }

  async resetItems(items: WorkOrderTableData[]): Promise<void> {
    this.editDialog = false;
    this.completedInitialLoad = false;

    const dataList: any[] = [];

    items.forEach((node: any) => {
      const data = {
        guid: node.guid,
        project_guid: node.projectGuid,
        priority_item: {},
        scheduled_start_date: null,
        scheduled_end_date: null,
        notes: '',
        scheduled_start_date_null: true,
        scheduled_end_date_null: true,
        crewLead: [],
        crew: [],
        status_item: { guid: WORK_ORDER_STATUS_OPEN },
      };

      const priority = {
        guid: PRIORITY_LOW,
        category: {
          guid: 'cf12207e-9707-11ea-9cfe-6f062ca617ff',
          name: 'Priority',
        },
        description: 'Low',
        short_name: 'LOW',
        order: 1,
      };

      data.priority_item = priority;
      dataList.push(data);
    });

    this.canEdit = false;
    this.$emit('reset', {
      emails: [],
      requestList: dataList,
    });
  }

  editAssignments(): void {
    if (this.invalidSelectedForEdit) {
      this.invalidSelectedForEditDialog = true;
      return;
    }
    this.canEdit = !this.canEdit;
    this.editItems = this.synchedSelectedItems;
    this.fetchWorkOrderCrew(this.editItems.map((value) => value.guid)).then(() => {
      this.editDialog = true;
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  editAssignmentItem(editAssignment: any, shouldEditMACPLevels: boolean): void {
    this.$emit('editAssignmentItem', editAssignment, shouldEditMACPLevels);
  }

  editWorkOrderPass(): void {
    this.editWorkOrderDialog = false;
    this.$emit('editWorkOrderPass');
  }

  closeWorkOrder(): void {
    this.editWorkOrderDialog = false;
  }

  handleEdit(itemGuid: string): void {
    this.editItems = [this.analyticsData.find(
      (item) => item['guid'] === itemGuid,
    )];
    this.fetchWorkOrderCrew(this.editItems.map((value) => value.guid)).then(() => {
      this.editDialog = true;
    });
  }

  handleInfo(itemGuid: string): void {
    const infoItem = this.analyticsData.find(
      (item) => item['guid'] === itemGuid,
    );
    this.getDataStandard(infoItem);
  }

  handleAdd(itemGuid: string): void {
    this.workOrderToFollowUp = this.analyticsData.find((wo) => wo.guid === itemGuid);
    this.selectedFollowUpTaskType = '';
    this.followUpDialog = true;
  }

  clearAllFilters(clearQuery = false): void {
    (this.$refs.workOrderTable as IntegrityTable).clearAllMatchFilters();
    if (clearQuery) {
      this.ignoreQuery = true;
    }

    this.dateFrom = undefined;
    this.dateTo = undefined;
  }

  resetEdit(): void {
    this.canEdit = false;
    this.synchedSelectedItems = [];
  }

  async submit(data: unknown): Promise<void> {
    this.editDialog = false;
    this.$emit('submit', data);
  }

  cancel(): void{
    this.editDialog = false;
    this.canEdit = false;
    this.$emit('cancel');
  }

  getRouteColor(routeGuid: string): string | undefined {
    this.checkRouteData();
    if (this.routingData == null) {
      return '#FFFFFFFF';
    }
    const hexReg = /^#([0-9a-f]{3,4}){1,2}$/i;
    const foundValue = this.routingData.find((r) => r.guid === routeGuid);
    if (routeGuid == null || foundValue == null) {
      return '#FFFFFFFF';
    }
    const routeColor = foundValue.color;
    if (hexReg.test(routeColor)) {
      return routeColor;
    } if (hexReg.test(`#${routeColor}`)) {
      return `#${routeColor}`;
    }
    return null;
  }

  getTextColor(textBackgroundColor: string): string {
    const color = (textBackgroundColor.charAt(0) === '#') ? textBackgroundColor.substring(1, 7) : textBackgroundColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186)
      ? '#000000' : '#FFFFFF';
  }

  checkRouteData(): void {
    if (!this.routingLoading
    && this.routingData == null
    && this.project?.guids != null
    && this.project.guids.length > 0) {
      this.fetchRoutingData(this.project.guids[0]);
    }
  }

  getRouteNameFromGuid(routeGuid: string): string {
    this.checkRouteData();
    if (this.routingData == null || this.routingData.length === 0) {
      return '';
    }
    const foundValue = this.routingData.find((r) => r.guid === routeGuid);
    return foundValue ? foundValue.routeName : '';
  }

  routeString(item: WorkOrderTableData): string {
    const { routeGuid } = item;
    return this.getRouteNameFromGuid(routeGuid);
  }

  mapPageString(item: WorkOrderTableData): string {
    const { mapPage } = item;
    if (mapPage == null || mapPage === '') {
      return '';
    }
    return mapPage;
  }

  taskTypeString(item: WorkOrderTableData): string {
    const { taskTypeGuid } = item;
    if (taskTypeGuid == null) {
      return '';
    }
    const foundValue = TASK_TYPE_STRING.find((tts) => tts.guid === taskTypeGuid);
    if (foundValue == null) {
      return '';
    }
    return foundValue.desc;
  }

  nodeNameString(item: WorkOrderTableData): string {
    const { nodeName } = item;
    if (nodeName == null) {
      return '';
    }
    return nodeName;
  }

  async submitFollowUp(): Promise<void> {
    const taskTypeGuid = TASK_TYPE_STRING.find((tts) => tts.desc === this.selectedFollowUpTaskType);
    const followUpPayload = {
      parentWorkOrderGuid: this.workOrderToFollowUp.guid,
      taskTypeGuid: taskTypeGuid.guid,
    };
    await this.postFollowUpWorkOrder(followUpPayload);

    const refreshMsg = {
      followUpWorkOrder: this.followUpWorkOrder,
      isSuccessful: !this.followUpError,
    };

    if (refreshMsg.isSuccessful) {
      this.completedInitialLoad = false;
    }

    await this.$emit('refreshFollowUpWorkOrder', refreshMsg);
    this.closeFollowUpDialog();
  }

  updateTable(): void {
    this.clearAllFilters();
    if (!this.ignoreQuery) this.getRouteParams();
    this.setFilteredWorkOrders(this.analyticsData);
  }

  closeFollowUpDialog(): void {
    this.followUpDialog = false;
  }
}
