











import {
  IProjectResource,
  ResourceType,
  MaterialResource,
} from '@/store/resources/types';
import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';
import ManualEntryDialog from './ManualEntryDialog.vue';

@Component({
  components: {
    ManualEntryDialog,
  },
})
export default class MaterialEntryDialog extends Vue {
  @Prop() readonly mode: string;

  @PropSync('model') resource: IProjectResource;

  @PropSync('addResourceDialog') showDialog: boolean;

  materialUnitOptions = ['Foot', 'Gallon', 'Item', 'Pound'];

  materialFields = [
    {
      label: 'Material Name', value: 'name', required: true, type: 'text',
    },
    {
      label: 'Description', value: 'description', required: false, type: 'multiline',
    },
    {
      label: 'Unit of Measure',
      value: 'unitOfMeasure',
      required: false,
      type: 'combo',
      options: this.materialUnitOptions,
    },
    {
      label: 'Unit Cost', value: 'unitCost', required: false, type: 'number',
    },
  ]

  newMaterial = {
    name: '',
    description: '',
    unitOfMeasure: '',
    unitCost: null,
  } as MaterialResource;

  get materialType(): string {
    return ResourceType.materials;
  }
}
