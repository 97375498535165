// eslint-disable-next-line no-shadow
export enum ResourceType {
    operators = 'operators',
    equipment = 'equipment',
    materials = 'materials',
    services = 'services',
}

export interface IProjectResource {
    guid: string,
}

export interface OperatorResource extends IProjectResource {
    userGuid: string,
    name: string,
    email: string,
    hourlyRate?: number,
    overTimeRate?: number,
    doubleTimeRate?: number,
}

export interface EquipmentResource extends IProjectResource {
    name: string,
    equipmentType?: string,
    hourlyRate?: number,
}

export interface MaterialResource extends IProjectResource {
    name: string,
    description?: string,
    unitOfMeasure?: string,
    unitCost?: number,
}

export interface ServiceResource extends IProjectResource {
    serviceProvider: string,
    description?: string,
    unitOfMeasure?: string,
    unitCost?: number,
}

export interface ProjectResource {
    projectGuid: string,
    operators: OperatorResource[],
    equipment: EquipmentResource[],
    materials: MaterialResource[],
    services: ServiceResource[],
}

export interface ResourceState {
    resourceLoading: boolean,
    patchResourceLoading: boolean,
    postResourceLoading: boolean,
    deleteResourceLoading: boolean,
    fetchResourceError: string,
    patchResourceError: string,
    postResourceError: string,
    deleteResourceError: string,
    projectResource: ProjectResource,
    patchProjectResource: ProjectResource,
    postProjectResource: ProjectResource,
}
