













































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'vue-property-decorator';
import ReportFooter from '../ReportFooter.vue';
import { GeneralMaintenanceReportFields } from './types';
import ReportInterface, {
  WorkOrderfieldType,
} from '../ReportHelpers/ReportInterface.vue';
import ReportCalendarInput from '../ReportHelpers/ReportInputHelpers/ReportCalendarInput.vue';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import ReportAssignedToDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportAssignedToDropDownInput.vue';

@Component({
  components: {
    ReportFooter,
    ReportCalendarInput,
    ReportDropDownInput,
    ReportTextInput,
    ReportAssignedToDropDownInput,
  },
})
export default class GeneralMaintenanceReport extends ReportInterface {
  // #region Abstract fields
  workOrderData: GeneralMaintenanceReportFields | any = {};

  workOrderFields = [
    { name: 'assignedTo', value: 'Users', dontUpdateInProgress: true },
    { name: 'dateIssued', value: 'DateIssued', type: WorkOrderfieldType.DATE },
    { name: 'workDescription', value: 'Description' },
    { name: 'specialInstructions', value: 'Instructions' },
    { name: 'assetNumber', value: 'Wastewater' },
    { name: 'priority', value: 'Priority' },
    {
      name: 'planDueDate',
      value: 'PlanDueDate',
      type: WorkOrderfieldType.DATE,
      dontUpdateInProgress: true,
    },
    { name: 'street', value: 'Street' },
    { name: 'dateCompleted', value: 'InspDate', type: WorkOrderfieldType.DATE },
    { name: 'notCompletedWhy', value: 'ResonNotComplete' },
    { name: 'specialConditions', value: 'SpecialCondition' },
    { name: 'clean', value: 'FUClean', type: WorkOrderfieldType.BOOLEAN },
    { name: 'repair', value: 'FURepair', type: WorkOrderfieldType.BOOLEAN },
    { name: 'inspect', value: 'FUInspect', type: WorkOrderfieldType.BOOLEAN },
    { name: 'none', value: 'FUNone', type: WorkOrderfieldType.BOOLEAN },
    { name: 'activity', value: 'Activity' },
  ];

  get isNoneSelected(): boolean {
    return this.workOrderData.none === true;
  }

  get isFollowUpSelected(): boolean {
    return (
      this.workOrderData.clean !== false
      || this.workOrderData.repair !== false
      || this.workOrderData.inspect !== false
    );
  }

  isFollowUpRequired(): boolean {
    if (this.isNoneSelected) {
      return false;
    }
    return this.isFollowUpSelected;
  }

  // #endregion
}
