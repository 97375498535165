

























































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, VModel } from 'vue-property-decorator';
import ReportInputMixin from '../ReportInputMixin.vue';
import ReportTextInput from './ReportTextInput.vue';
import ReportCalendarInput from './ReportCalendarInput.vue';
import ReportDropDownInput from './ReportDropDownInput.vue';

@Component({
  components: {
    ReportTextInput,
    ReportCalendarInput,
    ReportDropDownInput,
  },
})
export default class ReportResourceTableInput extends ReportInputMixin {
  @VModel() modelValue!: any[] | undefined;

  /**
   * Label for the table.
   * @type {string}
   */
  @Prop() label!: string;

  /**
   * Array of columns to be displayed in the table.
   * @type {any[]}
   */
  @Prop() columns!: any[];

  @Prop() options!: any[];

  dialogDelete = false;

  deleteIndex = 0;

  get tableItems(): string[] {
    return this.options.map((x) => x.name);
  }

  update(val, row): void {
    const selectedOption = this.options.find((option) => option.name === val);
    this.columns.forEach((column) => {
      if (!column.fillValue) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      row[column.value] = selectedOption[column.fillValue];
    });
  }

  columnCost(column): number {
    if (!this.modelValue) {
      return 0;
    }
    return this.modelValue.reduce(
      (partialSum, a) => partialSum + this.callFunction(column, a),
      0,
    );
  }

  callFunction(column, row): number {
    return column.function(row);
  }

  /**
   * Temporary new row getter
   */
  newRow(): any {
    return Object.fromEntries(
      this.columns.filter((col) => !col.filter).map((col) => [col.value, null]),
    );
  }

  /**
   * Adds a new row to the table.
   */
  addRow(): void {
    this.modelValue.push(this.newRow());
  }

  toggleDelete(index: number): void {
    this.dialogDelete = true;
    this.deleteIndex = index;
  }

  /**
   * Removes a row from the table by its index.
   * @param {number} index - The index of the row to remove.
   * @returns {void}
   */
  removeRow(index: number): void {
    if (this.modelValue.length <= index) {
      console.error('Invalid index');
    }
    this.modelValue.splice(index, 1);
    this.dialogDelete = false;
  }
}
