export interface SubInspectionWorkOrder {
    subInspectionGuid: string,
    jsonData: any,
}

export const timeAndMaterialsFields = [
  { name: 'timeAndMaterialsLabor', value: 'timeAndMaterialsLabor' },
  { name: 'timeAndMaterialsEquipment', value: 'timeAndMaterialsEquipment' },
  { name: 'timeAndMaterialsMaterial', value: 'timeAndMaterialsMaterial' },
  { name: 'timeAndMaterialsService', value: 'timeAndMaterialsService' },
];
