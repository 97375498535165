import { ActionTree } from 'vuex';
import { RootState } from '../types';
import storeUtility from '../utils';
import { ProjectResource, ResourceState } from './types';
import { ResourceMutations } from './mutations';

// eslint-disable-next-line no-shadow
export enum ResourceActions {
  FETCH_PROJECT_RESOURCE = 'FETCH_PROJECT_RESOURCE',
  PATCH_PROJECT_RESOURCE = 'PATCH_PROJECT_RESOURCE',
  POST_PROJECT_RESOURCE = 'POST_PROJECT_RESOURCE',
  DELETE_PROJECT_RESOURCE = 'DELETE_PROJECT_RESOURCE',
}

export const actions: ActionTree<ResourceState, RootState> = {

  // eslint-disable-next-line spaced-comment
  async [ResourceActions.FETCH_PROJECT_RESOURCE]({ commit }, projectID: string): Promise<void> {
    commit(ResourceMutations.SET_FETCH_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.fetchProjectResource(projectID);
      commit(ResourceMutations.SET_PROJECT_RESOURCE, response.data);
    } catch (e) {
      commit(ResourceMutations.SET_FETCH_RESOURCE_ERROR, (e as Error).message);
      throw Error('Fetch Resource Error occurred.');
    } finally {
      commit(ResourceMutations.SET_FETCH_LOADING_STATE, false);
    }
  },

  // eslint-disable-next-line spaced-comment
  async [ResourceActions.PATCH_PROJECT_RESOURCE]({ commit }, projectResourceDTO: ProjectResource)
    : Promise<void> {
    commit(ResourceMutations.SET_PATCH_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.patchProjectResource(projectResourceDTO);
      commit(ResourceMutations.SET_PATCH_PROJECT_RESOURCE, response.data);
    } catch (e) {
      commit(ResourceMutations.SET_PATCH_RESOURCE_ERROR, (e as Error).message);
      throw Error('Fetch Resource Error occurred.');
    } finally {
      commit(ResourceMutations.SET_PATCH_LOADING_STATE, false);
    }
  },

  // eslint-disable-next-line spaced-comment
  async [ResourceActions.POST_PROJECT_RESOURCE]({ commit }, projectResourceDTO: ProjectResource)
    : Promise<void> {
    commit(ResourceMutations.SET_POST_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.postProjectResource(projectResourceDTO);
      commit(ResourceMutations.SET_POST_PROJECT_RESOURCE, response.data);
    } catch (e) {
      if (storeUtility.isDuplicateDbEntry(e)) {
        commit(ResourceMutations.SET_POST_RESOURCE_ERROR, 'This Resource already exists on this Project');
        throw new Error('Resource Name Already Exists on this Project');
      }
      commit(ResourceMutations.SET_POST_RESOURCE_ERROR, (e as Error).message);
      throw Error('Fetch Resource Error occurred.');
    } finally {
      commit(ResourceMutations.SET_POST_LOADING_STATE, false);
    }
  },

  // eslint-disable-next-line spaced-comment
  async [ResourceActions.DELETE_PROJECT_RESOURCE]({ commit }, projectID: string): Promise<void> {
    commit(ResourceMutations.SET_DELETE_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.deleteProjectResource(projectID);
    } catch (e) {
      commit(ResourceMutations.SET_DELETE_RESOURCE_ERROR, (e as Error).message);
      throw Error('Fetch Resource Error occurred.');
    } finally {
      commit(ResourceMutations.SET_DELETE_LOADING_STATE, false);
    }
  },
};
