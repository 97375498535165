







































































import {
  IProjectResource,
  ProjectResource,
  ResourceType,
} from '@/store/resources/types';
import {
  Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ResourceActions } from '@/store/resources/actions';
import { SnackBarActions } from '@/store/integritySnackBar/actions';

const resourcesModule = namespace('resources');
const projectModule = namespace('project');
const integritySnackBar = namespace('integritySnackBar');

@Component({})
export default class ManualEntryDialog extends Vue {
  @Prop() readonly resourceType: ResourceType;

  @Prop() readonly fields: any[];

  @Prop() readonly mode: string;

  @Prop() readonly newResource: IProjectResource;

  @PropSync('showDialog') syncedShowDialog: boolean;

  @PropSync('resource') syncedResource: IProjectResource;

  @resourcesModule.Action(ResourceActions.POST_PROJECT_RESOURCE)
  postProjectResource;

  @resourcesModule.Action(ResourceActions.PATCH_PROJECT_RESOURCE)
  patchProjectResource;

  @resourcesModule.Action(ResourceActions.FETCH_PROJECT_RESOURCE)
  fetchProjectResource;

  @resourcesModule.State('projectResource') projectResource:
    | ProjectResource
    | undefined;

  @resourcesModule.State('postResourceLoading')
  postResourceLoading: boolean;

  @resourcesModule.State('patchResourceLoading')
  patchResourceLoading: boolean;

  @resourcesModule.State('postResourceError') postResourceError: string;

  @resourcesModule.State('patchResourceError') patchResourceError: string;

  @projectModule.State('projectGuids') projectGuids: string[];

  @integritySnackBar.Action(SnackBarActions.SHOW) showSnackBar;

  validResource = false as boolean;

  search = null;

  resourceUpdate = { guid: '' };

  requiredField = [
    (v: string): string | boolean => !!v || 'This is a required field.',
  ];

  emailRules = [
    (v: string): string | boolean => (
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || v === '')
      || 'Email must be valid',
  ];

  numberRules = [
    (v: string) : string | boolean => (
      (/^(?:\d+(\.\d*)?|\.\d+)$/.test(v) && parseFloat(v) >= 0) || v === '' || v == null)
      || 'Input must be a positive number or zero',
  ];

  mounted(): void {
    this.onResourceChange();
  }

  @Watch('syncedResource')
  onResourceChange(): void {
    if (this.syncedResource) {
      this.resourceUpdate = { ...this.syncedResource };
    } else {
      this.resourceUpdate = { ...this.newResource };
    }
  }

  resetForm(): void {
    (this.$refs.resourceForm as any).reset();
  }

  checkForNewOptionsAndAdd(field: any): void {
    if (!field.options.includes(this.resourceUpdate[field.value])) {
      field.options.push(this.resourceUpdate[field.value]);
    }
  }

  async save(item: IProjectResource): Promise<boolean> {
    try {
      const obj = {
        projectGuid: this.projectGuids[0],
        operators: [],
        equipment: [],
        materials: [],
        services: [],
      };
      obj[this.resourceType] = [item];
      if (this.mode === 'Edit') {
        await this.patchProjectResource(obj);
      } else {
        await this.postProjectResource(obj);
      }
      this.fetchProjectResource(this.projectGuids[0]);
    } catch (e) {
      let message = '';
      if (this.mode === 'Add') {
        message = this.postResourceError;
      } else {
        message = (e as Error).message;
      }
      this.showSnackBar({
        message,
        color: '#E61E25',
        showSnackBar: true,
      });
      return false;
    }
    this.syncedShowDialog = false;
    this.resetForm();
    return true;
  }

  getRules(field: any): any[] {
    let rules = [];
    if (field.required) {
      rules = rules.concat(this.requiredField);
    }
    if (field.value === 'email') {
      rules = rules.concat(this.emailRules);
    } else if (field.type === 'number') {
      rules = rules.concat(this.numberRules);
    }
    return rules;
  }
}
