




























































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'vue-property-decorator';
import ReportFooter from '../ReportFooter.vue';
import ReportInterface, {
  WorkOrderfieldType,
} from '../ReportHelpers/ReportInterface.vue';
import ReportCalendarInput from '../ReportHelpers/ReportInputHelpers/ReportCalendarInput.vue';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import ReportAssignedToDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportAssignedToDropDownInput.vue';
import ReliningReportHeader from './PipeReliningReportHeader.vue';
import { PipeReliningReportFields } from './types';
import TimeAndMaterialsTable from '../TimeAndMaterials/TimeAndMaterialsTable.vue';

@Component({
  components: {
    ReportFooter,
    ReportCalendarInput,
    ReportDropDownInput,
    ReportTextInput,
    ReportAssignedToDropDownInput,
    ReliningReportHeader,
    TimeAndMaterialsTable,
  },
})
export default class PipeReliningReport extends ReportInterface {
  // #region Abstract fields
  workOrderData: PipeReliningReportFields | any = {};

  workOrderFields = [
    { name: 'wastewater', value: 'Wastewater' },
    { name: 'dateIssued', value: 'DateIssued', type: WorkOrderfieldType.DATE },
    { name: 'surveyor', value: 'Surveyor' },
    {
      name: 'planDueDate',
      value: 'PlanDueDate',
      type: WorkOrderfieldType.DATE,
    },
    {
      name: 'reliningDate',
      value: 'ReliningDate',
      type: WorkOrderfieldType.DATE,
    },
    { name: 'location', value: 'Location' },
    {
      name: 'pipeLength',
      value: 'PipeLength',
      type: WorkOrderfieldType.NUMBER,
    },
    {
      name: 'lengthOrdered',
      value: 'LengthOrdered',
      type: WorkOrderfieldType.NUMBER,
    },
    { name: 'hostPipeMaterial', value: 'HostPipeMaterial' },
    {
      name: 'hostPipeSize',
      value: 'HostPipeSize',
      type: WorkOrderfieldType.NUMBER,
    },
    { name: 'specialConditions', value: 'SpecialConditions' },
  ];
  // #endregion
}
