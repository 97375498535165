import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/processing/actions';
import { mutations } from './mutations';
import { ProcessingState } from './types';

const state : ProcessingState = {
  processings: [],
  processingEditError: undefined,
  processingEditLoading: false,
  processingGetError: undefined,
  processingGetLoading: false,
  soloProcessingVersion: undefined,
  soloProcessingVersionError: undefined,
  soloProcessingVersionLoading: false,
};

const processing: Module<ProcessingState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default processing;
