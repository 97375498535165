











import {
  IProjectResource,
  ResourceType,
  ServiceResource,
} from '@/store/resources/types';
import {
  Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import ManualEntryDialog from './ManualEntryDialog.vue';

@Component({
  components: {
    ManualEntryDialog,
  },
})
export default class ServiceEntryDialog extends Vue {
  @Prop() readonly mode: string;

  @PropSync('model') resource: IProjectResource;

  @PropSync('addResourceDialog') showDialog: boolean;

  serviceUnitOptions = ['Hour', 'Day', 'Session'];

  resourceUpdate = null;

  serviceFields = [
    {
      label: 'Service Provider', value: 'serviceProvider', required: true, type: 'text',
    },
    {
      label: 'Description', value: 'description', required: false, type: 'multiline',
    },
    {
      label: 'Unit of Measure',
      value: 'unitOfMeasure',
      required: false,
      type: 'combo',
      options: this.serviceUnitOptions,
    },
    {
      label: 'Unit Cost', value: 'unitCost', required: false, type: 'number',
    },
  ]

  newService = {
    serviceProvider: '',
    description: '',
    unitOfMeasure: '',
    unitCost: null,
  } as ServiceResource;

  get serviceType(): string {
    return ResourceType.services;
  }
}
