

















































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  InspectionData, Condition, AssetData,
} from '@/store/asset/types';
import {
  Component, Prop, Watch,
} from 'vue-property-decorator';
import mediaUtils from '@/views/Asset/utils';
import PipeConditionReport from '../../PipeConditionReport/PipeConditionReport.vue';
import ReportObservation from '../ReportObservation.vue';
import util from '../util';
import ReportFooter from '../ReportFooter.vue';
import { ObservationReportFullFields } from './types';
import ReportInterface from '../ReportHelpers/ReportInterface.vue';

@Component({
  components: {
    PipeConditionReport,
    ReportObservation,
    ReportFooter,
  },
})
export default class Observation extends ReportInterface {
  @Prop() readonly inspection!: InspectionData;

  @Prop({ default: 'N/A' }) readonly assetName!: string;

  @Prop() readonly asset!: AssetData;

  @Prop({ default: -1 }) readonly fullVideoTime: number;

  workOrderData: ObservationReportFullFields | any = {};

  workOrderFields = [
    { name: 'street', value: 'Address' },
    { name: 'city', value: 'City' },
    { name: 'overallScoringoverallQuick', value: 'OverallQuick' },
    { name: 'overallScoringoverallIndex', value: 'OverallIndex' },
    { name: 'overallScoringoverallRating', value: 'OverallRating' },
    { name: 'overallScoringstructuralQuick', value: 'StructuralQuick' },
    { name: 'overallScoringstructuralIndex', value: 'StructuralIndex' },
    { name: 'overallScoringstructuralRating', value: 'StructuralRating' },
    { name: 'overallScoringomQuick', value: 'OMQuick' },
    { name: 'overallScoringomIndex', value: 'OMIndex' },
    { name: 'overallScoringomRating', value: 'OMRating' },
    { name: 'generalInformationDate', value: 'date_L1_2' },
    { name: 'generalInformationowner', value: 'Owner' },
    { name: 'generalInformationcustomer', value: 'Customer' },
    { name: 'lining', value: 'LiningMethod' },
    { name: 'shape', value: 'PipeShape' },
    { name: 'surveyedLength', value: 'LengthSurveyed' },
    { name: 'surveyor', value: 'SurveyedBy' },
    { name: 'direction', value: 'Direction' },
    { name: 'location', value: 'Location' },
    { name: 'locationDetails', value: 'LocationDetails' },
    { name: 'po', value: 'PO_Number' },
    { name: 'purpose', value: 'Purpose' },
    { name: 'generalInformationdateCleaned', value: 'DateCleaned' },
    { name: 'flowControl', value: 'FlowControl' },
    { name: 'material', value: 'PipeMaterial' },
    { name: 'pipeHeight', value: 'PipeSize_Height' },
    { name: 'generalInformationcertificateNumber', value: 'CertificateNumber' },
    { name: 'locationdrainageArea', value: 'DrainageArea' },
    { name: 'generalInformationpreCleaning', value: 'PreCleaning' },
    { name: 'manholeyearRenewed', value: 'YearRenewed' },
    { name: 'generalInformationweather', value: 'Weather' },
    { name: 'additionalComponentInformation', value: 'AdditionalInfo' },
  ];

  howManyIterations = 0;

  inspectionsGrouped: Array<InspectionData> = [];

  get codingDetailReport(): Record<string, unknown> {
    return this.codingDetail ? JSON.parse(this.codingDetail.inspections[0].jsonData) : {};
  }

  get report(): Record<string, unknown> {
    return this.inspection ? this.inspection.report : {};
  }

  get conditionReportPages(): Array<Condition[]> {
    let index = 1;
    let pageCount = 0;
    let page = [];
    const pages = [[]];

    if (this.inspection) {
      this.inspection.conditionReport.forEach((condRep) => {
        pages[pageCount].push(condRep);

        if (index % 3 === 0 && index !== this.inspection.conditionReport.length) {
          page = [];
          pages.push(page);
          pageCount += 1;
        }

        index += 1;
      });
    }
    return pages;
  }

  // eslint-disable-next-line class-methods-use-this
  get dateNow(): string {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    } as const;
    return new Date().toLocaleDateString('en-US', options);
  }

  get maxDepth(): number {
    return mediaUtils.getMaxDepthReached(
      this.inspection,
      this.fullVideoTime,
    );
  }

  get assetScreenshotUrl(): string {
    if (this.$store == null) return '';
    return this.$store.getters['asset/getAssetScreenshotUrl'];
  }

  @Watch('inspection')
  onInspectionChange(): void{
    this.inspectionsGrouped = [];
    this.howManyIterations = 0;
    if (this.inspection != null
      && this.inspection.conditionReport != null
      && this.inspection.conditionReport.length > 0
    ) {
      this.howManyIterations = Math.ceil(this.inspection.conditionReport.length / 30);

      let tempList = [];
      const sortedInspections = this.inspection.conditionReport
        .sort((a, b) => a.distance - b.distance);
      sortedInspections.forEach((e, i) => {
        if (i % 30 === 0 && i !== 0) {
          const newInspection: InspectionData = {
            name: this.inspection.name,
            guid: this.inspection.guid,
            inspectionDate: this.inspection.inspectionDate != null
              ? this.inspection.inspectionDate : null,
            macpLevel: 'Level 1',
            maxPayout: this.inspection.maxPayout,
            measurementUnits: this.inspection.measurementUnits,
            conditionReport: this.inspection.conditionReport,
            flatImage: this.inspection.flatImage,
            video: this.inspection.video,
            wmVideo: this.inspection.wmVideo,
            msiExist: this.inspection.msiExist,
            model: this.inspection.model,
            deliverablesJson: this.inspection.deliverablesJson,
            report: this.inspection.report,
            payoutIPD: this.inspection.payoutIPD != null ? this.inspection.payoutIPD : null,
            version: this.inspection.version,
          };
          newInspection.conditionReport = tempList;
          this.inspectionsGrouped.push(newInspection);
          tempList = [];
        }
        tempList.push(e);
      });
      if (tempList.length > 0) {
        const newInspection: InspectionData = {
          name: this.inspection.name,
          guid: this.inspection.guid,
          inspectionDate: this.inspection.inspectionDate != null
            ? this.inspection.inspectionDate : null,
          macpLevel: 'Level 1',
          maxPayout: this.inspection.maxPayout,
          measurementUnits: this.inspection.measurementUnits,
          conditionReport: this.inspection.conditionReport,
          flatImage: this.inspection.flatImage,
          video: this.inspection.video,
          wmVideo: this.inspection.wmVideo,
          msiExist: this.inspection.msiExist,
          model: this.inspection.model,
          deliverablesJson: this.inspection.deliverablesJson,
          report: this.inspection.report,
          payoutIPD: this.inspection.payoutIPD != null ? this.inspection.payoutIPD : null,
          version: this.inspection.version,
        };
        newInspection.conditionReport = tempList;
        this.inspectionsGrouped.push(newInspection);
      }
    }
  }

  mounted(): void{
    this.onInspectionChange();
  }

  getDisplayDistanceInMM(value: string): string {
    try {
      const num = parseInt(value.substring(0, value.length - 1), 10);
      return value || num === 0
        ? `${util.getDisplayDistanceInMM(this.displayImperial, num)} ${util.getDistanceUnitsInMM(this.displayImperial)}`
        : '';
    } catch {
      return '';
    }
  }

  getDisplayDistanceFtM(value: number): string {
    try {
      return value || value === 0
        ? `${util.getDisplayDistanceFtM(this.displayImperial, value)} ${util.getDistanceUnitsFtM(this.displayImperial)}`
        : '';
    } catch {
      return '';
    }
  }

  getQuickStyle(score: string): any {
    try {
      const quickScore = score.length > 0 ? parseInt(score.charAt(0), 10) : 0;
      return {
        color: quickScore === 3 ? 'black' : 'white',
        backgroundColor: util.getScoreColor(quickScore),
      };
    } catch {
      return {
        color: 'white',
        backgroundColor: '#0C6599',
      };
    }
  }

  get firstManhole(): string {
    return this.isUpstreamInspection ? this.downstreamMH : this.upstreamMH;
  }

  get secondManhole(): string {
    return this.isUpstreamInspection ? this.upstreamMH : this.downstreamMH;
  }

  get upstreamMH(): string {
    return this.asset.attributes['Wastewater_Structure_Up'] ?? '';
  }

  get downstreamMH(): string {
    return this.asset.attributes['Wastewater_Structure_Dn'] ?? '';
  }

  get isUpstreamInspection(): boolean {
    const { direction } = this.workOrderData;
    return direction === 'Upstream';
  }
}
