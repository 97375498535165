



























































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import CustomerDialogSelectCustomer from '@/components/Admin/Customers/CustomerDialogSelectCustomer.vue';
import CustomerDialogSelectOrganization from '@/components/Admin/Customers/CustomerDialogSelectOrganization.vue';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import { UserPermission } from '@/store/userpermissions/types';
import {
  Component, Prop, PropSync,
} from 'vue-property-decorator';

@Component({
  components: {
    CustomerDialogSelectOrganization,
    CustomerDialogSelectCustomer,
  },
})
export default class MPSSectionInformation extends UserPermissionsMixin {
  @Prop() readonly canEditMPS: boolean;

  @Prop() readonly requiredField;

  @PropSync('city') synchedCity;

  @PropSync('contractNumber') synchedContractNumber;

  @PropSync('jobNumber') synchedJobNumber;

  @PropSync('otherProjectReason') synchedOtherProjectReason;

  @PropSync('poNumber') synchedPONumber;

  @PropSync('projectName') synchedProjectName;

  @PropSync('projectScope') synchedProjectScope;

  @PropSync('reasonForProject') synchedReasonForProject;

  @PropSync('stateSelected') synchedStateSelected;

  @PropSync('totalFootage') synchedTotalFootage;

  @PropSync('totalVertical') synchedTotalVertical;

  @PropSync('projectOwner') synchedProjectOwner;

  @PropSync('inspectionPurpose') synchedInspectionPurpose;

  @PropSync('organization') synchedOrganization;

  @PropSync('customer') synchedCustomer;

  @PropSync('valid') synchedValid;

  projectReasonOptions = [
    'Capital Improvement',
    'General Condition Assessment',
    'New Construction',
    'Rehabilitation Planning',
    'Cleaning',
    'Other',
  ];

  states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Deleware',
    'District of Columbia',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
    'Guam',
    'Puerto Rico',
    'Virgin Islands',
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon',
  ];

  projectInspectionPurposeOptions = [
    'Capital Improvement Program Assessment',
    'Infiltration/Inflow Investigation',
    'Maintenance Related',
    'Not Known',
    'Post Rehabilitation Survey',
    'Pre-Acceptance',
    'Resurvey',
    'Reversal',
    'Routine Assessment',
  ];

  resetValidation(): void {
    (this.$refs.infoForm as any).reset();
  }

  /**
   * @description Finds if the user should be limited to selecting orgs that they are a part of.
   * @returns {boolean} true if user's email is not a redzone.com email.
   */
  get shouldLimitOrganizations(): boolean {
    if (!this.$auth?.user?.email) {
      return true;
    }
    return !this.$auth.user.email.endsWith('@redzone.com');
  }

  /**
   * @returns true if the user has the permission PROJECT_SELECTION_CREATE_CUSTOMER
   */
  get hasPermissionCreateCustomer(): boolean {
    return this.hasPermission(UserPermission.PROJECT_SELECTION_CREATE_CUSTOMER);
  }

  /**
   * @returns true if the user has the permission PROJECT_SELECTION_CREATE_ORGANIZATION
   */
  get hasPermissionCreateOrganization(): boolean {
    return this.hasPermission(UserPermission.PROJECT_SELECTION_CREATE_ORGANIZATION);
  }
}
