
























































































































































































































































































































































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'vue-property-decorator';
import ReportObservation from '../ReportObservation.vue';
import ReportFooter from '../ReportFooter.vue';
import { SSOReportFields } from './types';
import SSOReportHeader from './SSOReportHeader.vue';
import ReportInterface, {
  WorkOrderfieldType,
} from '../ReportHelpers/ReportInterface.vue';
import ReportInputCheckboxTable from '../ReportHelpers/ReportInputHelpers/ReportInputCheckboxTable.vue';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import ReportCalendarInput from '../ReportHelpers/ReportInputHelpers/ReportCalendarInput.vue';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportRadioRowInput from '../ReportHelpers/ReportInputHelpers/ReportRadioRowInput.vue';

@Component({
  components: {
    ReportFooter,
    ReportObservation,
    SSOReportHeader,
    ReportInputCheckboxTable,
    ReportTextInput,
    ReportCalendarInput,
    ReportDropDownInput,
    ReportRadioRowInput,
  },
})
export default class SSOReportReport extends ReportInterface {
  workOrderData: SSOReportFields | any = {};

  workOrderFields = [
    {
      name: 'city',
      value: 'City',
    },
    {
      name: 'correctiveActionAddedSewerToPMProgram',
      value: 'CorrectiveActionAddedSewerToPMProgram',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'correctiveActionAdjustedScheduleMethodOfPM',
      value: 'CorrectiveActionAdjustedScheduleMethodOfPM',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'correctiveActionEnfmntActionAgainstFogSource',
      value: 'CorrectiveActionEnfmntActionAgainstFogSource',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'correctiveActionOther',
      value: 'CorrectiveActionOther',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'correctiveActionPlanRehabilitationOfSewer',
      value: 'CorrectiveActionPlanRehabilitationOfSewer',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'correctiveActionRepairSewer',
      value: 'CorrectiveActionRepairSewer',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'departArrivalDate',
      value: 'DepartArrivalDate',
      type: WorkOrderfieldType.DATE,
    },
    {
      name: 'departArrivalTime',
      value: 'DepartArrivalTime',
      type: WorkOrderfieldType.TIME,
    },
    {
      name: 'deptNotifiedDate',
      value: 'DeptNotifiedDate',
      type: WorkOrderfieldType.DATE,
    },
    {
      name: 'deptNotifiedTime',
      value: 'DeptNotifiedTime',
      type: WorkOrderfieldType.TIME,
    },
    {
      name: 'descriptionOfSpill',
      value: 'DescriptionOfSpill',
    },
    {
      name: 'dischargeNotReturnedToSS',
      value: 'DischargeNotReturnedToSS',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'dischargeReachedDrain',
      value: 'DischargeReachedDrain',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'estimateCalculationMethod',
      value: 'EstimateCalculationMethod',
    },
    {
      name: 'estSpillRate',
      value: 'EstSpillRate',
      type: WorkOrderfieldType.NUMBER,
    },
    {
      name: 'estStartDate',
      value: 'EstStartDate',
      type: WorkOrderfieldType.DATE,
    },
    {
      name: 'estStartTime',
      value: 'EstStartTime',
      type: WorkOrderfieldType.TIME,
    },
    {
      name: 'fishKilled',
      value: 'FishKilled',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'healthServiceAfterHourPhone',
      value: 'HealthServiceAfterHourPhone',
    },
    {
      name: 'healthServicePhone',
      value: 'HealthServicePhone',
    },
    {
      name: 'healthServicesCaseNo',
      value: 'HealthServicesCaseNo',
    },
    {
      name: 'healthServicesNotified',
      value: 'HealthServicesNotified',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'healthServicesNotifiedDate',
      value: 'HealthServicesNotifiedDate',
      type: WorkOrderfieldType.DATE,
    },
    {
      name: 'healthServicesNotifiedTime',
      value: 'HealthServicesNotifiedTime',
      type: WorkOrderfieldType.TIME,
    },
    {
      name: 'healthWarningsPosted',
      value: 'HealthWarningsPosted',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'impactedBeaches',
      value: 'ImpactedBeaches',
    },
    {
      name: 'impactedSurfaceWaters',
      value: 'ImpactedSurfaceWaters',
    },
    {
      name: 'inspDate',
      value: 'InspDate',
      type: WorkOrderfieldType.DATE,
    },
    {
      name: 'lengthGIS',
      value: 'LengthGIS',
      type: WorkOrderfieldType.NUMBER,
    },
    {
      name: 'manholeNumber',
      value: 'ManholeNumber',
    },
    {
      name: 'nameOfResponsibleParty',
      value: 'NameOfResponsibleParty',
    },
    {
      name: 'notRecoveredVolume',
      value: 'NotRecoveredVolume',
      type: WorkOrderfieldType.NUMBER,
    },
    {
      name: 'oESContact',
      value: 'OESContact',
    },
    {
      name: 'oESContactedBy',
      value: 'OESContactedBy',
    },
    {
      name: 'oESControlNumber',
      value: 'OESControlNumber',
    },
    {
      name: 'oESNotifiedDate',
      value: 'OESNotifiedDate',
      type: WorkOrderfieldType.DATE,
    },
    {
      name: 'oESNotifiedTime',
      value: 'OESNotifiedTime',
      type: WorkOrderfieldType.TIME,
    },
    {
      name: 'ongoingInvestigation',
      value: 'OngoingInvestigation',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'photosTaken',
      value: 'PhotosTaken',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'pipeAge',
      value: 'PipeAge',
      type: WorkOrderfieldType.NUMBER,
    },
    {
      name: 'pipeMaterial',
      value: 'PipeMaterial',
    },
    {
      name: 'pipeSize',
      value: 'PipeSize',
      type: WorkOrderfieldType.NUMBER,
    },
    {
      name: 'privateLateral',
      value: 'PrivateLateral',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'recoveredVollume',
      value: 'RecoveredVollume',
      type: WorkOrderfieldType.NUMBER,
    },
    {
      name: 'reportedBy',
      value: 'ReportedBy',
    },
    {
      name: 'reportedDate',
      value: 'ReportedDate',
      type: WorkOrderfieldType.DATE,
    },
    {
      name: 'reportedToCountyHealthAgency',
      value: 'ReportedToCountyHealthAgency',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'reportedToNoneOfTheAbove',
      value: 'ReportedToNoneOfTheAbove',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'reportedToNotApplicableToThisSpill',
      value: 'ReportedToNotApplicableToThisSpill',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'reportedToNoWaterQualitySamplesTaken',
      value: 'ReportedToNoWaterQualitySamplesTaken',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'reportedToRegionalWaterQualityControlBoard',
      value: 'ReportedToRegionalWaterQualityControlBoard',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'rWQCBNotifiedDate',
      value: 'RWQCBNotifiedDate',
      type: WorkOrderfieldType.DATE,
    },
    {
      name: 'rWQCBNotifiedTime',
      value: 'RWQCBNotifiedTime',
      type: WorkOrderfieldType.TIME,
    },
    {
      name: 'rWQCBNotifiedViaFax',
      value: 'RWQCBNotifiedViaFax',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'rWQCBTrackingNo',
      value: 'RWQCBTrackingNo',
    },
    {
      name: 'sampleAnalyzedForBiologicalIndicators',
      value: 'SampleAnalyzedForBiologicalIndicators',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'sampleAnalyzedForDissolvedOxygen',
      value: 'SampleAnalyzedForDissolvedOxygen',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'sampleAnalyzedForNoWaterQualitySamplesTaken',
      value: 'SampleAnalyzedForNoWaterQualitySamplesTaken',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'sampleAnalyzedForOther',
      value: 'SampleAnalyzedForOther',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'sampleAnalyzedForOtherChemicalIndicators',
      value: 'SampleAnalyzedForOtherChemicalIndicators',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillAddress',
      value: 'SpillAddress',
    },
    {
      name: 'spillAppearancePointOther',
      value: 'SpillAppearancePointOther',
    },
    {
      name: 'spillBuildingOrStructure',
      value: 'SpillBuildingOrStructure',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillCauseDebris',
      value: 'SpillCauseDebris',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillCauseFlowExceededCapacity',
      value: 'SpillCauseFlowExceededCapacity',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillCauseGreaseDeposition',
      value: 'SpillCauseGreaseDeposition',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillCauseOperatorError',
      value: 'SpillCauseOperatorError',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillCauseOther',
      value: 'SpillCauseOther',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillCauseOtherDetails',
      value: 'SpillCauseOtherDetails',
    },
    {
      name: 'spillCausePipeStructuralProblemFailure',
      value: 'SpillCausePipeStructuralProblemFailure',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillCausePumpStationFailure',
      value: 'SpillCausePumpStationFailure',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillCauseRainfallExceededDesign',
      value: 'SpillCauseRainfallExceededDesign',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillCauseRootIntrusion',
      value: 'SpillCauseRootIntrusion',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillCauseVandalism',
      value: 'SpillCauseVandalism',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillCorrectiveActionOther',
      value: 'SpillCorrectiveActionOther',
    },
    {
      name: 'spillCounty',
      value: 'SpillCounty',
    },
    {
      name: 'spillCrossStreet',
      value: 'SpillCrossStreet',
    },
    {
      name: 'spillFinalDestOther',
      value: 'SpillFinalDestOther',
    },
    {
      name: 'spillForceMainOrPessureSewer',
      value: 'SpillForceMainOrPessureSewer',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillGravitySewer',
      value: 'SpillGravitySewer',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillLatHours',
      value: 'SpillLatHours',
      type: WorkOrderfieldType.NUMBER,
    },
    {
      name: 'spillLatMinutes',
      value: 'SpillLatMinutes',
      type: WorkOrderfieldType.NUMBER,
    },
    {
      name: 'spillLatSeconds',
      value: 'SpillLatSeconds',
      type: WorkOrderfieldType.NUMBER,
    },
    {
      name: 'spillLocation',
      value: 'SpillLocation',
    },
    {
      name: 'spillLocationDesc',
      value: 'SpillLocationDesc',
    },
    {
      name: 'spillLongHours',
      value: 'SpillLongHours',
      type: WorkOrderfieldType.NUMBER,
    },
    {
      name: 'spillLongMinutes',
      value: 'SpillLongMinutes',
      type: WorkOrderfieldType.NUMBER,
    },
    {
      name: 'spillLongSeconds',
      value: 'SpillLongSeconds',
      type: WorkOrderfieldType.NUMBER,
    },
    {
      name: 'spillManhole',
      value: 'SpillManhole',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillOther',
      value: 'SpillOther',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillOtherSewerSystemStructure',
      value: 'SpillOtherSewerSystemStructure',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillPumpStation',
      value: 'SpillPumpStation',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillReachedBeach',
      value: 'SpillReachedBeach',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillReachedBuildingOrStructure',
      value: 'SpillReachedBuildingOrStructure',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillReachedOther(Specifiy)',
      value: 'SpillReachedOther(Specifiy)',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillReachedOtherPavedSurface',
      value: 'SpillReachedOtherPavedSurface',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillReachedStormDrain',
      value: 'SpillReachedStormDrain',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillReachedStreetCurbAndGutter',
      value: 'SpillReachedStreetCurbAndGutter',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillReachedSurfaceWater',
      value: 'SpillReachedSurfaceWater',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillReachedUnpavedSurface',
      value: 'SpillReachedUnpavedSurface',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillRespCompDate',
      value: 'SpillRespCompDate',
      type: WorkOrderfieldType.DATE,
    },
    {
      name: 'spillRespCompTime',
      value: 'SpillRespCompTime',
      type: WorkOrderfieldType.TIME,
    },
    {
      name: 'spillResponseCleaned-Up',
      value: 'SpillResponseCleaned-Up',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillResponseContainedAllOrPortionOfSpill',
      value: 'SpillResponseContainedAllOrPortionOfSpill',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillResponseDetails',
      value: 'SpillResponseDetails',
    },
    {
      name: 'spillResponseInspectedSewerUsingCCTVToDetermineCause',
      value: 'SpillResponseInspectedSewerUsingCCTVToDetermineCause',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillResponseOther',
      value: 'SpillResponseOther',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillResponseRestoredFlow',
      value: 'SpillResponseRestoredFlow',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillResponseReturnedAllOrPortionToSanitarySewerSystem',
      value: 'SpillResponseReturnedAllOrPortionToSanitarySewerSystem',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'spillRWCB',
      value: 'SpillRWCB',
    },
    {
      name: 'spillSampleOtherResponse',
      value: 'SpillSampleOtherResponse',
    },
    {
      name: 'spillSampleResultsRepTo',
      value: 'SpillSampleResultsRepTo',
    },
    {
      name: 'spillStopDate',
      value: 'SpillStopDate',
      type: WorkOrderfieldType.DATE,
    },
    {
      name: 'spillStopTime',
      value: 'SpillStopTime',
      type: WorkOrderfieldType.TIME,
    },
    {
      name: 'spillVolume',
      value: 'SpillVolume',
      type: WorkOrderfieldType.NUMBER,
    },
    {
      name: 'sSOStormSize',
      value: 'SSOStormSize',
    },
    {
      name: 'sSOTerrain',
      value: 'SSOTerrain',
    },
    {
      name: 'structAge',
      value: 'StructAge',
      type: WorkOrderfieldType.NUMBER,
    },
    {
      name: 'structName',
      value: 'StructName',
    },
    {
      name: 'sWQCBCertificationNo',
      value: 'SWQCBCertificationNo',
    },
    {
      name: 'sWQCBElectronicReportFiled',
      value: 'SWQCBElectronicReportFiled',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'sWQCBEventID',
      value: 'SWQCBEventID',
    },
    {
      name: 'sWQCBNotifiedDate',
      value: 'SWQCBNotifiedDate',
      type: WorkOrderfieldType.DATE,
    },
    {
      name: 'sWQCBNotifiedTime',
      value: 'SWQCBNotifiedTime',
      type: WorkOrderfieldType.TIME,
    },
    {
      name: 'wastewater',
      value: 'Wastewater',
    },
  ];

  appearancePointCheckboxValues = [
    { label: 'Building Or Structure', value: 'spillBuildingOrStructure' },
    {
      label: 'Force Main or Pressure Sewer',
      value: 'spillForceMainOrPessureSewer',
    },
    { label: 'Gravity Sewer', value: 'spillGravitySewer' },
    {
      label: 'Other Sewer System Structure',
      value: 'spillOtherSewerSystemStructure',
    },
    { label: 'Pump Station', value: 'spillPumpStation' },
    { label: 'Manhole', value: 'spillManhole' },
    { label: 'Other(Specify)', value: 'spillOther' },
  ];

  finalSpillDestinationCheckboxValues = [
    { label: 'Beach', value: 'spillReachedBeach' },
    {
      label: 'Street/Curb and Gutter',
      value: 'spillReachedStreetCurbAndGutter',
    },
    {
      label: 'Building or Structure',
      value: 'spillReachedBuildingOrStructure',
    },
    { label: 'Surface Water', value: 'spillReachedSurfaceWater' },
    { label: 'Other Paved Surface', value: 'spillReachedOtherPavedSurface' },
    { label: 'Unpaved Surface', value: 'spillReachedUnpavedSurface' },
    { label: 'Storm Drain', value: 'spillReachedStormDrain' },
    { label: 'Other (Specify)', value: 'spillReachedOther(Specifiy)' },
  ];

  spillCauseCheckboxValues = [
    { label: 'Debris', value: 'spillCauseDebris' },
    { label: 'Pump Station Failure', value: 'spillCausePumpStationFailure' },
    {
      label: 'Flow Exceeded Capacity',
      value: 'spillCauseFlowExceededCapacity',
    },
    {
      label: 'Rainfall Exceeded Design (Specify)',
      value: 'spillCauseRainfallExceededDesign',
    },
    { label: 'Grease Deposition (FOG)', value: 'spillCauseGreaseDeposition' },
    { label: 'Root Intrusion', value: 'spillCauseRootIntrusion' },
    { label: 'Operator Error', value: 'spillCauseOperatorError' },
    { label: 'Vandalism', value: 'spillCauseVandalism' },
    {
      label: 'Pipe Structural Problem/Failure',
      value: 'spillCausePipeStructuralProblemFailure',
    },
    { label: 'Other (Specify)', value: 'spillCauseOther' },
  ];

  spillResponseCheckboxValues = [
    {
      label: 'Cleaned Up (Mitigated Effects of Spill)',
      value: 'spillResponseCleaned-Up',
    },
    { label: 'Restored Flow', value: 'spillResponseRestoredFlow' },
    {
      label: 'Contained All or Portion of Spill',
      value: 'spillResponseContainedAllOrPortionOfSpill',
    },
    {
      label: 'Returned All or Portion to Sanitary Sewer System',
      value: 'spillResponseReturnedAllOrPortionToSanitarySewerSystem',
    },
    {
      label: 'Inspected Sewer Using CCTV to Determine Cause',
      value: 'spillResponseInspectedSewerUsingCCTVToDetermineCause',
    },
    { label: 'Other (Specify)', value: 'spillResponseOther' },
  ];

  sampleAnalyzedForCheckboxValues = [
    { label: 'Dissolved Oxygen', value: 'sampleAnalyzedForDissolvedOxygen' },
    {
      label: 'No Water Quality Samples Taken',
      value: 'sampleAnalyzedForNoWaterQualitySamplesTaken',
    },
    {
      label: 'Other Chemical Indicators(s)*',
      value: 'sampleAnalyzedForOtherChemicalIndicators',
    },
    { label: 'Other*', value: 'sampleAnalyzedForOther' },
    {
      label: 'Biological Indicator(s)*',
      value: 'sampleAnalyzedForBiologicalIndicators',
    },
  ];

  resultsReportedToCheckBoxValues = [
    { label: 'County Health Agency', value: 'reportedToCountyHealthAgency' },
    {
      label: 'No Water Quality Samples Taken',
      value: 'reportedToNoWaterQualitySamplesTaken',
    },
    {
      label: 'Regional Water Quality Control Board',
      value: 'reportedToRegionalWaterQualityControlBoard',
    },
    {
      label: 'Not Applicable to this Spill',
      value: 'reportedToNotApplicableToThisSpill',
    },
    { label: 'None of the Above', value: 'reportedToNoneOfTheAbove' },
  ];

  spillCorrectiveActionTakenCheckboxValues = [
    {
      label: 'Added Sewer to PM Program',
      value: 'correctiveActionAddedSewerToPMProgram',
    },
    {
      label: 'Plan Rehabilitation of Sewer',
      value: 'correctiveActionPlanRehabilitationOfSewer',
    },
    {
      label: 'Adjusted Schedule/Method of PM',
      value: 'correctiveActionAdjustedScheduleMethodOfPM',
    },
    { label: 'Repair Sewer', value: 'correctiveActionRepairSewer' },
    {
      label: 'Enfmnt Action Against FOG Source',
      value: 'correctiveActionEnfmntActionAgainstFogSource',
    },
    { label: 'Other (Specify)', value: 'correctiveActionOther' },
  ];

  descriptionOfTerrainAtSpillOptions = [
    { value: 'FLAT', label: 'Flat' },
    { value: 'MIXED', label: 'Mixed' },
    { value: 'STEEP', label: 'Steep' },
  ];

  stormSizeValues = [
    '1 Year',
    '2 Year',
    '5 Year',
    '10 Year',
    '50 Year',
    '100 Year',
    '> 100 Year',
    'Unknown',
  ];

  stormSizeLabels = [
    '1 Yr',
    '2 Yr',
    '5 Yr',
    '10 Yr',
    '50 Yr',
    '100 Yr',
    '> 100 Yr',
    'Unknown',
  ];
}
