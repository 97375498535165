


















































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'vue-property-decorator';
import ReportCalendarInput from '../ReportHelpers/ReportInputHelpers/ReportCalendarInput.vue';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportFooter from '../ReportFooter.vue';
import ReportInterface, {
  WorkOrderfieldType,
} from '../ReportHelpers/ReportInterface.vue';
import { GenericWorkFields } from './types';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import ReportAssignedToDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportAssignedToDropDownInput.vue';

@Component({
  components: {
    ReportFooter,
    ReportCalendarInput,
    ReportDropDownInput,
    ReportTextInput,
    ReportAssignedToDropDownInput,
  },
})
export default class GenericReport extends ReportInterface {
  // #region Abstract fields
  workOrderData: GenericWorkFields | any = {};

  workOrderFields = [
    { name: 'assignedTo', value: 'Users', dontUpdateInProgress: true },
    { name: 'dateIssued', value: 'CreatedDate', type: WorkOrderfieldType.DATE },
    {
      name: 'planDueDate', value: 'AssignDate', type: WorkOrderfieldType.DATE, dontUpdateInProgress: true,
    },
    { name: 'workDescription', value: 'WorkPerformed' },
    { name: 'comments', value: 'Comments' },
    { name: 'dateCompleted', value: 'InspDate', type: WorkOrderfieldType.DATE },
    { name: 'streetAddress', value: 'Street' },
    { name: 'followUp', value: 'FollowUp' },
    { name: 'activity', value: 'Activity' },
    { name: 'specialInstructions', value: 'Instructions' },
    { name: 'specialConditions', value: 'SpecialCondition' },
    { name: 'followUp', value: 'FUComments' },
  ];
  // #endregion
}
