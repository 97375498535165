export const INTEGRITY_VERSION = '1.0.0';

export const NODEITEM_LINESEGMENT_GUID = '83dd294a-888a-11e9-a8ef-07fd9c6148c7';
export const NODEITEM_MANHOLE_GUID = '82ea0cf6-888a-11e9-a8ee-47191578d461';

export const CATEGORY_ID_WORKTYPE = 'cf0fbdbc-9707-11ea-9cf4-eb6f8778e29f';
export const CATEGORY_ID_PRIORITY = 'cf12207e-9707-11ea-9cfe-6f062ca617ff';
export const CATEGORY_ID_INSPECTIONRESULT = 'cf12207b-9707-11ea-9cfb-a7dc5e39d9d1';

export const TASK_RESULT_SUCCEEDED = 'c2ef4e2b-f2f0-4455-bd3f-da14b2ad4685';
export const TASK_RESULT_FAILED = '0bfea150-f525-4525-9e09-00a5765c5781';

export const QA_SOLO_DATA_TASK = '09afaa83-a586-45fe-87e6-f3a12400d1c9';
export const SOLO_DATA_COLLECTION_TASK = '1248fb15-3548-41b1-a8bf-b5968d5cc2ff';
export const UPLOAD_MEDIA_TASK = 'ab671dd0-f5e0-11ea-adc1-0242ac120002';
export const COPY_FILE_TASK = '34477504-a5bc-40ed-b495-954bbd16b600';
export const COPY_FOLDER_TASK = '9f25102e-8655-4e3f-8bf8-00de89282c7b';
export const GPS_COLLECTION_TASK = 'c971ce8b-91de-11e9-811b-83dcb8e142d4';
export const VERTUE_COLLECTION_TASK = 'e2f797a0-db6f-432c-9d59-c94fb68c1161';
export const SOLO_MANHOLE_COLLECTION_TASK = '34ff1c00-11aa-11ed-89bf-e78f74205981';
export const P3D_COLLECTION_TASK = '2180e6e0-1ca9-11ed-b6cf-5f0bd137feea';
export const RESPONDER_COLLECTION_TASK = '533808e0-1e33-11ed-9850-a7a26c8f2650';
export const PROFILER_COLLECTION_TASK = '533c9cc0-1e33-11ed-9850-ab1217bae360';
export const MACP_LEVEL_1_COLLECTION_TASK = 'e6ba9f20-668f-11ed-b3f9-a39b82ae3c50';
export const MACP_LEVEL_2_COLLECTION_TASK = '62927d20-66b3-11ed-b3f9-cf4bca2f030d';
export const PREMIUM_COLLECTION_TASK = 'c6b51280-6691-11ed-b3f9-5fb444f5b17b';
export const PACP_COLLECTION_TASK = 'a43b5ce4-2299-11ea-97cd-0ba9dd4363f8';
export const MSI_COLLECTION_TASK = '66973f80-6692-11ed-b3f9-7b2b8e37f682';
export const CCTV_COLLECTION_TASK = '99224d40-4c2f-11ee-be34-e331ce0af0d7';

export const WORK_ORDER_STATUS_OPEN = '68ae6161-b658-11ee-855a-57dfa579c14d';
export const WORK_ORDER_STATUS_SCHEDULED = '68ae6160-b658-11ee-855a-0badcebd3425';
export const WORK_ORDER_STATUS_INPROGRESS = 'c971ce87-91de-11e9-8117-93fb3d6b7255';
export const WORK_ORDER_STATUS_FOLLOWUPREQUIRED = '07c51d80-b658-11ee-855a-0bfa80f10f0c';
export const WORK_ORDER_STATUS_COMPLETE = 'c971ce88-91de-11e9-8118-2b9ff3ef52cb';

export const PRIORITY_HIGH = '81551d40-4c48-11ea-837c-6ffcbb49c39c';
export const PRIORITY_MEDIUM = '8154cf2a-4c48-11ea-837b-b3292e55d534';
export const PRIORITY_LOW = '814df268-4c48-11ea-837a-4f3c4a95d728';

export const DATASTANDARD_MACP7_LEVEL1 = 'ea5518e0-2963-11ed-a65b-77d84cf14bcb';
export const DATASTANDARD_MACP7_LEVEL2 = 'ea614de0-2963-11ed-a65b-7bb47ad3378c';
export const DATASTANDARD_SERVICE_CALL = 'c43155e0-71aa-11ed-ae7f-cfc9b666cbba';
export const REDZONE_ORGANIZATION_GUID = 'de7a7bf3-d09c-480c-94f5-37dc9e81231a';
export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const ALLOWED_DEPLOYMENT_FORM_TASK_TYPES = [
  RESPONDER_COLLECTION_TASK,
  CCTV_COLLECTION_TASK,
  PROFILER_COLLECTION_TASK,
];

export const PRIORITY_COLOR_LOOKUP = {
  '81551d40-4c48-11ea-837c-6ffcbb49c39c': 'red',
  '8154cf2a-4c48-11ea-837b-b3292e55d534': 'yellow',
  '814df268-4c48-11ea-837a-4f3c4a95d728': 'green',
};

export const FOG_TASK_TYPE = '3a584afb-3e70-467b-b100-e0196732f531';
export const CLEANING_TASK_TYPE = '3f896960-34bf-11eb-9f84-8f3124dc2b2a';
export const GENERAL_MAINTENANCE_TASK_TYPE = '3f896960-34bf-11eb-9f84-8f3124dc2b3b';
export const SERVICE_CALL_TASK_TYPE = '3f896960-34bf-11eb-9f84-8f3124dc2b4c';
export const SSO_TASK_TYPE = '3f896960-34bf-11eb-9f84-8f3124dc2b6e';
export const REPAIR_TASK_TYPE = 'c8233323-c07d-4a38-937d-d6a88da8aab7';

export const TASK_TYPE_STRING = [
  {
    guid: 'ab671dd0-f5e0-11ea-adc1-0242ac120002',
    desc: 'Media',
  },
  {
    guid: 'c971ce8b-91de-11e9-811b-83dcb8e142d4',
    desc: 'GPS',
  },
  {
    guid: 'f2a65a61-2a1a-47b3-8267-0b45be7983e2',
    desc: 'MACP',
  },
  {
    guid: 'e6ba9f20-668f-11ed-b3f9-a39b82ae3c50',
    desc: 'Level 1',
  },
  {
    guid: '62927d20-66b3-11ed-b3f9-cf4bca2f030d',
    desc: 'Level 2',
  },
  {
    guid: 'e2f797a0-db6f-432c-9d59-c94fb68c1161',
    desc: 'Vertue',
  },
  {
    guid: '34ff1c00-11aa-11ed-89bf-e78f74205981',
    desc: 'Solo Manhole',
  },
  {
    guid: '2180e6e0-1ca9-11ed-b6cf-5f0bd137feea',
    desc: 'P3D',
  },
  {
    guid: '1248fb15-3548-41b1-a8bf-b5968d5cc2ff',
    desc: 'Solo',
  },
  {
    guid: 'a43b5ce4-2299-11ea-97cd-0ba9dd4363f8',
    desc: 'PACP Inspection',
  },
  {
    guid: '66973f80-6692-11ed-b3f9-7b2b8e37f682',
    desc: 'MSI Collection',
  },
  {
    guid: 'c6b51280-6691-11ed-b3f9-5fb444f5b17b',
    desc: 'Premium',
  },
  {
    guid: CLEANING_TASK_TYPE,
    desc: 'Cleaning Work Order',
  },
  {
    guid: GENERAL_MAINTENANCE_TASK_TYPE,
    desc: 'General Maintenance',
  },
  {
    guid: '1502aac0-bd7e-11ef-a608-a39c6cc7536e',
    desc: 'Pipe Relining',
  },
  {
    guid: SERVICE_CALL_TASK_TYPE,
    desc: 'Service Call',
  },
  {
    guid: '3f896960-34bf-11eb-9f84-8f3124dc2b5d',
    desc: 'Structure Inspection',
  },
  {
    guid: SSO_TASK_TYPE,
    desc: 'SSO Report',
  },
  {
    guid: 'a43b0ece-2299-11ea-97ca-f7e5a3fb6eaa',
    desc: 'MACP 6.0 Inspection',
  },
  {
    guid: '4517d486-3542-4215-8123-3c76e5182135',
    desc: 'Get Customer Data',
  },
  {
    guid: '37ddb3e1-208e-4f0a-88ac-626290bbe289',
    desc: 'Locate Deployment Assignment',
  },
  {
    guid: '2069e081-7e17-11ec-8002-bfb692e0d7d5',
    desc: 'QA Align',
  },
  {
    guid: '09afaa83-a586-45fe-87e6-f3a12400d1c9',
    desc: 'QA Deployment Data',
  },
  {
    guid: 'bc0f831a-f9dd-11ea-8089-efb083a1dada',
    desc: 'Merge into BaseCamp from ICOM',
  },
  {
    guid: '09afaa83-a586-45fe-87e6-f3a12400d1c7',
    desc: 'QA Form Data',
  },
  {
    guid: '3f896960-34bf-11eb-9f84-8f3124dc2b1a',
    desc: 'Vertue Inspection',
  },
  {
    guid: '21cb2320-face-11eb-9da6-eb11117e8f6c',
    desc: 'Process Deployment Data',
  },
  {
    guid: '533808e0-1e33-11ed-9850-a7a26c8f2650',
    desc: 'Responder',
  },
  {
    guid: '533c9cc0-1e33-11ed-9850-ab1217bae360',
    desc: 'Profiler',
  },
  {
    guid: '72e86d80-f659-11ec-903e-5f603c6c35dc',
    desc: 'Ready To Code',
  },
  {
    guid: '2069e084-7e17-11ec-8002-6b2cc58f0508',
    desc: 'Export',
  },
  {
    guid: 'a43a9994-2299-11ea-97c6-4b40c205137a',
    desc: 'Line Segment Data Transfer',
  },
  {
    guid: '2069e080-7e17-11ec-8002-0bcc48172bca',
    desc: 'Align',
  },
  {
    guid: '34477504-a5bc-40ed-b495-954bbd16b600',
    desc: 'Copy File',
  },
  {
    guid: '9f25102e-8655-4e3f-8bf8-00de89282c7b',
    desc: 'Copy Folder',
  },
  {
    guid: 'a43ac0a5-2299-11ea-97c8-633382f1db7b',
    desc: 'Default Line Segment Inspection',
  },
  {
    guid: 'a43ac0a4-2299-11ea-97c7-5b007a817db8',
    desc: 'Default Manhole Inspection',
  },
  {
    guid: '2605bf70-599b-11ea-9ab2-bb4d9774428f',
    desc: 'Default MH Connection Inspection',
  },
  {
    guid: 'a439d644-2299-11ea-97c5-c3abf0a75449',
    desc: 'Manhole Data Transfer',
  },
  {
    guid: '2069e082-7e17-11ec-8002-b795405ab837',
    desc: 'Mesh',
  },
  {
    guid: 'a43b35de-2299-11ea-97cc-93dc49945d95',
    desc: 'PACP 6.0 Inspection',
  },
  {
    guid: '2069e083-7e17-11ec-8002-5bdcac4f9af7',
    desc: 'QA Mesh',
  },
  {
    guid: 'a43ae7b4-2299-11ea-97c9-67c22a88665a',
    desc: 'RZMACP Inspection',
  },
  {
    guid: FOG_TASK_TYPE,
    desc: 'FOG Inspection',
  },
  {
    guid: REPAIR_TASK_TYPE,
    desc: 'Repair',
  },
  {
    guid: '34cd5540-0abe-11ee-b661-975bb0631ca6',
    desc: 'WinCan Job',
  },
  {
    guid: 'e2d49000-9dca-11ee-84ba-67b294870e0c',
    desc: 'Generic',
  },
  {
    guid: 'c2210700-c6c7-11ee-9cfc-7be9a9162366',
    desc: 'Smoke Test',
  },
  {
    guid: CCTV_COLLECTION_TASK,
    desc: 'CCTV',
  },
];

export const TASK_RESULTS = {
  'c2ef4e2b-f2f0-4455-bd3f-da14b2ad4685': 'Succeeded',
  '0bfea150-f525-4525-9e09-00a5765c5781': 'Failed',
  'ab59b6d2-5ee2-48b5-8268-a42bd82cd434': 'Inspection pending',
  '6698c493-e8f1-4ca7-90de-59dd0641000c': 'Could not locate',
  'b80f3559-3e84-4cb5-b4d8-e1b2d1c590ed': 'Could not access',
  '3c5288f6-9300-40c4-8cfc-b86d7e16afd4': 'Does not exist',
  'de1223c9-9ff6-43ac-a39a-21f8bcada9c6': 'Complete',
  '337fbabb-37be-45e5-8158-40bfbf317be5': 'Traffic prevented access',
  'd69d511d-ac17-4208-88da-ebcb41aaa819': 'Located but buried',
  '5e365778-796d-4bd3-8216-9e8abbd8cf96': 'Unable to open access point',
  'd8239006-f30e-4ee9-b458-e44ab641921e': 'Access point severely surcharged/Too much debris',
  '4d18878f-f772-46ac-bd49-ff9c3f715f5c': 'Unknown',
};

export const TASK_RESULT_DETAILS = [
  // {
  //   guid: 'c2ef4e2b-f2f0-4455-bd3f-da14b2ad4685',
  //   name: 'S',
  //   description: 'Succeeded',
  // },
  // {
  //   guid: 'c2ef4e2b-f2f0-4455-bd3f-da14b2ad4686',
  //   name: 'C',
  //   description: 'Completed',
  // },
  {
    guid: 'de1223c9-9ff6-43ac-a39a-21f8bcada9c6',
    name: 'C',
    description: 'Complete',
  },
  {
    guid: '0bfea150-f525-4525-9e09-00a5765c5781',
    name: 'F',
    description: 'Incomplete',
  },
  {
    guid: 'ab59b6d2-5ee2-48b5-8268-a42bd82cd434',
    name: 'IP',
    description: 'Inspection Pending',
  },
  {
    guid: '6698c493-e8f1-4ca7-90de-59dd0641000c',
    name: 'CNL',
    description: 'Could Not Locate',
  },
  {
    guid: 'b80f3559-3e84-4cb5-b4d8-e1b2d1c590ed',
    name: 'CNA',
    description: 'Could Not Access',
  },
  {
    guid: '3c5288f6-9300-40c4-8cfc-b86d7e16afd4',
    name: 'DNE',
    description: 'Does Not Exist',
  },
  {
    guid: '337fbabb-37be-45e5-8158-40bfbf317be5',
    name: 'CNF',
    description: 'Traffic Prevented Access',
  },
  {
    guid: 'd69d511d-ac17-4208-88da-ebcb41aaa819',
    name: 'BML',
    description: 'Located But Buried',
  },
  {
    guid: '5e365778-796d-4bd3-8216-9e8abbd8cf96',
    name: 'CNO',
    description: 'Unable To Open Access Point',
  },
  {
    guid: 'd8239006-f30e-4ee9-b458-e44ab641921e',
    name: 'CSD',
    description: 'Access Point Severely Surcharged/Too Much Debris',
  },
  {
    guid: '4d18878f-f772-46ac-bd49-ff9c3f715f5c',
    name: 'UNK',
    description: 'Unknown',
  },
  {
    guid: '4d7ad360-1749-11ed-aaed-d78a73158801',
    name: 'IP',
    description: 'In Process',
  },
  {
    guid: '52a76c40-bc7a-11ee-81fa-9bd6e58db052',
    name: 'OT',
    description: 'Other',
  },
  {
    guid: '22b9d4a0-bc7a-11ee-81fa-3b8a6573f435',
    name: 'TCR',
    description: 'Traffic Control Required',
  },
  {
    guid: '10d6ab00-bc7a-11ee-81fa-d7871e8e1822',
    name: 'CRH',
    description: 'Cleaning Required (Heavy)',
  },
  {
    guid: 'f5a41520-bc79-11ee-81fa-6792298ff8f0',
    name: 'CRL',
    description: 'Cleaning Required (Light)',
  },
  {
    guid: '5f3cbe60-e20e-11ee-9a2b-638a8f3a3bc8',
    name: 'RR',
    description: 'Reversal Required',
  },
];

export const WORK_ORDER_STATUSES = [
  {
    guid: 'c971ce88-91de-11e9-8118-2b9ff3ef52cb',
    short_name: 'C',
    description: 'Complete',
    category: {
      guid: 'cf0fbdbb-9707-11ea-9cf3-53caadd1fdce',
      name: 'WorkOrderStatus',
    },
  },
  {
    guid: '68ae6161-b658-11ee-855a-57dfa579c14d',
    short_name: 'O',
    description: 'Open',
    category: {
      guid: 'cf0fbdbb-9707-11ea-9cf3-53caadd1fdce',
      name: 'WorkOrderStatus',
    },
  },
  {
    guid: '68ae6160-b658-11ee-855a-0badcebd3425',
    short_name: 'S',
    description: 'Scheduled',
    category: {
      guid: 'cf0fbdbb-9707-11ea-9cf3-53caadd1fdce',
      name: 'WorkOrderStatus',
    },
  },
  {
    guid: 'c971ce87-91de-11e9-8117-93fb3d6b7255',
    short_name: 'IP',
    description: 'In Progress',
    category: {
      guid: 'cf0fbdbb-9707-11ea-9cf3-53caadd1fdce',
      name: 'WorkOrderStatus',
    },
  },
  {
    guid: '07c51d80-b658-11ee-855a-0bfa80f10f0c',
    short_name: 'FUR',
    description: 'Follow-Up Required',
    category: {
      guid: 'cf0fbdbb-9707-11ea-9cf3-53caadd1fdce',
      name: 'WorkOrderStatus',
    },
  },
  {
    guid: 'c971ce88-91de-11e9-8118-2b9ff3ef52cb',
    short_name: 'C',
    description: 'Complete',
    category: {
      guid: 'cf0fbdbb-9707-11ea-9cf3-53caadd1fdce',
      name: 'WorkOrderStatus',
    },
  },
];

export const DEFECT_CATEGORIES = [
  {
    name: 'Breaks/Broken',
    defects: ['B', 'BSV', 'BVV'],
    isStructural: true,
  },
  {
    name: 'Holes',
    defects: ['H', 'HSV', 'HVV'],
    isStructural: true,
  },
  {
    name: 'Collapse',
    defects: ['X'],
    isStructural: true,
  },
  {
    name: 'Deformation',
    defects: ['D', 'DFBI', 'DFBR', 'DFC', 'DFE', 'DH', 'DR', 'DTBI', 'DTBR', 'DV'],
    isStructural: true,
  },
  {
    name: 'Cracks',
    defects: ['CM', 'CS', 'CL', 'CH'],
    isStructural: true,
  },
  {
    name: 'Fractures',
    defects: ['FM', 'FS', 'FL', 'FH'],
    isStructural: true,
  },
  {
    name: 'Surface Damages',
    defects: ['SAM', 'SAP', 'SAV', 'SCP', 'SMW', 'SRC', 'SRI', 'SRP', 'SRV', 'SSS', 'SZ'],
    isStructural: true,
  },
  {
    name: 'FOG',
    defects: ['DAGS'],
    isStructural: false,
  },
  {
    name: 'Encrustation',
    defects: ['DAE'],
    isStructural: false,
  },
  {
    name: 'Roots',
    defects: ['RBB', 'RBC', 'RBJ', 'RBL', 'RFB', 'RFC', 'RFJ', 'RFL', 'RMB', 'RMC', 'RMJ', 'RML', 'RTB', 'RTC', 'RTJ', 'RTL'],
    isStructural: false,
  },
  {
    name: 'Obstructions',
    defects: ['OBR', 'OBZ', 'OBN'],
    isStructural: false,
  },
  {
    name: 'Crossbore',
    defects: ['OBP'],
    isStructural: false,
  },
];

export const PROCESSING_STATUS = [
  {
    name: 'In Process',
    guid: 'a328f2c0-4a9f-11ef-850d-939f8a834a3f',
  },
  {
    name: 'Pending Media',
    guid: '9a508b40-4a9f-11ef-850d-dfdeecbdaace',
  },
  {
    name: 'Processing Failed',
    guid: 'a6a71d00-4a9f-11ef-850d-a34fedb42b02',
  },
  {
    name: 'Processed',
    guid: '6fc47bc0-7600-11ef-b0c6-0fb217eaf643',
  },
  {
    name: 'Ready for Processing',
    guid: '9e4a4e20-4a9f-11ef-850d-efda84882e68',
  },
  {
    name: 'Re-processing Needed',
    guid: 'aaad14e0-4a9f-11ef-850d-cb39825b153b',
  },
  {
    name: 'With Processing Support',
    guid: '6d0bf7c0-9165-11ef-97f7-9fc7a9dae3a6',
  },
  {
    name: 'Do Not Process',
    guid: '655bb960-ac3c-11ef-95c4-2332c74a73a5',
  },
];

export const PRIORITY_ITEMS = [
  { name: 'High', guid: PRIORITY_HIGH },
  { name: 'Medium', guid: PRIORITY_MEDIUM },
  { name: 'Low', guid: PRIORITY_LOW },
];

// File Import Mappings
export const FILE_MAPPING_MANHOLES_WITH_INSPECTIONS = 'c4cc5760-d446-11ef-af75-37d6bc7b1bbe';
export const FILE_MAPPING_MANHOLES_NO_INSPECTIONS = '9da4d7c0-d446-11ef-af75-2351e4cbd126';
export const FILE_MAPPING_MANHOLE_GIS_DATA = 'c4c7c380-d446-11ef-af75-b7a636dd32ee';
export const FILE_MAPPING_LINE_SEGMENT = '4a963820-d447-11ef-af75-9f8508e29da5';
export const FILE_MAPPING_LINE_SEGMENT_GIS_DATA = '63c9a2a0-d447-11ef-af75-6f3508441337';

export const FILE_MAPPING_OPERATOR_RESOURCE = '327963c0-de79-11ef-8d78-b7bdcd005d30';
export const FILE_MAPPING_EQUIPMENT_RESOURCE = 'ac758740-de78-11ef-8d78-3fa08181d8e8';
export const FILE_MAPPING_SERVICE_RESOURCE = '05c0b860-de79-11ef-8d78-c3cec3769d2d';
export const FILE_MAPPING_MATERIAL_RESOURCE = 'eb8553c0-de78-11ef-8d78-d3df1600dee0';
