





















































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'vue-property-decorator';
import ReportFooter from '../ReportFooter.vue';
import { StructureInspectionReportFields } from './types';
import ReportInterface, {
  WorkOrderfieldType,
} from '../ReportHelpers/ReportInterface.vue';
import ReportCalendarInput from '../ReportHelpers/ReportInputHelpers/ReportCalendarInput.vue';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import ReportAssignedToDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportAssignedToDropDownInput.vue';
import ReportRadioRowInput from '../ReportHelpers/ReportInputHelpers/ReportRadioRowInput.vue';

@Component({
  components: {
    ReportFooter,
    ReportCalendarInput,
    ReportDropDownInput,
    ReportTextInput,
    ReportAssignedToDropDownInput,
    ReportRadioRowInput,
  },
})
export default class StructureInspectionReport extends ReportInterface {
  // #region Abstract fields
  workOrderData: StructureInspectionReportFields | any = {};

  workOrderFields = [
    { name: 'assignedTo', value: 'Surveyor', dontUpdateInProgress: true },
    { name: 'activity', value: 'Activity' },
    { name: 'priority', value: 'Priority' },
    { name: 'area', value: 'Area' },
    { name: 'dateIssued', value: 'DateIssued', type: WorkOrderfieldType.DATE },
    {
      name: 'planDueDate',
      value: 'WorkCompletedBy',
      type: WorkOrderfieldType.DATE,
      dontUpdateInProgress: true,
    },
    { name: 'workDescription', value: 'Description' },
    { name: 'specialInstructions', value: 'SpecialInstruction' },
    { name: 'streetLocation', value: 'Street' },
    { name: 'crossStreet', value: 'CrossStreet' },
    { name: 'structureType', value: 'StructureType' },
    { name: 'specialConditions', value: 'SpecialConditions' },
    { name: 'dateCompleted', value: 'InspDate', type: WorkOrderfieldType.DATE },
    { name: 'conditionsGFP', value: 'ConditionGFP' },
    {
      name: 'followUpClean',
      value: 'FollowUp_Clean',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'followUpRepair',
      value: 'FollowUp_RepairRehab',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'followUpReInspect',
      value: 'FollowUp_ReInspect',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'followUpOther',
      value: 'FollowUp_Other',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'followUpNone',
      value: 'FollowUp_None',
      type: WorkOrderfieldType.BOOLEAN,
    },
    { name: 'inspectionComments', value: 'Comments' },
  ];

  // #endregion

  // #region Local variables and functions

  observedConditionsOptions = ['Good', 'Fair', 'Poor'];

  changeOption(itemVal: string, option: string): void {
    this.$set(this.workOrderData, itemVal, option);
  }

  get isNoneSelected(): boolean {
    return this.workOrderData.followUpNone === true;
  }

  get isFollowUpSelected(): boolean {
    return (
      this.workOrderData.followUpClean !== false
      || this.workOrderData.followUpRepair !== false
      || this.workOrderData.followUpReInspect !== false
      || this.workOrderData.followUpOther !== false
    );
  }

  isFollowUpRequired(): boolean {
    if (this.isNoneSelected) {
      return false;
    }
    return this.isFollowUpSelected;
  }

  // #endregion
}
