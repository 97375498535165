

















































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
import {
  Component, Prop, Watch,
} from 'vue-property-decorator';
import Ajv2019 from 'ajv/dist/2019';
import addFormats from 'ajv-formats';
import { namespace } from 'vuex-class';
import { InspectionActions } from '@/store/inspection/actions';
import VueHtml2pdf from 'vue-html2pdf';
import { AssetActions } from '@/store/asset/actions';
import { uuid } from 'vue-uuid';
import { InspectionMutations } from '@/store/inspection/mutations';
import { PlanningActions } from '@/store/planning/actions';
import {
  TASK_TYPE_STRING, WORK_ORDER_STATUSES,
  WORK_ORDER_STATUS_COMPLETE,
  WORK_ORDER_STATUS_FOLLOWUPREQUIRED,
  WORK_ORDER_STATUS_INPROGRESS,
  WORK_ORDER_STATUS_SCHEDULED,
} from '@/common/Constants';
import { ReportMutations } from '@/store/report/mutations';
import { GenericDropDownData } from '@/common/types';
import { getAllCrewMembers } from '@/common/utils/WorkOrderUtils';
import { UserActions } from '@/store/users/actions';
import { UserData } from '@/store/users/types';
import {
  CodingFormData,
  ConditionalRequirement,
  FormDataFillFunction,
  FormPageData,
  SubInspectionData,
} from './types';
import FormPage from './FormPage/FormPage.vue';
import Observation from '../Report/ObservationFullReport/ObservationFullReport.vue';
import CleaningWorkOrderReport from '../Report/Cleaning/CleaningWorkOrderReport.vue';
import PipeInspectionReport from '../Report/Pipe Inspection/PipeInspectionReport.vue';
import ServiceCallReport from '../Report/ServiceCall/ServiceCallReport.vue';
import StructureInspectionReport from '../Report/Structure Inspection/StructureInspectionReport.vue';
import SSOReportReport from '../Report/SSO/SSOReportReport.vue';
import GeneralMaintenanceReport from '../Report/General Maintenance/GeneralMaintenanceReport.vue';
import FOGInspectionReport from '../Report/FOG Inspection/FOGInspectionReport.vue';
import RepairReport from '../Report/Repair/RepairReport.vue';
import MACP2 from '../Report/MACP2/MACP2.vue';
import GenericReport from '../Report/GenericWork/GenericReport.vue';
import SmokeTestReport from '../Report/Smoke Test/SmokeTestReport.vue';
import PipeReliningReport from '../Report/PipeReliningReport/PipeReliningReport.vue';
import CodingFormCommonMixin from '../Report/CodingFormCommonMixin.vue';
import { timeAndMaterialsFields } from '../Report/ReportHelpers/types';

const inspectionModule = namespace('inspection');
const projectModule = namespace('project');
const assetModule = namespace('asset');
const planningModule = namespace('planning');
const reportModule = namespace('report');
const userModule = namespace('users');

@Component({
  components: {
    FormPage,
    CleaningWorkOrderReport,
    PipeInspectionReport,
    ServiceCallReport,
    StructureInspectionReport,
    SSOReportReport,
    GeneralMaintenanceReport,
    GenericReport,
    FOGInspectionReport,
    PipeReliningReport,
    RepairReport,
    MACP2,
    Observation,
    SmokeTestReport,
    VueHtml2pdf,
  },
})
export default class CodingForm extends CodingFormCommonMixin {
  @Prop({ default: null }) assetId: string;

  @Prop() popOutOpen: boolean;

  @Prop({ default: '' }) workOrderReport: string;

  @Prop({ default: null }) item: any;

  @Prop({ default: false }) hasModel: boolean;

  @Prop({ default: true }) enableReportExport: boolean;

  @Prop({ default: false }) readonly: boolean;

  @Prop({ default: true }) canEdit: boolean;

  @Prop({ default: true }) canPlan: boolean;

  @Prop() canExport: boolean;

  @Prop({ default: true }) displayForm: boolean;

  @projectModule.State('projectGuids') projectGuids: string[];

  @assetModule.Action(AssetActions.FETCH_ASSET_DATA) fetchAssetData;

  @inspectionModule.State('codingError') codingError: string | undefined;

  @inspectionModule.State('codingState') codingState: boolean;

  @inspectionModule.Action(InspectionActions.FETCH_CODING_DETAIL)
  fetchCodingDetail;

  @inspectionModule.Mutation(InspectionMutations.SET_CODING_DETAIL)
  setCodingDetail;

  @inspectionModule.Action(InspectionActions.PATCH_CODING_DETAIL)
  patchCodingDetail;

  @inspectionModule.Action(InspectionActions.DELETE_SUB_INSPECTION)
  deleteSubInspectionAction;

  @planningModule.State('patchWorkOrderLoading') patchWorkOrderLoading: boolean;

  @planningModule.Action(PlanningActions.PATCH_ASSIGNMENT) patchAssignmentList;

  @planningModule.Action(PlanningActions.PATCH_ASSIGNMENT_WORK_ORDER)
    patchAssignmentDataStandards;

  @planningModule.Action(PlanningActions.EDIT_ASSIGNMENT) editAssignment;

  @planningModule.Action(PlanningActions.FETCH_ASSIGNMENTS_DATA) fetchAssignmentsData;

  @userModule.Action(UserActions.FETCH_ALL_USER_DATA) fetchAllUserData;

  @userModule.State('allUserData') allUsers: UserData[] | undefined;

  @userModule.State('loading') allUserDataLoading: boolean;

  @reportModule.State('isEditMode') isEditModeInStore: boolean;

  @reportModule.Mutation(ReportMutations.SET_EDIT_STATE) setEditState;

  @reportModule.Mutation(ReportMutations.SET_EXPORTING_STATE) setExportingState;

  ajv = new Ajv2019({
    strict: false,
    strictSchema: false,
    strictTypes: false,
    strictRequired: false,
    strictNumbers: false,
    validateFormats: false,
    useDefaults: 'empty',
    allErrors: true,
    multipleOfPrecision: 6,
    unicodeRegExp: false,
  });

  validate = null;

  subValidate = null;

  subDataFormFlag = false;

  errors = [];

  subErrors = [];

  selectedPipeConnection = 0;

  currentFormPageData = [];

  reportJson = {};

  schedulingJson = {};

  showValidationResults = true;

  isPatched = false;

  hasDownloaded = true;

  reportRefreshKey = 0;

  processingForm = false;

  assetAttributes = null;

  // Field names for required fields
  requiredFields = new Map<string, boolean>();

  subSchemaNumberHeaders = ['PipeNumber', 'AssetNumber'];

  currentUser = null;

  hasChanged = false;

  get isEditMode(): boolean {
    return this.isEditModeInStore && !this.shouldDisableReportEdit;
  }

  get codingFormValidBool(): boolean {
    return (
      this.errors !== undefined
      && (this.errors.length > 0 || this.subErrors.length > 0 || this.codingState)
    );
  }

  get readOnlyFields(): string[] {
    const fields = this.subSchemaNumberHeaders;
    if (!this.canPlan) {
      fields.push('Assigned To');
    }
    return fields;
  }

  getCrewMembers(): GenericDropDownData[] {
    if (!this.doesUserDataNeedLoaded && this.allUsers != null) {
      return getAllCrewMembers(this.allUsers) ?? [];
    }
    return [];
  }

  get doesUserDataNeedLoaded(): boolean {
    return this.allUsers == null && !this.allUserDataLoading;
  }

  get reportContainerClasses(): string[] {
    const returnValue = ['report-container'];
    if (!this.displayForm) {
      returnValue.push('report-container-work-order-form');
    }
    return returnValue;
  }

  get subHeader(): string {
    if (!this.displayForm) {
      return `${this.workOrderName}`;
    }
    return this.selectedType;
  }

  get surfacePhotos(): any[] {
    if (this.inspection?.report?.surfacePhotos instanceof Array
      || Array.isArray(this.inspection?.report?.surfacePhotos)
    ) {
      return this.inspection.report.surfacePhotos;
    }
    return [];
  }

  async mounted(): Promise<void> {
    if (!this.isPopout && this.codingDetail === null) return;
    this.currentUser = await this.$auth.getUser();
    if (this.doesUserDataNeedLoaded) {
      await this.fetchAllUserData();
    }
    const isEditableStatus = (this.item?.status === 'Open'
        || (this.item?.status === 'Scheduled'
          && this.currentUser?.id === this.item?.assignedtoUserGuid)
        || (this.item?.status === 'In Progress'
          && this.currentUser?.id === this.item?.assignedtoUserGuid));
    if (isEditableStatus) {
      this.setEditState(!this.shouldDisableReportEdit);
    } else {
      this.setEditState(false);
    }
    await this.populateForm();
  }

  @Watch('inspectionId')
  async onInspectionChange(): Promise<void> {
    await this.reset();
    await this.populateForm();
  }

  @Watch('selectedType')
  async selectedTypeChange(): Promise<void> {
    // TODO: split into functions
    // sub ds
    if (this.selectedType === undefined) {
      this.selectedType = this.selectedTypeOld;
      return;
    }
    if (this.selectedTypeOld === 'Report') {
      this.updateFromReport();
      this.updateFromReportSub();
    }
    if (this.selectedType === 'Report') {
      this.$nextTick(() => {
        const pdfReport = this.$refs.pdfReport as any;
        if (pdfReport?.updateReportData) {
          pdfReport.updateReportData(this.reportData);
        }
        if (pdfReport?.updateReportSubData) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const temp = this.codingDetailInspections.map((subForm, index) => ({
            subInspectionGuid: subForm.subInspectionGuid,
            jsonData: this.subFullCodingForm[index]?.reduce(
              (a, v) => ({ ...a, [v.header]: v.value }),
              {},
            ) ?? {},
          }));
          const reportData = {};
          this.fullCodingForm.forEach((data) => {
            reportData[data.header] = data.value;
          });
          pdfReport.updateReportData(reportData);
        }
      });
    }
    this.selectedTypeOld = this.selectedType;

    if (
      this.subPages.includes(this.selectedType)
      || this.selectedType === 'Pipe Connection'
    ) {
      this.subDataFormFlag = true;
      const formPage = this.subDataStandardFormat.formpage.find(
        (fp) => fp.pagename === this.selectedType,
      );

      if (formPage != null) {
        this.currentFormPageData = formPage.formpagedata as FormPageData[];
      }

      this.currentFormPageData.forEach((form: FormPageData) => {
        // look for prefilled in subform
        this.oldSubFormData.forEach((oldData) => {
          form.prefilled = false;
          if (
            oldData[form.headername] != null
            && oldData[form.headername].toString().trim() !== ''
          ) {
            form.prefilled = true;
          }
        });

        Object.assign(form, this.applyCommonFillFunction(form));

        // look for enums
        // TODO: De-jankify
        const properties = Object.entries(this.subDataStandardSchema).find(
          (e) => e[0] === 'properties',
        )[1];

        if (properties != null) {
          const currProp = Object.entries(properties).find(
            (p) => p[0] === form.headername,
          )[1];
          form.enum = currProp.enum;
        }
      });

      this.validateForm();
      return;
    }

    // ds
    this.subDataFormFlag = false;
    const isSubDataForm = this.subPages.includes(this.selectedType);
    this.subDataFormFlag = isSubDataForm;
    const currentDataStandardFormat = isSubDataForm
      ? this.subDataStandardFormat
      : this.dataStandardFormat;
    const formPage = currentDataStandardFormat.formpage.find(
      (fp) => fp.pagename === this.selectedType,
    );

    if (formPage && formPage.formpagedata) {
      this.currentFormPageData = formPage.formpagedata as FormPageData[];
    }

    this.currentFormPageData.forEach((form: FormPageData) => {
      // look for prefilled in main form
      const oldFormData = this.oldFormData[form.headername];
      form.prefilled = false;

      if (oldFormData != null && oldFormData.toString().trim() !== '') {
        form.prefilled = true;
      }

      form.required = this.requiredFields.has(form.headername)
        && this.requiredFields.get(form.headername);

      Object.assign(form, this.applyCommonFillFunction(form));

      // look for hints
      if (
        this.dataStandardJson?.Requirements
        && Object.keys(this.dataStandardJson.Requirements.HeaderValues).includes(
          form.headername,
        )
      ) {
        form.hint = this.dataStandardJson.Requirements.HeaderValues[form.headername];
      }

      // look for enums
      const currentSchema = isSubDataForm
        ? this.subDataStandardSchema
        : this.dataStandardSchema;
      const properties = Object.entries(currentSchema).find(
        (e) => e[0] === 'properties',
      )[1];

      if (properties === null) {
        return;
      }

      const currPropArr = Object.entries(properties).find(
        (p) => p[0] === form.headername,
      );

      if (
        currPropArr === undefined
        || currPropArr[1] === undefined
        || currPropArr[1].enum === undefined
      ) {
        return;
      }

      const currProp = currPropArr[1];
      form.enum = currProp.enum;
    });
    this.validateForm();
  }

  updateFromReport(): void {
    if (!this.reportJson || Object.keys(this.reportJson).length === 0) {
      return;
    }
    Object.assign(this.oldFormData, this.reportJson);
    this.reportJson = {};
    const reqs: string[] = [];

    this.pages.forEach((page) => {
      const formPage = this.dataStandardFormat.formpage.find(
        (fp) => fp.pagename === page,
      );
      if (formPage != null) {
        formPage.formpagedata.forEach((pageData) => {
          const oldVal = this.oldFormData[pageData.headername];

          const codingFormIndex = this.fullCodingForm.findIndex(
            (fc) => fc.header === pageData.headername,
          );

          // check if header has already been added, if it was, skip
          if (
            !this.isPopout
            && codingFormIndex === -1
          ) {
            this.fullCodingForm.push({
              header: pageData.headername,
              // instead of null we should check data type and fill as appropriate
              // strings = '' ints/nums = 0
              value: this.getCodingFormDataValue(oldVal, pageData),
              isChanged: !!(
                oldVal
                || (reqs && reqs.includes(pageData.headername))
              ),
              headerNumber: pageData.headernumber,
            });
          } else {
            this.fullCodingForm[codingFormIndex].value = this.getCodingFormDataValue(
              oldVal, pageData,
            );
          }
        });
      }
    });
  }

  @Watch('codingDetail')
  async oncodingDetailUpdate(): Promise<void> {
    await this.reset();

    if (this.codingDetail === undefined) return;

    this.codingDetailsUpdateFunc();

    // Ajv throws an error if we try to add formats if they already exist
    if (Object.keys(this.ajv.formats).length === 0) {
      addFormats(this.ajv);
    }
    this.validate = this.ajv.compile(this.dataStandardSchema);
    if (this.subDataStandardSchema) {
      this.subValidate = this.ajv.compile(this.subDataStandardSchema);
    }
    this.validateForm();
  }

  @Watch('selectedPipeConnection')
  onSelectedPipeConnectionChange(): void {
    if (this.selectedPipeConnection === undefined) {
      this.selectedPipeConnection = 1;
      return;
    }

    if (this.connectionsCount === 0) {
      this.subCodeIndex = -1;
      return;
    }

    this.subCodeIndex = this.selectedPipeConnection - 1;

    this.currentFormPageData.forEach((form: FormPageData) => {
      if (this.subCodeIndex === 0) {
        this.oldSubFormData.forEach((oldData) => {
          form.prefilled = false;
          if (
            oldData[form.headername] != null
            && oldData[form.headername].toString().trim() !== ''
          ) {
            form.prefilled = true;
          }
        });
      } else {
        this.oldSubFormData.forEach(() => {
          form.prefilled = false;
          if (this.subSchemaNumberHeaders.indexOf(form.headername) !== -1) {
            form.prefilled = true;
          }
        });
      }
    });
    this.validateForm();
  }

  async populateForm(): Promise<void> {
    if (this.isPopout && this.assetId === null) {
      window.opener.postMessage('Form Popout Loaded', window.location.origin);
      window.addEventListener(
        'message',
        this.popOutLoadEventReader,
      );

      const container = document.getElementsByClassName(
        'view-container',
      )[0] as HTMLElement;
      const header = document.getElementsByClassName(
        'navbarCustom',
      )[0] as HTMLElement;
      const footer = document.getElementsByClassName(
        'footer',
      )[0] as HTMLElement;
      const nav = document.getElementsByClassName(
        'v-navigation-drawer',
      )[0] as HTMLElement;
      const main = document.getElementsByClassName('v-main')[0] as HTMLElement;
      if (header) header.remove();
      if (footer) footer.remove();
      if (nav) nav.remove();
      main.style.padding = '0px';
      container.style.height = 'calc(100vh - 36px)';
      window.addEventListener('resize', () => {
        window.resizeTo(1200, 1000);
      });

      window.addEventListener('beforeunload', this.beforeUnloadPop, {
        capture: true,
      });
    }

    if (this.codingDetail !== undefined) {
      this.oncodingDetailUpdate();
    }

    this.sortSubFullCoding();

    if (this.item) {
      await this.fetchAssetData({
        assetId: this.item.nodeGuid,
        inspectionId: this.item.guid,
      });
    }
  }

  /**
   * @description Reads messages until popout recieves form data.
   *              Closes event listener after data is read
   * @param {MessageEvent} event events passed from message listener
   */
  async popOutLoadEventReader(event: MessageEvent<any>): Promise<void> {
    let closeListenerFlag = false;
    if (event.data.assetId) {
      this.assetId = event.data.assetId;
      closeListenerFlag = true;
    }
    if (event.data.inspectionId) {
      this.inspectionId = event.data.inspectionId;
      closeListenerFlag = true;
    }
    if (event.data.codingDetail) {
      this.codingDetail = event.data.codingDetail;
      closeListenerFlag = true;
    }
    if (event.data.inspection) {
      this.inspection = event.data.inspection;
      closeListenerFlag = true;
    }
    if (event.data.assetType) {
      this.assetType = event.data.assetType;
      closeListenerFlag = true;
    }
    if (event.data.fullCodingForm) {
      await this.setCodingForm(event.data.fullCodingForm);
      closeListenerFlag = true;
    }
    if (event.data.subFullCodingForm) {
      await this.setSubCodingForm(event.data.subFullCodingForm);
      closeListenerFlag = true;
    }

    if (this.assetId && this.inspectionId) {
      closeListenerFlag = true;
      await this.fetchAssetData({
        assetId: this.assetId,
        inspectionId: this.inspectionId,
      });
    }

    if (closeListenerFlag) {
      window.removeEventListener('message', this.popOutLoadEventReader);
    }
  }

  /**
   * @description Used to get the Button text to add an object to the subschema
   * @return String to render in the button
   */
  getSubSchemaButtonText() {
    const pageHeader = this.getPageHeader();
    switch (pageHeader) {
      case 'AssetNumber':
        return 'Add Asset';
      case 'PipeNumber':
        return 'Add Pipe Connection';
      default:
        return 'Error - No Number Field';
    }
  }

  /**
   * @description Used to get the function to call on submit
   * @return the function to call
   */
  getSubSchemaButtonSubmitFunction() {
    const pageHeader = this.getPageHeader();
    switch (pageHeader) {
      case 'AssetNumber':
        return this.addAssetConnection;
      case 'PipeNumber':
        return this.addPipeConnection;
      default:
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
    }
  }

  /**
   * @description Used to get the label for the object type in the subschema
   * @return the label for the object type
   */
  getSubSchemaLabelText() {
    const pageHeader = this.getPageHeader();
    switch (pageHeader) {
      case 'AssetNumber':
        return 'Asset';
      case 'PipeNumber':
        return 'Pipe Connection';
      default:
        return 'Object';
    }
  }

  formDataArrayToObject(codingForm: any, mainInspection: boolean): any {
    if (!codingForm) {
      return false;
    }
    const retVal = codingForm.reduce(
      (a, v) => ({ ...a, [v.header]: v.value }),
      {},
    );
    if (mainInspection) {
      // remove unchanged values
      const delHeaders = this.fullCodingForm
        .filter((fc) => fc.isChanged === false)
        .map((fc) => fc.header);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      delHeaders.forEach((header) => {
        // delete retVal[header];
      });
    }
    return retVal;
  }

  validateForm(): void {
    // TODO: eliminate side effects
    // update pipeConnections if we're on pipe connections
    // validate sub inspections if on pipe coinnections
    if (this.subDataFormFlag && this.subFullCodingForm.length > 0) {
      this.connectionsCount = this.subFullCodingForm.length;
      // validate sub form
      const valid = this.subValidate(
        this.formDataArrayToObject(
          this.subFullCodingForm.find(
            (cf) => cf.find(
              (it) => this.subSchemaNumberHeaders.indexOf(it.header) !== -1,
            ).value === this.subCodeIndex,
          ) !== undefined
            ? this.subFullCodingForm.find(
              (cf) => cf.find(
                (it) => this.subSchemaNumberHeaders.indexOf(it.header) !== -1,
              ).value === this.subCodeIndex,
            )
            : this.subFullCodingForm[this.subCodeIndex],
          false,
        ),
      );
      if (!valid && this.subValidate.errors != null) {
        this.subErrors = this.subValidate.errors;
      } else {
        this.subErrors = [];
      }
      return;
    }
    // validateForm
    this.applyRequiredFields(this.requiredFieldObjects);
    this.applyHintFields();
    this.autoFillAutoProcessedFields();
    const valid = this.validate(
      this.formDataArrayToObject(this.fullCodingForm, true),
    );
    if (!valid && this.validate.errors != null) {
      this.errors = this.validate.errors;
    } else {
      this.errors = [];
    }
  }

  saveFormAsDraft(): void {
    const workOrderStatus = this.hasChanged
      ? WORK_ORDER_STATUS_INPROGRESS
      : WORK_ORDER_STATUS_SCHEDULED;
    this.submitForm(workOrderStatus);
  }

  submitFormButtonClick(): void {
    const pdfReport = this.$refs.pdfReport as any;
    const workOrderStatus = pdfReport.isFollowUpRequired()
      ? WORK_ORDER_STATUS_FOLLOWUPREQUIRED
      : WORK_ORDER_STATUS_COMPLETE;
    this.submitForm(workOrderStatus);
  }

  async submitForm(workOrderStatus = null): Promise<void> {
    if (this.selectedTypeOld === 'Report') {
      this.updateFromReport();
      this.updateFromReportSub();
    }
    // update original json objects with updated data
    this.fullCodingForm.forEach((cf: CodingFormData) => {
      const newKey = cf.header;
      const newVal = cf.value;
      const newObj = { [newKey]: newVal };
      Object.assign(this.oldFormData, newObj);
      if (cf.isChanged === false) {
        // delete this.oldFormData[cf.header];
      }
    });

    const statusGuid = this.schedulingJson != null && this.schedulingJson['statusGuid'] != null
      ? this.schedulingJson['statusGuid']
      : WORK_ORDER_STATUSES.find(
        (wo) => wo.description === this.item?.status,
      )?.guid;
    const canStatusGuidChangeToInProcessing = statusGuid === '68ae6161-b658-11ee-855a-57dfa579c14d' // Open
      || statusGuid === '68ae6160-b658-11ee-855a-0badcebd3425'; // Scheduled
    // Set the status to inProgress
    if (this.isWorkOrderForm && canStatusGuidChangeToInProcessing) {
      if (this.schedulingJson == null) {
        this.schedulingJson = {};
      }
      this.schedulingJson['statusGuid'] = 'c971ce87-91de-11e9-8117-93fb3d6b7255'; // In Progress
    }

    if (this.isWorkOrderForm) {
      await this.patchAssignmentDataStandards({
        guid: this.item['guid'],
        payload: {
          inspectionJsonData: JSON.stringify(this.oldFormData),
          subInspectionJsonData: await this.formatSubInspections(),
        },
      });

      if (Object.keys(this.schedulingJson).length === 0) {
        const formPage = this.dataStandardFormat?.formpage;
        if (formPage != null && formPage.length > 0) {
          formPage.forEach((fp) => {
            const formPageData = fp?.formpagedata;
            if (formPageData != null && formPageData.length > 0) {
              formPageData.forEach((fpd) => {
                this.schedulingJson = this.applyCommonValues(fpd, this.schedulingJson);
              });
            }
          });
        }
      }

      if (Object.keys(this.schedulingJson).length > 0) {
        const inspectionData = {
          ...this.item,
          ...this.schedulingJson,
        };
        const editAssignmentList = [
          {
            assignedToUserGuid: inspectionData['assignedTo'],
            guid: inspectionData['guid'],
            startDate: inspectionData['scheduledStartDate'],
            endDate: inspectionData['scheduledDueDate'],
            completedDate: inspectionData['completeDate'],
            priorityItemGuid: inspectionData['priorityItemGuid'],
            taskTypeGuid: inspectionData['taskTypeGuid'],
            resourceGroupGuid: inspectionData['resourceGroupGuid'],
            statusGuid: workOrderStatus || inspectionData['statusGuid'],
            priorityGuid: inspectionData['priorityGuid'],
          },
        ];
        await this.editAssignment(editAssignmentList);
        const date = new Date(0).toISOString();
        // Reload the table with updated changes
        this.fetchAssignmentsData({
          dateCutoff: date,
          taskTypeGuids: [],
          projectGuids: this.projectGuids,
        });
      }
      this.$emit('patchWorkOrder');
      return;
    }

    this.processingForm = true;
    const result = await this.patchCodingDetail({
      inspectionGuid: this.inspection?.guid != null
        ? this.inspection.guid
        : this.inspectionId,
      payload: {
        inspectionJsonData: JSON.stringify(this.oldFormData),
        subInspectionJsonData: await this.formatSubInspections(),
      },
    });
    this.updateCodingDetail();

    if (result === true) {
      this.isPatched = true;
    }

    this.sortSubFullCoding();
    this.processingForm = false;
  }

  /**
   * @description Used to add a pipe connection to the subschema
   */
  addPipeConnection(): void {
    const tempData = [];
    this.subPages.forEach((page) => {
      this.subDataStandardFormat.formpage
        .find((fp) => fp.pagename === page)
        .formpagedata.forEach((pageData) => {
          tempData.push({
            header: pageData.headername,
            // instead of null we should check data type and fill as appropriate
            // strings = '' ints/nums = 0
            value: this.getCodingFormDataValue(null, pageData),
          });
        });
    });
    let newTempDataSubFullCodingFormValue = 1;
    if (
      this.connectionsCount > 0
      && this.subFullCodingForm.length >= this.connectionsCount - 1
      && this.subFullCodingForm[this.connectionsCount - 1].length > 0
      && this.subFullCodingForm[this.connectionsCount - 1][0].value != null
    ) {
      newTempDataSubFullCodingFormValue = this.subFullCodingForm[
        this.connectionsCount - 1][0].value + 1;
    }
    tempData.find((cd) => cd.header === 'PipeNumber').value = newTempDataSubFullCodingFormValue;
    this.subFullCodingForm.push(tempData);
    this.codingDetailInspections.push({
      subInspectionGuid: uuid.v4(),
      jsonData: tempData,
    });
    this.connectionsCount = this.subFullCodingForm.map((cf) => cf.find((it) => it.header === 'Structure')).length;
    this.sortSubFullCoding();
  }

  /**
   * @description Used to add an asset to the subschema
   */
  addAssetConnection(): void {
    const tempData = [];
    this.subPages.forEach((page) => {
      this.subDataStandardFormat.formpage
        .find((fp) => fp.pagename === page)
        .formpagedata.forEach((pageData) => {
          tempData.push({
            header: pageData.headername,
            // instead of null we should check data type and fill as appropriate
            // strings = '' ints/nums = 0
            value: this.getCodingFormDataValue(null, pageData),
          });
        });
    });
    let newTempDataSubFullCodingFormValue = 1;
    if (
      this.connectionsCount > 0
      && this.subFullCodingForm.length >= this.connectionsCount - 1
      && this.subFullCodingForm[this.connectionsCount - 1].length > 0
      && this.subFullCodingForm[this.connectionsCount - 1][0].value != null
    ) {
      newTempDataSubFullCodingFormValue = this.subFullCodingForm[
        this.connectionsCount - 1][0].value + 1;
    }
    tempData.find((cd) => cd.header === 'AssetNumber').value = newTempDataSubFullCodingFormValue;
    this.subFullCodingForm.push(tempData);
    this.codingDetailInspections.push({
      subInspectionGuid: uuid.v4(),
      jsonData: tempData,
    });
    this.connectionsCount = this.subFullCodingForm.map((cf) => cf.find((it) => it.header === 'AssetNumber')).length;
    this.sortSubFullCoding();
  }

  updateFromReportSub(): void {
    const pdfReport = this.$refs.pdfReport as any;
    if (!pdfReport?.subInspectionData) {
      return;
    }
    const subInspectionData = (pdfReport.subInspectionData as any[]);
    if (!subInspectionData || subInspectionData.length === 0) {
      return;
    }
    this.codingDetailInspections = subInspectionData.map((x) => ({
      jsonData: JSON.stringify(x.jsonData),
      subInspectionGuid: x.subInspectionGuid,
    }));

    this.oldSubFormData = subInspectionData;

    const ret = [];
    const subCheckboxNums = [];
    this.oldSubFormData.forEach((oldData) => {
      const uniqueNums = [];
      const tempData = [];
      this.subPages.forEach((page) => {
        this.subDataStandardFormat.formpage
          .find((fp) => fp.pagename === page)
          .formpagedata.forEach((pageData) => {
            const oldVal = oldData.jsonData[pageData.headername];
            tempData.push({
              header: pageData.headername,
              value: this.getCodingFormDataValue(oldVal, pageData),
              // eslint-disable-next-line no-unneeded-ternary
              isChanged: !!oldVal,
              headerNumber: pageData.headernumber,
            });
            // look for checkbox values
            const currNum = pageData.headernumber;
            if (!uniqueNums.includes(currNum)) {
              uniqueNums.push(currNum);
            } else {
              subCheckboxNums.push(currNum);
            }
          });
      });
      ret.push(tempData);
    });
    this.subCheckBoxHeaderNumbers = subCheckboxNums;
    this.setSubCodingForm(ret);
    this.connectionsCount = this.subFullCodingForm.length;
    this.subCodeIndex = this.connectionsCount > 0 ? 0 : -1;

    this.connectionsCount = this.subFullCodingForm.map((cf) => cf.find((it) => it.header === 'Structure')).length;
  }

  async formatSubInspections(): Promise<SubInspectionData[]> {
    const retVal = [] as SubInspectionData[];
    this.sortSubFullCoding();
    // update old data with new data
    this.oldSubFormData.forEach((sfd, i) => {
      this.subFullCodingForm[i].forEach((cf: CodingFormData) => {
        // eslint-disable-next-line no-param-reassign
        sfd[cf.header] = cf.value;
      });
    });

    // check if new sub insps
    if (this.oldSubFormData.length < this.subFullCodingForm.length) {
      const newSubInspections = this.subFullCodingForm.splice(
        this.oldSubFormData.length,
      );
      // format new sub inspections
      newSubInspections.forEach((nsi, i) => {
        newSubInspections[i] = nsi.reduce(
          (a, v) => ({ ...a, [v.header]: v.value }),
          {},
        );
      });
      // add to old sub form data
      this.oldSubFormData.push(...newSubInspections);
    }
    // fill object
    this.oldSubFormData.forEach((element, i) => {
      const currCodeDetail = this.codingDetail.inspections[this.inspectionIndex]
        .subInspectionJsonData[i] as SubInspectionData;
      const subInspectionGuid = currCodeDetail && currCodeDetail.subInspectionGuid != null
        ? currCodeDetail.subInspectionGuid
        : this.codingDetailInspections[i].subInspectionGuid;

      retVal.push({
        subInspectionGuid,
        jsonData: JSON.stringify(element),
      });
    });

    // re-setup subFullCodingForm
    await this.setSubCodingForm([]);
    if (this.subDataStandardFormat != null) {
      this.oldSubFormData.forEach((oldData) => {
        const tempData = [];
        this.subPages.forEach((page) => {
          this.subDataStandardFormat.formpage
            .find((fp) => fp.pagename === page)
            .formpagedata.forEach((pageData) => {
              const oldVal = oldData[pageData.headername];
              tempData.push({
                header: pageData.headername,
                value: this.getCodingFormDataValue(oldVal, pageData),
              });
            });
        });
        this.subFullCodingForm.push(tempData);
      });
    }
    return retVal;
  }

  async deleteSubInspection(subInspIndex: number): Promise<void> {
    let deleteGuid = '';

    // check if this sub was remote
    if (subInspIndex <= this.oldSubFormData.length) {
      const subInspOrig = this.codingDetailInspections[
        subInspIndex
      ] as SubInspectionData;
      const inpectionExists = subInspOrig != null && subInspOrig.jsonData != null;

      if (inpectionExists) {
        // Delete remote
        deleteGuid = subInspOrig.subInspectionGuid;
        await this.deleteSubInspectionAction(deleteGuid);
      }
    }

    // Delete locally
    this.subFullCodingForm.splice(subInspIndex, 1);
    this.codingDetailInspections.splice(subInspIndex, 1);
    if (this.oldSubFormData.length >= subInspIndex) {
      this.oldSubFormData.splice(subInspIndex, 1);
    }

    this.connectionsCount = this.subFullCodingForm.length;
    this.selectedPipeConnection -= 1;
    this.sortSubFullCoding();
  }

  // false means not empty
  isEmptyValue(value: string | number | boolean | []): boolean {
    if (typeof value === 'string') return (value as string).length === 0;
    if (Array.isArray(value)) return value.length === 0;
    return false;
  }

  popOutForm(): void {
    this.$emit('togglePopout');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  beforeUnloadPop(_event: any): void {
    window.opener.postMessage(
      {
        fullCodingForm: this.fullCodingForm,
        subFullCodingForm: this.subFullCodingForm,
      },
      window.location.origin,
    );
  }

  getErrorCount(formPageName: string): number {
    let retVal = 0;
    const formPage = this.dataStandardFormat.formpage.find(
      (fp) => fp.pagename === formPageName,
    );
    if (formPage !== null && formPage != null) {
      formPage.formpagedata.forEach((d) => {
        if (
          this.errors.find((e) => e.instancePath.split('/')[1] === d.headername)
        ) {
          retVal += 1;
        }
      });
    }
    return retVal;
  }

  applyHintFields(): void {
    this.currentFormPageData.forEach((form: FormPageData) => {
      // look for hints
      if (
        this.dataStandardJson?.Requirements
        && Object.keys(this.dataStandardJson.Requirements.HeaderValues).includes(
          form.headername,
        )
      ) {
        form.hint = this.dataStandardJson.Requirements.HeaderValues[form.headername];
      }
    });
  }

  applyRequiredFields(conditionalRequirement: ConditionalRequirement): void {
    // clear required fields
    this.requiredFields.clear();
    this.currentFormPageData.forEach((fpd) => {
      fpd.required = false;
    });
    this.applyRequiredFieldsRecursive(conditionalRequirement);
    // apply required to current form page
    this.requiredFields.forEach((value, requiredField) => {
      const field = this.currentFormPageData.find(
        (fpd) => fpd.headername === requiredField,
      );
      if (field !== undefined) {
        field.required = value;
      }
    });
  }

  applyRequiredFieldsRecursive(
    conditionalRequirement: ConditionalRequirement,
  ): void {
    let conditionalMet = false;
    const { conditionalField, comparerValue } = conditionalRequirement;
    // find field value
    const fieldValue = conditionalField === this.ALWAYSREQUIRED
      ? true
      : this.fullCodingForm.find((cf) => cf.header === conditionalField)
        ?.value;

    // compare to comparer values
    // skip if field is undefined
    if (fieldValue !== undefined) {
      // see if comparer value is valid depending on the conditional type
      // have to check it differently if it's an array
      if (Array.isArray(comparerValue)) {
        if (comparerValue.some((cv) => cv === (fieldValue as string))) {
          conditionalMet = true;
        }
      } else if (comparerValue === fieldValue) {
        conditionalMet = true;
      }
      // true, process on thens
      if (conditionalMet) {
        const { requiredOnThen, conditionalsOnThen, optionalOnThen } = conditionalRequirement;
        requiredOnThen.forEach((field) => {
          this.requiredFields.set(field, true);
        });
        optionalOnThen.forEach((field) => {
          this.requiredFields.set(field, false);
        });
        conditionalsOnThen.forEach((conditional) => {
          this.applyRequiredFieldsRecursive(conditional);
        });
      }
      // false, process on elses
      if (!conditionalMet) {
        const { requiredOnElse, conditionalsOnElse, optionalOnElse } = conditionalRequirement;
        requiredOnElse.forEach((field) => {
          this.requiredFields.set(field, true);
        });
        optionalOnElse.forEach((field) => {
          this.requiredFields.set(field, false);
        });
        conditionalsOnElse.forEach((conditional) => {
          this.applyRequiredFieldsRecursive(conditional);
        });
      }
    }
  }

  async reset(): Promise<void> {
    if (this.isPopout) return;
    this.pages = [];
    this.subPages = [];
    this.connectionsCount = 0;
    this.subDataFormFlag = false;
    this.subCodeIndex = -1;
    this.errors = [];
    this.subErrors = [];
    this.errorExists = false;
    this.error = '';
    this.selectedType = '';
    this.selectedPipeConnection = 0;
    this.currentFormPageData = [];
    this.oldSubFormData = [];
    this.checkBoxHeaderNumbers = [];
    this.oldFormData = {};
    this.codingDetailInspections = [];

    await this.setCodingForm([]);
    await this.setSubCodingForm([]);
  }

  getBackgroundColor(): any {
    const retVal = this.selectedType === 'Report'
      ? { background: '#CCCCCC' }
      : { background: 'white' };
    return retVal;
  }

  generateReport(): void {
    this.hasDownloaded = false;
    this.setEditState(false);
    this.setExportingState(true);
    // eslint-disable-next-line dot-notation
    this.$refs.html2Pdf['generatePdf']();
  }

  html2PdfHasDownloaded(): void {
    this.hasDownloaded = true;
    this.setExportingState(false);
  }

  autoFillAutoProcessedFields(): void {
    this.currentFormPageData.forEach((form: FormPageData) => {
      // look for hints
      if (form.hint != null && form.hint !== '') {
        const splitHint = form.hint.split(' ');
        const item = this.fullCodingForm.find(
          (f) => f.header === form.headername,
        );
        const val1 = this.fullCodingForm.find(
          (f) => f.header === splitHint[0],
        ).value;
        const val2 = this.fullCodingForm.find(
          (f) => f.header === splitHint[2],
        ).value;
        const operator = splitHint[1];
        switch (operator) {
          case '+':
            item.value = (val1 as number) + (val2 as number);
            break;
          case '-':
            item.value = (val1 as number) - (val2 as number);
            break;
          default:
            throw new Error('Invalid operator');
        }
      }
    });
  }

  /**
   * Uses a function to apply common functionity for datastandards that request it.
   * @param {FormPageData} formOriginal Form page data to check for common functionality and apply
   * @returns {FormPageData} the updated form page data
   */
  applyCommonFillFunction(formOriginal: FormPageData): FormPageData {
    const form = { ...formOriginal };
    const fillFunctionString = form?.fillFunctionMappingString;
    if (fillFunctionString != null) {
      // If we have a fill function, search for it.
      switch (fillFunctionString.toString().toLowerCase()) {
        case FormDataFillFunction.ASSIGNED_TO.toString().toLocaleLowerCase():
          if (this.allUsers) {
            form.enum = this.getCrewMembers();
            form.InputType = 'Dropdown';
          }
          break;
        default:
          break;
      }
    }
    return form;
  }

  /**
   *
   * @param formOriginal The form page data to use
   * @param schedulingJson The current version of scheduling data
   */
  applyCommonValues(form: FormPageData, schedulingJson: any): any {
    const schedulingJsonChanged = { ...schedulingJson };
    const fillFunctionString = form?.fillFunctionMappingString;
    if (fillFunctionString != null) {
      // If we have a fill function, search for it.
      switch (fillFunctionString.toString().toLowerCase()) {
        case FormDataFillFunction.ASSIGNED_TO.toString().toLocaleLowerCase():
        {
          const assignedToName = this.oldFormData[form.headername];
          const foundCrew = this.getCrewMembers()
            .find((crew) => crew.guid === assignedToName || crew.text === assignedToName);
          if (foundCrew?.guid != null) {
            schedulingJsonChanged['assignedTo'] = foundCrew.guid;
          }
          break;
        }
        default:
          break;
      }
    }
    return schedulingJsonChanged;
  }

  updateWorkOrderJson(workOrderJson: any): void {
    Object.assign(this.reportJson, workOrderJson);
  }

  updateAdditionalReportData(schedulingJson: any): void {
    Object.assign(this.schedulingJson, schedulingJson);
  }

  updateHasChanged(): void {
    this.hasChanged = true;
  }

  updateCodingDetail(): void {
    // Always update the codingDetail for legacy support
    const codingDetailUpdated = { ...this.codingDetail };
    if (codingDetailUpdated?.inspections != null && codingDetailUpdated.inspections.length > 0) {
      const legacySupportedInspection = codingDetailUpdated.inspections[0];
      legacySupportedInspection.jsonData = JSON.stringify(this.reportData);
      this.setCodingDetail(codingDetailUpdated);
    }
  }

  get reportData() {
    const reportData = {};
    if (this.fullCodingForm != null) {
      this.fullCodingForm.forEach((data) => {
        reportData[data.header] = data.value;
      });
    }
    timeAndMaterialsFields.forEach((field) => {
      reportData[field.name] = this.oldFormData[field.name] ?? [];
    });
    return reportData;
  }

  get workOrderName(): string {
    return TASK_TYPE_STRING.find((tts) => tts.guid === this.item.taskTypeGuid)?.desc ?? '';
  }

  get workOrderReportName(): string {
    return `${this.workOrderName} Report`;
  }

  get shouldDisableReportEdit(): boolean {
    if (!this.isWorkOrderForm) {
      return false;
    }
    return this.reportDisabledReason != null;
  }

  get reportDisabledReason(): string {
    if (!this.canEdit) {
      return 'Do Not Have Permission To Edit Work Order';
    }

    if (!this.item) {
      return 'The selected item could not be found';
    }

    if (this.item.assignedtoUserGuid && this.currentUser && this.allUsers) {
      const currentUserData = this.allUsers.find((u) => u.useridentity === this.currentUser.sub);
      if (this.item.assignedtoUserGuid !== currentUserData?.guid) {
        return 'This work order cannot be edited because it is assigned to another user';
      }
    }

    if (this.item.status === 'Complete') {
      return 'Cannot Edit Complete Work Order';
    }

    return null;
  }
}
