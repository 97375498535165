











import {
  IProjectResource,
  ResourceType,
  EquipmentResource,
} from '@/store/resources/types';
import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';
import ManualEntryDialog from './ManualEntryDialog.vue';

  @Component({
    components: {
      ManualEntryDialog,
    },
  })
export default class EquipmentEntryDialog extends Vue {
    @Prop() readonly mode: string;

    @PropSync('model') resource: IProjectResource;

    @PropSync('addResourceDialog') showDialog: boolean;

    equipmentTypeOptions = ['Truck'];
    // additional options to maybe include:
    // Pump, Trailer, easement machine, generator, rodder, tv van, vactor,  air compressor

    equipmentFields = [
      {
        label: 'Equipment Name', value: 'name', required: true, type: 'text',
      },
      {
        label: 'Equipment Type',
        value: 'equipmentType',
        required: false,
        type: 'combo',
        options: this.equipmentTypeOptions,
      },
      {
        label: 'Hourly Rate', value: 'hourlyRate', required: false, type: 'text',
      },
    ]

    newEquipment = {
      name: '',
      equipmentType: '',
      hourlyRate: null,
    } as EquipmentResource;

    get equipmentType(): string {
      return ResourceType.equipment;
    }
}
